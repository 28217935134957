import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Layout from "../../../layout";
import { faArrowLeft, faExclamationCircle, faEye, faEyeSlash, faPen } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import MISButton from "../../../../components/button";
import { Card, Col, Container, Dropdown, Row, Spinner, Stack } from "react-bootstrap";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ServiceManager } from "../../../../services/ServiceManager";
import Skeleton from "react-loading-skeleton";
import IExtractClient, { IClientConfiguration } from "../../../../models/ExtractClient";
import { APIStatusCode, MisType, Permissions } from "../../../../app/enums";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { GUID } from "../../../../app/common/helper/Guid";
import CustomModal from "../../../../components/modal";
import IconElement from "../../../../components/icon";
import { SubmitHandler, useForm } from "react-hook-form";
import ExtractClientUserTypes from "./user-types/extract-client-user-types";
import { usePermissionCheck } from "../../../../app/common/helper/Permissions";
import MISTooltip from "../../../../components/tooltip";
import { PERMISSION_REQUIRED } from "../../../../app/constants";

export default function ExtractClientDetails() {
    const [revealCredentials, setRevealCredentials] = useState(false);
    const [showResetModal, setShowResetModal] = useState(false);
    const [clientId, setClientId] = useState<GUID | null>(null);
    const [updateExtractClient, setUpdateExtractClient] = useState(false);
    const canEditEstablishments = usePermissionCheck(Permissions.CanEditEstablishments);

    let unsavedChanges = false;

    const handleCloseResetModal = () => setShowResetModal(false);
    const unsavedChangeMessage = "You have unsaved changes. Are you sure you want to leave?";

    let navigate = useNavigate();
    const { establishmentId, configurationId } = useParams();

    const { isLoading, data } = useQuery<IExtractClient>(["extractClientDetails-" + establishmentId], () =>
        ServiceManager.ConfigurationService.GetSimsConfigurationsById(establishmentId, configurationId), { refetchOnWindowFocus: false }
    );

    const resetClientSecret = (clientId: GUID) => {
        setClientId(clientId);
        setShowResetModal(true);
    }

    const onClickUpdateExtractClient = (isTrue: boolean) => {
        setUpdateExtractClient(isTrue);
        unsavedChanges = false;
    }

    const handleUnsavedChangesClick = () => {
        if (unsavedChanges) {
            const shouldLeave = window.confirm(unsavedChangeMessage);
            if (shouldLeave) {
                onClickUpdateExtractClient(false);
            }
        }
        else{
            onClickUpdateExtractClient(false);
        }
    }

    const handleUnsavedChanges = (event: any) => {
        if (unsavedChanges) {
            const message = unsavedChangeMessage;
            event.returnValue = message;
            return message;
        }
    }

    const ResetClientSecretModal = () => {
        const queryClient = useQueryClient();
        const mutation = useMutation({
            mutationFn: () => {
                return ServiceManager.ConfigurationService.ResetClientSecret(establishmentId, clientId);
            },
            onError: (error, variables, context) => {

            },
            onSuccess: (data, variables, context) => {
                if (data === null) {
                    ServiceManager.ToastService.showError("Unable to reset client secret.");
                }
                else {
                    if (data.status === APIStatusCode.NoContent) {
                        ServiceManager.ToastService.showSuccess("Client secret reset successfully.");
                        queryClient.invalidateQueries({ queryKey: ["extractClientDetails-" + establishmentId] });
                        handleCloseResetModal();
                    }
                    else {
                        let errorMessage = (data.response && data.response.data && data.response.data.errors.errors) ? data.response.data.errors.errors : data.message;
                        ServiceManager.ToastService.showError(errorMessage.toString());
                    }
                }
            }
        });

        return (
            <Stack>
                <p className="heading text-center">Reset Client Secret</p>
                <p className="text-center">Are you sure you wish to generate and apply a new Client Secret?</p>
                {
                    !mutation.isLoading
                        ? (<Stack direction="horizontal" className="mt-3">
                            <MISButton text="Cancel" className="popup-btn right-margin10 btn btn-outline-secondary w-50" FnOnClick={handleCloseResetModal} />
                            <MISButton text="Confirm" className="popup-btn right-margin10 btn btn-primary crbc-bg-color w-50" FnOnClick={() => { mutation.mutate(); }} />
                        </Stack>
                        ) : (
                            <div className="d-flex justify-content-center mt-3">
                                <Spinner animation="border" variant="info" role="status"></Spinner>
                            </div>
                        )
                }
            </Stack>
        )
    }

    const UpdateExtractClientForm = () => {
        const queryClient = useQueryClient();
        const [selectedMisType, setSelectedMisType] = useState(data?.misType);
        const [showPassword, setShowPassword] = useState(false);

        useEffect(() => {
            handleSelect(data?.misType);     
            const handleBeforeUnload = (event: any) => {
                handleUnsavedChanges(event);
            };
            window.addEventListener('beforeunload', handleBeforeUnload);
            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        const mutation = useMutation({
            mutationFn: (model: IClientConfiguration) => {
                return ServiceManager.ConfigurationService.UpdateSimsConfigurations(establishmentId, configurationId, model);
            },
            onError: (error, variables, context) => {

            },
            onSuccess: (data, variables, context) => {
                if (data === null) {
                    ServiceManager.ToastService.showError("Unable to update extract client.");
                }
                else {
                    if (data.status === APIStatusCode.Ok) {
                        ServiceManager.ToastService.showSuccess("Extract Client updated successfully.");
                        queryClient.invalidateQueries({ queryKey: ["extractClientDetails-" + establishmentId] });
                        onClickUpdateExtractClient(false);
                    }
                    else {
                        const errors = data?.response?.data?.errors;
                        if (errors) {
                            Object.keys(errors).forEach(field => {
                                ServiceManager.ToastService.showError(`${field}: ${errors[field][0]}`);
                            });
                        }
                        else{
                            ServiceManager.ToastService.showError(data?.message);
                        }
                    }
                }
            }
        });
        const { register, handleSubmit, clearErrors, setValue, formState: { errors } } = useForm<IClientConfiguration>();
        const onSubmit: SubmitHandler<IClientConfiguration> = (data) => {
            unsavedChanges = false;
            mutation.mutate(data);
        };
        const firstCol = 2, secondCol = 4;

        const handleSelect = (eventKey: any) => {
            setValue('misType', eventKey);
            setSelectedMisType(eventKey);
            clearErrors('misType');
        };

        const togglePasswordVisibility = () => {
            setShowPassword((prev) => !prev);
        };

        const handleInputChange = (event: any) => {
            unsavedChanges = true;
            event.preventDefault();
        };

        return (
            <Stack>
                <form onSubmit={handleSubmit(onSubmit)} className="form">
                    <Container className="mt-3">
                        <Row className="pb-3">
                            <Col md={firstCol}>MIS Type</Col>
                            <Col md={secondCol}>
                                <Dropdown className="m-auto cursor-no-drop" onSelect={handleSelect as any} defaultValue={data?.misType}>
                                    <Dropdown.Toggle className="btn btn-secondary w-100 d-flex justify-content-between align-items-center"
                                        {...register('misType', { required: 'MIS type is required' })} disabled>
                                        {selectedMisType ? MisType[selectedMisType]?.toUpperCase() : 'Select'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="timer-dropdown w-100">
                                        <Dropdown.Item eventKey="1">SIMS</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                {errors.misType && <div className="invalid-feedback" style={{ display: 'block', color: '#DC3245' }}>MIS Type is required</div>}
                            </Col>
                        </Row>
                        <Row className="pb-3">
                            <Col md={firstCol}>SIMS Path</Col>
                            <Col md={secondCol}>
                                <input {...register("simsPath", { required: 'SIMS Path is required', pattern: { value: /^[a-zA-Z]:\\(?:[^\\/:*?"<>|]+\\)*[^\\/:*?"<>|]*$/, message: 'Invalid Windows path', }, })}
                                    className={`form-control mt-1 ms-0 w-100 ${(errors.simsPath) ? 'is-invalid' : ''}`} defaultValue={data?.clientConfiguration.simsPath} onChange={handleInputChange}/>
                                {errors.simsPath && <div className="invalid-feedback">{errors.simsPath.message}</div>}
                            </Col>
                        </Row>
                        <Row className="pb-3">
                            <Col md={firstCol}>Username</Col>
                            <Col md={secondCol}>
                                <input {...register("username", { required: true })} className={`form-control mt-1 ms-0 w-100 ${(errors.username) ? 'is-invalid' : ''}`} 
                                    defaultValue={data?.clientConfiguration.username} maxLength={50} onChange={handleInputChange}/>
                                {errors.username && <div className="invalid-feedback">Username is required</div>}
                            </Col>
                        </Row>
                        <Row className="pb-3">
                            <Col md={firstCol}>Password</Col>
                            <Col md={secondCol}>
                                <div className="password-input-container">
                                    <input {...register("password", { required: 'Password is required' })} type={showPassword ? 'text' : 'password'}
                                        className={`form-control password-input mt-1 ms-0 w-100 ${(errors.password) ? 'is-invalid' : ''}`} defaultValue={data?.clientConfiguration.password} maxLength={512}
                                        onChange={handleInputChange}/>
                                    <button type="button" onClick={togglePasswordVisibility} className="password-toggle" >
                                        {!errors.password && (showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />)}
                                    </button>
                                </div>
                                {errors.password && <div className="invalid-feedback" style={{ display: 'block', color: '#DC3245' }}>{errors.password.message}</div>}
                            </Col>
                        </Row>
                        {
                            !mutation.isLoading
                                ? (
                                    <Row className="mt-5">
                                        <Col md={firstCol}></Col>
                                        <Col md={secondCol}>
                                            <MISButton text="Cancel" className="btn popup-btn right-margin10 btn-outline-secondary" type="button" FnOnClick={handleUnsavedChangesClick} />
                                            <MISButton text="Save" className="popup-btn btn btn-primary crbc-bg-color" type="submit" FnOnClick={handleSubmit(onSubmit)} />
                                        </Col>
                                    </Row>
                                )
                                : (
                                    <Row>
                                        <Col md={firstCol}></Col>
                                        <Col md={secondCol}>
                                            <div className="d-flex justify-content-center mt-3">
                                                <Spinner animation="border" variant="info" role="status" > </Spinner>
                                            </div>
                                        </Col>
                                    </Row>
                                )
                        }
                    </Container>
                </form>
            </Stack>
        );
    }

    return (
        <>
            {updateExtractClient === false ?
                (
                    <Layout title="Extract Client Details"
                        button={<>
                            <MISButton text="Transform" className="btn btn-primary crbc-bg-color me-2" FnOnClick={() => navigate(`transform/${data?.clientID}`)} disabled={!data?.clientID}></MISButton>
                            <MISButton text="Overrides" className="btn btn-secondary" FnOnClick={() => navigate('overrides')}></MISButton></>}
                        backArrow={<FontAwesomeIcon icon={faArrowLeft} onClick={() => navigate(-1)} className="me-4 fs-5 cursor-pointer" />}
                    >
                        <div className="stats-widget-box" style={{ height: 'auto' }}>
                            <Stack direction="horizontal">
                                <div className="stats-widget-box-header">
                                    Client Details
                                </div>
                                {
                                    canEditEstablishments ?
                                        <MISButton text="Edit" className="btn btn-outline-secondary ms-auto" FnOnClick={() => onClickUpdateExtractClient(true)} icon={<FontAwesomeIcon icon={faPen} />}></MISButton>
                                        :
                                        <MISTooltip message={PERMISSION_REQUIRED} placement="top">
                                            <div className="disabled ms-auto">
                                                <MISButton text="Edit" className="btn btn-secondary" FnOnClick={() => null} disabled icon={<FontAwesomeIcon icon={faPen} />}></MISButton>
                                            </div>
                                        </MISTooltip>
                                }
                            </Stack>
                            <Stack>
                                {!isLoading && data != null &&
                                    <Row>
                                        <Col xl={6} sm={5} className="text-break">
                                            <Row>
                                                <Col md={4} className="pb-2"><b>Establishment</b></Col>
                                                <Col md={8}>{data.establishmentName}</Col>
                                            </Row>
                                            <Row>
                                                <Col md={4} className="pb-2"><b>MIS</b></Col>
                                                <Col md={8}>{MisType[data.misType]?.toUpperCase()}</Col>
                                            </Row>
                                            <Row>
                                                <Col md={4} className="pb-2"><b>SIMS Path</b></Col>
                                                <Col md={8}>{data.clientConfiguration?.simsPath}</Col>
                                            </Row>
                                            <Row>
                                                <Col md={4} className="pb-2"><b>Client Start Date</b></Col>
                                                <Col md={8}>{data.clientConfiguration?.dateCreated ? format(new Date(data.clientConfiguration?.dateCreated), "dd/MM/yyyy") : ''}</Col>
                                            </Row>
                                        </Col>
                                        <Col xl={4} sm={7}>
                                            <div className="client-credentials">
                                                <Card style={{ height: 'auto' }}>
                                                    <Card.Body className="d-flex flex-column justify-content-between">
                                                        <Stack direction="horizontal">
                                                            <Card.Title as="h6">Credentials</Card.Title>
                                                            {
                                                                canEditEstablishments ?
                                                                    <span className="ms-auto crbc-color fw-bold cursor-pointer" onClick={() => resetClientSecret(data.clientID)}>Reset Client Secret</span>
                                                                    :
                                                                    <MISTooltip message={PERMISSION_REQUIRED} placement="top">
                                                                        <div className="disabled ms-auto">
                                                                            <span className="ms-auto fw-bold" onClick={() => null}>Reset Client Secret</span>
                                                                        </div>
                                                                    </MISTooltip>
                                                            }
                                                        </Stack>

                                                        {!revealCredentials &&
                                                            <MISButton text="Reveal" className="btn btn-light w-100" 
                                                                FnOnClick={() => setRevealCredentials(true)} icon={<FontAwesomeIcon icon={faEye} />}></MISButton>}

                                                        {revealCredentials &&
                                                            <div className="container">
                                                                <div className="row my-2">
                                                                    <div className="ps-0 col-3 font-11">Client ID</div>
                                                                    <div className="ps-0 col-9 font-11">{data.clientID}</div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="ps-0 col-3 font-11">Client Secret</div>
                                                                    <div className="ps-0 col-9 font-11">{data.secretKey}</div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="ps-0 col-3 font-11">Username</div>
                                                                    <div className="ps-0 col-9 font-11">{data.clientConfiguration?.username}</div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="ps-0 col-3 font-11">Password</div>
                                                                    <div className="ps-0 col-9 font-11">{data.clientConfiguration?.password}</div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </Col>
                                        <Col md={2}></Col>
                                    </Row>
                                }
                                {isLoading &&
                                    <Row>
                                        <Col md={5}><Skeleton height={20} count={4} /></Col>
                                        <Col md={5}><Skeleton height={20} count={4} /></Col>
                                        <Col md={2}></Col>
                                    </Row>
                                }
                            </Stack>
                        </div>

                        <ExtractClientUserTypes />

                        <CustomModal isShow={showResetModal} handleClose={handleCloseResetModal} header={<IconElement iconType={faExclamationCircle} headerClass="orange-icon ms-6" color="#D92D20" />} size="md" >
                            <ResetClientSecretModal />
                        </CustomModal>

                    </Layout>
                ) :
                (
                    <Layout title="Edit Client Details" backArrow={<FontAwesomeIcon icon={faArrowLeft} onClick={handleUnsavedChangesClick} className="me-4 fs-5 cursor-pointer" />}>
                        <UpdateExtractClientForm />
                    </Layout>
                )
            }
        </>
    );
}