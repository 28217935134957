import { createColumnHelper } from "@tanstack/react-table";
import { NavLink } from "react-router-dom";
import IExtract from "../../models/Extract";
import { FilterTableCallFrom, MisServiceType, MisType, ModalTypes, Permissions, QueueStatus, QueueStatusStyles, SortOrder } from "../../app/enums";
import { Stack } from "react-bootstrap";
import format from "date-fns/format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faPen, faSortAsc, faSortDesc, faTrashAlt, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { ServiceManager } from "../../services/ServiceManager";
import PagedFilterDataTable from "../../components/table/filter-table";
import { usePermissionCheck } from "../../app/common/helper/Permissions";
import { useState } from "react";
import { GUID } from "../../app/common/helper/Guid";
import CustomModal from "../../components/modal";
import SalesForceModal from "./modal/salesforce-modal";
import { useQueryClient } from "@tanstack/react-query";
import IconElement from "../../components/icon";

export default function ExtractServiceView() {
    const canViewEstablishments = usePermissionCheck(Permissions.CanViewEstablishments);
    const [showSalesForceModal, setShowSalesForceModal] = useState(false);
    const [extractQueueId, setExtractQueueId] = useState<GUID>();
    const [dateStartOrder, setDateStartOrder] = useState(SortOrder.DESC);
    const [dateCompletedOrder, setDateCompletedOrder] = useState(SortOrder.NONE);
    const [modalType, setModalType] = useState(ModalTypes.Add);
    const [salesForceParams, setSalesForceParams] = useState({ salesforceCaseId: '', salesforceCaseUrl: '' });
    const queryClient = useQueryClient();

    const columnHelper = createColumnHelper<IExtract>();
    const columns = [
        columnHelper.accessor('establishmentName', {
            header: () => <span>Estab Name</span>,
            cell: ({ row }) => (
                <div className="establishment-text">
                    {
                        canViewEstablishments
                            ?
                            <NavLink to={`/establishment/${row.original.establishmentID}`} state={{ "establishmentName": row.original.establishmentName }}>
                                {row.original.establishmentName}
                            </NavLink>
                            :
                            <span>{row.original.establishmentName}</span>
                    }
                </div>
            ),
        }),
        columnHelper.accessor('parentEstablishmentName', {
            header: () => <span>Parent</span>,
            cell: ({ row }) => (
                <div className="establishment-text">
                    {
                        canViewEstablishments
                            ?
                            <NavLink to={`/establishment/${row.original.parentEstablishmentID}`} state={{ "establishmentName": row.original.parentEstablishmentName }}>
                                {row.original.parentEstablishmentName}
                            </NavLink>
                            :
                            <span>{row.original.parentEstablishmentName}</span>
                    }
                </div>
            ),
        }),
        columnHelper.accessor('salesforceCaseID', {
            header: () => <span>Case ID</span>,
            cell: ({ row }) => (
                <SalesForceCase data={row.original} />
            ),
        }),
        columnHelper.accessor('extractQueueID', {
            header: () => <span>Queue ID</span>
        }),
        columnHelper.accessor('misType', {
            header: () => <span>MIS</span>,
            cell: ({ row }) => (
                <span>{MisType[row.original.misType]?.toUpperCase()}</span>
            ),
        }),
        columnHelper.accessor('dateCreated', {
            header: (header) => <div className="cursor-pointer" style={{ display: "table" }}  >Started <span id={header.column.id} onClick={() => onDateStartClick()} style={{ display: "table-cell", verticalAlign: "middle", paddingLeft: "5px" }} ><FontAwesomeIcon icon={dateStartOrder === SortOrder.DESC ? faSortDesc : faSortAsc} className={dateStartOrder === SortOrder.DESC ? "mb-1 cursor-pointer" : "mt-1 cursor-pointer"} /></span></div>,
            id: "dateStart",
            sortDescFirst: true,
            enableSorting: true,
            cell: ({ row }) => (
                <Stack>
                    <span className="font-dark">{row.original.dateCreated ? format(new Date(row.original.dateCreated), "p") : ''}</span>
                    <span>{row.original.dateCreated ? format(new Date(row.original.dateCreated), "dd-MM-yyyy") : ''}</span>
                </Stack>
            ),
        }),
        columnHelper.accessor('dateProcessed', {
            header: (header) => <div className="cursor-pointer" style={{ display: "table" }}  >Completed <span id={header.column.id} onClick={() => onDateCompletedClick()} style={{ display: "table-cell", verticalAlign: "middle", paddingLeft: "5px" }} ><FontAwesomeIcon icon={dateCompletedOrder === SortOrder.DESC ? faSortDesc : faSortAsc} className={dateCompletedOrder === SortOrder.NONE ? "light-gray-color mt-1 cursor-pointer" : dateCompletedOrder === SortOrder.DESC ? "mb-1 cursor-pointer" : "mt-1 cursor-pointer"} /></span></div>,
            id: "dateCompleted",
            sortDescFirst: true,
            enableSorting: true,
            cell: ({ row }) => (
                <Stack>
                    <span className="font-dark">{row.original.dateProcessed ? format(new Date(row.original.dateProcessed), "p") : ''}</span>
                    <span>{row.original.dateProcessed ? format(new Date(row.original.dateProcessed), "dd-MM-yyyy") : ''}</span>
                </Stack>
            ),
        }),
        columnHelper.accessor('status', {
            header: () => <span>Status</span>,
            cell: ({ row }) => (
                <div className={`p-1 ps-2 pe-2 ${QueueStatusStyles[row.original.status]}`}>
                    <FontAwesomeIcon icon={faCircle} size="2xs" className="icon" />
                    <span className="ms-2">{row.original.status === QueueStatus.NotExecuted ? "Not Executed " : QueueStatus[row.original.status]}</span>
                </div>
            ),
        }),
        columnHelper.accessor('processedDuration', {
            header: () => <span>Processed Duration</span>,
            cell: ({ row }) => (
                <span>{Math.round(row.original.processedDuration / 1000)}s</span>
            )
        })
    ];

    const getClickableLink = (link: string) => {
        return link.startsWith("http://") || link.startsWith("https://") ?
            link
            : `//${link}`;
    }

    function toggleSortOrder(order: any, setOrder: any) {
        setOrder(order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC);
    }

    function onDateStartClick() {
        toggleSortOrder(dateStartOrder, setDateStartOrder);
    }

    function onDateCompletedClick() {
        toggleSortOrder(dateCompletedOrder, setDateCompletedOrder);
    }

    async function fetchData(options: any) {
        if (options.startDate === '' || options.finishDate === '') {
            return null;
        }
        const extractResponse = await ServiceManager.ExtractService.GetAll(options.startDate, options.finishDate, options.establishmentId,
            options.misType, options.status, options.caseID, options.includeChildren, options.sortBy, options.sortOrder, options.pageIndex + 1, options.pageSize);
        if (options.sortBy === "dateStart") {
            setDateStartOrder(options.sortOrder);
        } else if (options.sortBy === "dateCompleted") {
            setDateCompletedOrder(options.sortOrder);
        }
        return extractResponse;
    }

    const SalesForceCase = (row: any) => {
        return (
            <>
                {row.data.salesforceCaseID &&
                    <>
                        <div className="btn icon-btn" onClick={() => {
                            setShowSalesForceModal(true); setExtractQueueId(row.data.extractQueueID); setModalType(ModalTypes.Edit);
                            setSalesForceParams({ salesforceCaseId: row.data.salesforceCaseID, salesforceCaseUrl: row.data.salesforceCaseUrl })
                        }}>
                            <FontAwesomeIcon icon={faPen} size="sm" className="icon" />
                        </div>
                        <div className="btn icon-btn" onClick={() => {
                            setShowSalesForceModal(true); setExtractQueueId(row.data.extractQueueID); setModalType(ModalTypes.Delete);
                            setSalesForceParams({ salesforceCaseId: row.data.salesforceCaseID, salesforceCaseUrl: row.data.salesforceCaseUrl })
                        }}>
                            <FontAwesomeIcon icon={faTrashCan} size="sm" className="icon" />
                        </div>
                    </>
                }
                {row.data.salesforceCaseID && row.data.salesforceCaseUrl
                    ? <a style={{ textDecoration: "underline" }} href={getClickableLink(row.data.salesforceCaseUrl)} target="_blank" rel="noopener noreferrer" >{row.data.salesforceCaseID}</a>
                    : row.data.salesforceCaseID
                        ? row.data.salesforceCaseID
                        : <div className="btn icon-btn" onClick={() => { setShowSalesForceModal(true); setExtractQueueId(row.data.extractQueueID); setModalType(ModalTypes.Add) }}> <span className="font-14">+ Add</span> </div>
                }
            </>
        );
    }

    const callBackSalesForceModal = (isRefresh: any) => {
        if (isRefresh) {
            queryClient.invalidateQueries({ queryKey: ["extract-service"] });
        }
        setShowSalesForceModal(false);
        setSalesForceParams({ salesforceCaseId: '', salesforceCaseUrl: '' });
    }

    const SalesForceModalHeader = ({ modalType }: { modalType: ModalTypes }) => {
      if (modalType === ModalTypes.Delete) {
        return (<IconElement iconType={faTrashAlt} headerClass="error-icon ms-6" color="#D92D20" />);
      }
      else {
        return (<p className="heading">{modalType === ModalTypes.Add ? "Add Case ID" : "Edit Case ID"}</p>);
      }
    }

    return (
        <>
            <div id="healthboardTable-transform">
                <PagedFilterDataTable
                    tableKey={"extract-service"}
                    columns={columns}
                    data={[]}
                    usePagination={true}
                    pageRecords={10}
                    fnFetchData={fetchData}
                    useSearch={false}
                    tableCallFrom={FilterTableCallFrom.Healthboard}
                    useSorting={true}
                />
            </div>

            <CustomModal isShow={showSalesForceModal} handleClose={() => { callBackSalesForceModal(false) }} header={<SalesForceModalHeader modalType={modalType} />} size="md">
                <SalesForceModal healthboardType={MisServiceType.Extract} healthboardId={extractQueueId} modalType={modalType} salesForceParams={salesForceParams} callBack={callBackSalesForceModal} />
            </CustomModal>
        </>
    )

}