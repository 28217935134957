import { Spinner, Stack } from "react-bootstrap";
import MISButton from "../../../../../components/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faBoxOpen, faPen, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import ISimsUserType from "../../../../../models/SimsUserType";
import { ServiceManager } from "../../../../../services/ServiceManager";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { APIStatusCode, Permissions, SimsStandardMappingTemplates } from "../../../../../app/enums";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import CustomModal from "../../../../../components/modal";
import IconElement from "../../../../../components/icon";
import SimpleTable from "../../../../../components/simpletable";
import Skeleton from "react-loading-skeleton";
import { usePermissionCheck } from "../../../../../app/common/helper/Permissions";
import MISTooltip from "../../../../../components/tooltip";
import { PERMISSION_REQUIRED } from "../../../../../app/constants";

export default function ExtractClientUserTypes(props: any) {
    const { establishmentId, configurationId } = useParams();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [userTypeToDelete, setUserTypeToDelete] = useState(0);
    const canEditEstablishments = usePermissionCheck(Permissions.CanEditEstablishments);

    const handleCloseDeleteModal = () => setShowDeleteModal(false);
    let navigate = useNavigate();

    const { isLoading, data } = useQuery(["extract-client-user-types-" + configurationId], () =>
    ServiceManager.ConfigurationService.GetSimsUserTypes(establishmentId, configurationId), { refetchOnWindowFocus: false }
    );

    const columnHelper = createColumnHelper<ISimsUserType>();
    const columns = [
        columnHelper.accessor('userTypeName', {
            header: () => <span>User Type Name</span>,
            size: 150
        }),
        columnHelper.accessor('reportName', {
            header: () => <span>Data Report Name</span>,
            size: 150
        }),
        columnHelper.accessor('photoReportName', {
            header: () => <span>Photos Report Name</span>,
            size: 150
        }),
        columnHelper.accessor('isApplicant', {
            header: () => <span>Is Applicant</span>,
            size: 150,
            cell: ({ row }) => (
                <span>{row.original.isApplicant ? "Yes" : "No"}</span>
            )
        }),
        columnHelper.accessor('mappingTemplate', {
            header: () => <span>Mapping Template</span>,
            size: 150,
            cell: ({ row }) => (
                <span>{SimsStandardMappingTemplates[row.original.mappingTemplate]}</span>
            )
        }),
        columnHelper.accessor('actions', {
            header: () => <span>Actions</span>,
            size: 5,
            cell: ({ row }) => (
                <div>
                    {
                        canEditEstablishments ?
                            <FontAwesomeIcon
                                className="cursor-pointer me-5"
                                icon={faTrashAlt}
                                size="lg"
                                onClick={() => {
                                    setShowDeleteModal(true); setUserTypeToDelete(row.original.userTypeID);
                                }}/>
                            :
                            <MISTooltip message={PERMISSION_REQUIRED} placement="top">
                                <FontAwesomeIcon
                                    icon={faTrashAlt}
                                    size="lg"
                                    className="disabled me-5"
                                />
                            </MISTooltip>
                    }

                    {
                        canEditEstablishments ?
                            <FontAwesomeIcon
                                className="cursor-pointer"
                                icon={faPen}
                                size="lg"
                                onClick={() => {
                                    navigate(`user-type/${row.original.userTypeID}`);
                                }}
                            />
                            :
                            <MISTooltip message={PERMISSION_REQUIRED} placement="top">
                                <FontAwesomeIcon
                                    icon={faPen}
                                    size="lg"
                                    className="disabled"
                                />
                            </MISTooltip>
                    }                    
                </div>
            )
        })
    ];

    const DeleteUserTypeConfirmationForm = () => {
        const queryClient = useQueryClient();
        const mutation = useMutation({
            mutationFn: () => {
                return ServiceManager.ConfigurationService.DeleteSimsUserType(establishmentId, configurationId, userTypeToDelete);
            },
            onError: (error, variables, context) => {
    
            },
            onSuccess: (data, variables, context) => {
                if (data === null) {
                    ServiceManager.ToastService.showError("Unable to delete user type.");
                }
                else {
    
                    if (data.status === APIStatusCode.NoContent) {
                        ServiceManager.ToastService.showSuccess("User Type deleted successfully.");
                        queryClient.invalidateQueries({ queryKey: ["extract-client-user-types-" + configurationId] });
                        handleCloseDeleteModal();
                    }
                    else {
                        let errorMessage = (data.response && data.response.data && data.response.data.errors.errors) ? data.response.data.errors.errors : data.message;
                        ServiceManager.ToastService.showError(errorMessage.toString());
                    }
                }
            }
        });
    
        return (
            <Stack>
                <p className="heading text-center">Delete User Type</p>
                <p className="text-center">Are you sure you want to remove this User Type configuration from the extract? This action cannot be undone.</p>
                {
                    !mutation.isLoading
                        ? (<Stack direction="horizontal" className="mt-3">
                            <MISButton text="Cancel" className="popup-btn right-margin10 btn btn-outline-secondary w-50" FnOnClick={handleCloseDeleteModal} />
                            <MISButton text="Delete" className="popup-btn right-margin10 btn btn-danger w-50" FnOnClick={() => { mutation.mutate(); }} />
                        </Stack>
                        ) : (
                            <div className="d-flex justify-content-center mt-3">
                                <Spinner
                                    animation="border"
                                    variant="info"
                                    role="status"
                                >
                                </Spinner>
                            </div>
                        )
                }
            </Stack>
        )
    }

    return (
        <div className="stats-widget-box mt-4" id="userTypesForExtract">
            <Stack direction="horizontal">
                <div className="stats-widget-box-header">
                    User Types For Extract
                </div>
                {
                    canEditEstablishments ?
                        <MISButton text="Add New" className="btn btn-outline-secondary ms-auto" FnOnClick={() => navigate('user-type/new')} icon={<FontAwesomeIcon icon={faAdd} />}></MISButton>
                        :
                        <MISTooltip message={PERMISSION_REQUIRED} placement="top">
                            <div className="disabled ms-auto">
                                <MISButton text="Add New" className="btn btn-secondary" FnOnClick={() => null} disabled icon={<FontAwesomeIcon icon={faAdd} />}></MISButton>
                            </div>
                        </MISTooltip>
                }
                
            </Stack>

            {isLoading &&
                <React.Fragment>
                    <div className="w-100">
                        <Skeleton height={20} count={2} />
                    </div>
                </React.Fragment>
            }

            {!isLoading && data && data.length === 0 &&
                <Stack className="d-flex justify-content-center align-items-center">
                    <FontAwesomeIcon icon={faBoxOpen} size="4x" className="me-4 grey-color"></FontAwesomeIcon>
                    <span className="text-muted mt-3">Use the Add New button to add User Type information</span>
                </Stack>
            }
            {!isLoading && data && data.length > 0 &&
                <div className="w-100 extract-client-user-types">
                    <SimpleTable
                        tableKey={`extract-client-user-types-${configurationId}`}
                        columns={columns}
                        data={data}
                    />
                </div>
            }

            <CustomModal isShow={showDeleteModal} handleClose={handleCloseDeleteModal} header={<IconElement iconType={faTrashAlt} headerClass="error-icon ms-6" color="#D92D20" />} size="md">
                <DeleteUserTypeConfirmationForm />
            </CustomModal>

        </div>
    );

}