import { useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { Spinner, Stack } from "react-bootstrap";
import format from "date-fns/format";
import { ServiceManager } from "../../../services/ServiceManager";
import PagedFilterTable from "../../../components/table";
import IExtractFile from "../../../models/ExtractFile";
import { useParams } from "react-router-dom";
import { toGuid } from "../../../app/common/helper/Guid";
import CustomModal from "../../../components/modal";
import { useQuery } from "@tanstack/react-query";
import { MisType } from "../../../app/enums";
import XMLViewer from 'react-xml-viewer';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function RawXml() {

    const { establishmentId } = useParams();
    const [tableJobs, setTableJobs] = useState([]);
    const [showXMLModal, setShowXMLModal] = useState(false);
    const [fileId, setFileId] = useState();
    const [fileName, setFileName] = useState("");

    const customXmlTheme = {
        "attributeKeyColor": "#FF0000",
        "attributeValueColor": "#000FF"
    };

    const handleCloseXMLModal = () => {setShowXMLModal(false); setFileId(undefined)}

    const columnHelper = createColumnHelper<IExtractFile>();
    const columns = [
        columnHelper.accessor('fileName', {
            header: () => <span>File Name</span>
        }),
        columnHelper.accessor('misType', {
            header: () => <span>MIS</span>,
            cell: ({ row }) => (
                <div className="short-text-columns">
                    <span>{row.original.dateCreated ? MisType[row?.original?.misType]?.toUpperCase() : ''}</span>
                </div>

            ),
        }),
        columnHelper.accessor('dateCreated', {
            header: () => <span>Date</span>,
            cell: ({ row }) => (
                <Stack>
                    <span>{row.original.dateCreated ? format(new Date(row.original.dateCreated), "dd-MM-yyyy 'T'HH:mm:ss.SSS") : ''}</span>
                </Stack>
            )
        }),
        columnHelper.accessor("fileId", {
            header: () => <span></span>,
            cell: ({ row }) => (
                <div className="text-center">
                    {(fileId !== row.original.fileId) && <button className="btn btn-outline-secondary" onClick={() => processViewXML(row.original.fileId, row.original.fileName)}>View</button>}
                    {(fileId === row.original.fileId) && <button className="btn btn-outline-secondary"><FontAwesomeIcon icon={faSpinner} className="fa-spin" /></button>}
                </div>
            ),
            size: 10,
        })
    ];

    const processViewXML = (fileId: any, fileName: any) => {
        setFileId(fileId);
        setFileName(fileName);
        setShowXMLModal(true);
    }

    const ViewXML = () => {
        const { isLoading, data } = useQuery([`viewXML-${fileId}`], () =>
            ServiceManager.EstablishmentService.GetExtractFile(establishmentId, fileId ?? 0),
            {
                refetchOnWindowFocus: false
            }
        );
        return (
            <Stack>
                {
                    !isLoading
                        ? (
                            <Stack direction="horizontal" className="mt-3">
                                <XMLViewer xml={data} theme={customXmlTheme} collapsible={false} wrap={false} />
                            </Stack>
                        ) : (
                            <div className="d-flex justify-content-center mt-3">
                                <Spinner
                                    animation="border"
                                    variant="info"
                                    role="status"
                                    style={{ width: "4rem", height: "4rem" }}
                                >
                                </Spinner>
                            </div>
                        )
                }
            </Stack>
        );
    }

    async function fetchData(options: {
        pageIndex: number;
        pageSize: number;
        searchValue: string
    }) {
        const transformData = await ServiceManager.EstablishmentService.GetExtractFiles(toGuid(establishmentId ?? ""), options.pageIndex + 1,
            options.pageSize);
        setTableJobs(transformData);
        return transformData;
    }

    return (
        <>
            <div id="listView-data-rawxml" className="mt-2">
                <PagedFilterTable
                    tableKey={`xml-data-${establishmentId}`}
                    columns={columns}
                    data={tableJobs}
                    usePagination={true}
                    pageRecords={10}
                    fnFetchData={fetchData}
                    useSearch={false}
                />
            </div>

            <CustomModal isShow={showXMLModal} handleClose={handleCloseXMLModal} header={<span className="xml-header-file mb-3">{fileName}</span>} size="xl" fullscreen={true} headerBgColor={'#8394aa'}>
                {showXMLModal && <ViewXML />}
            </CustomModal>
        </>

    )
}