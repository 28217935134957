import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import MISButton from "../../../components/button";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ServiceManager } from "../../../services/ServiceManager";
import { Spinner, Stack, Tab, Tabs } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import IRole from "../../../models/Role";
import { useForm } from "react-hook-form";
import { permissions } from "../../../app/common/data/Permissions";
import ReactSwitch from "react-switch";
import { useEffect, useState } from "react";
import { APIStatusCode, Permissions } from "../../../app/enums";
import RoleUsers from "./role-users";
import CustomModal from "../../../components/modal";
import IconElement from "../../../components/icon";
import { usePermissionCheck } from "../../../app/common/helper/Permissions";

export default function CreateUpdateRole() {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { roleId } = useParams();
    const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [defaultForUsers, setDefaultForUsers] = useState(false);
    const handleCloseDeleteModal = () => setShowDeleteModal(false);

    const localPermissions = permissions;
    const columnSize = Math.ceil(localPermissions.length / 3);

    let canViewUsers: boolean = usePermissionCheck(Permissions.CanViewUsers);

    const {isLoading: isRoleLoading, data: roleData} = useQuery([`role-${roleId}`], () => {
            if (roleId) {
                return ServiceManager.RoleService.GetById(roleId);
            }

            const defaultTask: IRole = {
                roleName: '',
                description: '',
                defaultForUsers: false,
                canAssignRoles: false,
                canManageRoles: false,
                canViewUsers: false,
                canEditEstablishments: false,
                canViewEstablishments: false,
                canViewDashboard: false,
                canViewHealthboard: false
            };
            return Promise.resolve(defaultTask);
        }, {refetchOnWindowFocus: false}
    );

    useEffect(() => {
        if (!isRoleLoading && roleData != null) {
            const rolePermissions = Object.keys(roleData).filter(permission => localPermissions.find(i => i.id === permission) && roleData[permission]);
            setSelectedPermissions(rolePermissions);

            setDefaultForUsers(roleData.defaultForUsers);
        }
    }, [localPermissions, isRoleLoading, roleData]);

    const { register, handleSubmit, reset, formState: { errors }, } = useForm();

    const handlePermissionToggle = (permissionId: string) => {
        setSelectedPermissions((prevSelected: any) => {
            if (prevSelected.includes(permissionId)) {
                return prevSelected.filter((id: any) => id !== permissionId);
            } else {
                return [...prevSelected, permissionId];
            }
        });
    };

    const onSubmit = (data: any) => {
        if (selectedPermissions.length === 0) {
            ServiceManager.ToastService.showError("Please select at least one Role Permission");
            return;
        }

        const role: IRole = {
            roleName: data.roleName,
            description: data.description,
            defaultForUsers: defaultForUsers,
            canEditEstablishments: selectedPermissions.includes("canEditEstablishments"),
            canViewEstablishments: selectedPermissions.includes("canViewEstablishments"),
            canManageRoles: selectedPermissions.includes("canManageRoles"),
            canAssignRoles: selectedPermissions.includes("canAssignRoles"),
            canViewUsers: selectedPermissions.includes("canViewUsers"),
            canViewDashboard:selectedPermissions.includes("canViewDashboard"),
            canViewHealthboard:selectedPermissions.includes("canViewHealthboard")
        };

        if (roleId) {
            updatedMutation.mutate(role);
        }
        else {
            createMutation.mutate(role);
        }
    };

    const createMutation = useMutation({
        mutationFn: (model: IRole) => {
            return ServiceManager.RoleService.Create(model);
        },
        onError: (error, variables, context) => {

        },
        onSuccess: (data, variables, context) => {
            if (data === null) {
                ServiceManager.ToastService.showError("Unable to add role.");
            }
            else {
                if (data.status === APIStatusCode.Created) {
                    ServiceManager.ToastService.showSuccess("Role created successfully.");
                    reset();
                    setSelectedPermissions([]);
                    queryClient.invalidateQueries({ queryKey: ["roles-table"] });
                    navigate("/setting?tabKey=roles");
                }
                else {
                    const errors = data?.response?.data?.errors;
                    if (errors) {
                        Object.keys(errors).forEach(field => {
                            ServiceManager.ToastService.showError(`${field}: ${errors[field][0]}`);
                        });
                    }
                    else {
                        ServiceManager.ToastService.showError(data?.response?.data ?? data?.message);
                    }
                }
            }
        }
    });

    const updatedMutation = useMutation({
        mutationFn: (model: IRole) => {
            return ServiceManager.RoleService.Update(roleId, model);
        },
        onError: (error, variables, context) => {

        },
        onSuccess: (data, variables, context) => {
            if (data === null) {
                ServiceManager.ToastService.showError("Unable to update role.");
            }
            else {
                if (data.status === APIStatusCode.Ok) {
                    ServiceManager.ToastService.showSuccess("Role updated successfully.");
                    queryClient.invalidateQueries({ queryKey: ["roles-table"] });
                    queryClient.invalidateQueries({ queryKey: [`role-${roleId}`] });
                }
                else {
                    const errors = data?.response?.data?.errors;
                    if (errors) {
                        Object.keys(errors).forEach(field => {
                            ServiceManager.ToastService.showError(`${field}: ${errors[field][0]}`);
                        });
                    }
                    else {
                        ServiceManager.ToastService.showError(data?.response?.data ?? data?.message);
                    }
                }
            }
        }
    });

    const RolePermissionsTemplate = () => {
        return (
            <>
                <Stack direction="horizontal" className="permission-box">
                    <Stack direction="vertical" gap={3}>
                        {localPermissions.slice(0, columnSize).map((permission) => (
                            <div key={permission.id} className="d-flex align-items-center permission-item pb-3">
                                <label className="w-50">{permission.name}</label>
                                <ReactSwitch className="" height={20} onColor="#10A760" offColor="#E2E2E3"
                                    handleDiameter={17} width={40} checkedIcon={false}
                                    uncheckedIcon={false} checked={selectedPermissions.includes(permission.id)}
                                    onChange={() => handlePermissionToggle(permission.id)} />
                            </div>
                        ))}
                    </Stack>
                    <Stack direction="vertical" gap={3}>
                        {localPermissions.slice(columnSize, (columnSize * 2) - 1).map((permission) => (
                            <div key={permission.id} className="d-flex align-items-center permission-item pb-3">
                                <label className="w-50">{permission.name}</label>
                                <ReactSwitch className="" height={20} onColor="#10A760" offColor="#E2E2E3"
                                             handleDiameter={17} width={40} checkedIcon={false}
                                             uncheckedIcon={false} checked={selectedPermissions.includes(permission.id)}
                                             onChange={() => handlePermissionToggle(permission.id)}/>
                            </div>
                        ))}
                    </Stack>
                    <Stack direction="vertical" gap={3}>
                        {localPermissions.slice((columnSize * 2)-1).map((permission) => (
                            <div key={permission.id} className="d-flex align-items-center permission-item-right pb-3">
                                <label className="w-50">{permission.name}</label>
                                <ReactSwitch className="" height={20} onColor="#10A760" offColor="#E2E2E3"
                                    handleDiameter={17} width={40} checkedIcon={false}
                                    uncheckedIcon={false} checked={selectedPermissions.includes(permission.id)}
                                    onChange={() => handlePermissionToggle(permission.id)} />
                            </div>
                        ))}
                    </Stack>
                </Stack>
                {
                    !(createMutation.isLoading || updatedMutation.isLoading)
                        ? (<Stack direction="horizontal" className="mt-4 w-25">
                            <MISButton text="Cancel" className="btn popup-btn right-margin10 btn-outline-secondary w-100" FnOnClick={() => navigate("/setting?tabKey=roles")} />
                            <MISButton text="Save" className="popup-btn btn btn-primary crbc-bg-color w-100" type="submit" FnOnClick={handleSubmit(onSubmit)} />
                        </Stack>
                        ) : (
                            <div className="d-flex justify-content-center mt-5 w-100">
                                <Spinner animation="border" variant="info" role="status" > </Spinner>
                            </div>
                        )
                }
            </>
        )
    }

    const DeleteRoleModal = () => {
        const queryClient = useQueryClient();
        const mutation = useMutation({
            mutationFn: () => {
                return ServiceManager.RoleService.DeleteById(roleId ?? "");
            },
            onError: (error, variables, context) => {

            },
            onSuccess: (data, variables, context) => {
                if (data === null) {
                    ServiceManager.ToastService.showError("Unable to delete role.");
                }
                else {

                    if (data.status === APIStatusCode.NoContent) {
                        ServiceManager.ToastService.showSuccess("Role deleted successfully.");
                        queryClient.invalidateQueries({ queryKey: ["roles-table"] });
                        navigate("/setting?tabKey=roles");
                        handleCloseDeleteModal();
                    }
                    else {
                        const errors = data?.response?.data?.errors;
                        if (errors) {
                            Object.keys(errors).forEach(field => {
                                ServiceManager.ToastService.showError(`${field}: ${errors[field][0]}`);
                            });
                        }
                        else {
                            ServiceManager.ToastService.showError(data?.response?.data ?? data?.message);
                        }
                    }
                }
            }
        });
        return (
            <Stack>
                <p className="heading text-center mt-2">Delete Role</p>
                <p className="text-center">Are you sure? This will also remove the Role from any users assigned. This action cannot be undone.</p>
                {
                    !mutation.isLoading
                        ? (<Stack direction="horizontal" className="mt-3">
                            <MISButton text="Cancel" className="popup-btn right-margin10 btn btn-outline-secondary w-50" FnOnClick={handleCloseDeleteModal} />
                            <MISButton text="Delete" className="popup-btn right-margin10 btn btn-danger w-50" FnOnClick={() => { mutation.mutate(); }} />
                        </Stack>
                        ) : (
                            <div className="d-flex justify-content-center mt-3">
                                <Spinner animation="border" variant="info" role="status"></Spinner>
                            </div>
                        )
                }
            </Stack>
        )
    }

    return (
        <Layout title={roleId ? "Role & Permissions" : "Create New Role"}
            backArrow={<FontAwesomeIcon icon={faArrowLeft} onClick={() => navigate("/setting?tabKey=roles")} className="me-4 fs-5 cursor-pointer" />}
            button={roleId ? <MISButton text="Delete Role"
                className="btn btn-outline-danger"
                FnOnClick={() => setShowDeleteModal(true)}
                icon={<FontAwesomeIcon icon={faTrashAlt} />}></MISButton> : undefined
            }
        >
            <form>
                {!isRoleLoading &&
                    <>
                        <label className="mt-1 label-text ms-0">Role Name</label>
                        <input {...register("roleName", { required: true })} defaultValue={roleData?.roleName} placeholder="Enter role name here" maxLength={16}
                            className={`form-control w-40 mt-1 ms-0 ${(errors.roleName) ? 'is-invalid' : ''}`} />
                        {errors.roleName && <div className="invalid-feedback">Role Name field is required</div>}

                        <label className="mt-3 label-text ms-0">Description</label>
                        <input {...register("description")} defaultValue={roleData?.description} placeholder="Enter role description here" maxLength={50}
                            className={`form-control w-40 mt-1 ms-0`} />

                        <div className="d-flex align-items-center mt-3 ms-0">
                            <label>Default for User</label>
                            <ReactSwitch height={20} onColor="#10A760" offColor="#E2E2E3"
                                handleDiameter={17} width={40} checkedIcon={false}
                                uncheckedIcon={false} checked={defaultForUsers} className="ms-4"
                                onChange={(event) => setDefaultForUsers(event)} key="defaultForUsers" />
                        </div>

                        {roleId ?
                            <Tabs defaultActiveKey="permissions" className="mt-3 mb-3">
                                <Tab eventKey="permissions" title="Permissions">
                                    <RolePermissionsTemplate />
                                </Tab>
                                {
                                    canViewUsers ? (
                                        <Tab eventKey="users" title="Users">
                                            <RoleUsers roleId={roleId} roleName={roleData?.roleName} />
                                        </Tab>
                                    ) : (
                                        <Tab title="Users" disabled></Tab>
                                    )
                                }
                            </Tabs>
                            :
                            <div className="mt-3">
                                <label className="label-text ms-0 mb-2">Role Permissions</label>
                                <RolePermissionsTemplate />
                            </div>
                        }
                    </>
                }
                {isRoleLoading &&
                    <div className="role-details">
                        <div className="w-25">
                            <Skeleton height={20} count={2} />
                        </div>
                        <div className="mt-3 w-25">
                            <Skeleton height={20} count={2} />
                        </div>
                        <div className="mt-5">
                            <Skeleton height={20} count={6} />
                        </div>
                    </div>
                }
            </form>

            <CustomModal isShow={showDeleteModal} handleClose={handleCloseDeleteModal} header={<IconElement iconType={faTrashAlt} headerClass="error-icon ms-6" color="#D92D20" />} size="md">
                <DeleteRoleModal />
            </CustomModal>
        </Layout>
    )
} 