import { Stack } from "react-bootstrap";
import MISButton from "../../../components/button";
import MISSpinner from "../../../components/spinner";
import { useMutation } from "@tanstack/react-query";
import { ServiceManager } from "../../../services/ServiceManager";
import { APIStatusCode, MisServiceType, ModalTypes } from "../../../app/enums";
import { useForm } from "react-hook-form";

interface ISalesForce {
    healthboardId: any;
    healthboardType: MisServiceType;
    modalType: ModalTypes;
    salesForceParams: MutationParams;
    callBack: any;
}

type MutationParams = {
    salesforceCaseId: string,
    salesforceCaseUrl: string
}

export default function SalesForceModal(props: ISalesForce) {
    const mutation = useMutation({
        mutationFn: (myMutationParams: MutationParams) => {
            if (props.healthboardType === MisServiceType.Task) {
                return ServiceManager.TaskService.UpdateCaseIdAndUrl(props.healthboardId, myMutationParams.salesforceCaseId, myMutationParams.salesforceCaseUrl);
            }
            else if (props.healthboardType === MisServiceType.Extract) {
                return ServiceManager.ExtractService.UpdateCaseIdAndUrl(props.healthboardId, myMutationParams.salesforceCaseId, myMutationParams.salesforceCaseUrl);
            }
            else {
                return ServiceManager.TransformService.UpdateCaseIdAndUrl(props.healthboardId, myMutationParams.salesforceCaseId, myMutationParams.salesforceCaseUrl);
            }
        },
        onError: (error, variables, context) => {
            ServiceManager.ToastService.showError("Unable to update Case ID.");
        },
        onSuccess: (data, variables, context) => {
            if (data === null) {
                ServiceManager.ToastService.showError("Unable to update Case ID.");
            }
            else {
                if (data.status === APIStatusCode.NoContent) {
                    ServiceManager.ToastService.showSuccess("Case ID updated successfully.");
                    props.callBack(true);
                }
                else {
                    let errorMessage = (data.response && data.response.data && data.response.data.errors.errors) ? data.response.data.errors.errors : data.message;
                    ServiceManager.ToastService.showError(errorMessage.toString());
                }
            }
        }
    });

    const { register, handleSubmit, formState: { errors }, setError } = useForm();
    const onSubmit = (data: any) => {
        mutation.mutate({ salesforceCaseId: data.salesforceCaseId, salesforceCaseUrl: data.salesforceCaseUrl });
    };

    const handleEnterPress = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (!event.target.value) {
                setError('salesforceCaseId', {
                    message: 'Case ID is required',
                });
                return;
            }
            mutation.mutate({ salesforceCaseId: event.target.value, salesforceCaseUrl: props.salesForceParams.salesforceCaseUrl });
        }
    };

    return (
        <Stack>
            {props.modalType === ModalTypes.Delete ?
                <>
                    <p className="heading text-center mt-2">Delete Case ID</p>
                    <p className="text-center">Task ID: {props.healthboardId} - Case ID: {props.salesForceParams.salesforceCaseId}</p>
                    {props.healthboardType === MisServiceType.Task
                        ? <p className="text-center">Are you sure you want to delete the Case ID and URL from this Task? For Client Extracts, these are removed from all tasks with this Task ID.</p>
                        : <p className="text-center">Are you sure you want to delete the Case ID and URL from this Task?</p>}
                    {
                        !mutation.isLoading
                            ? (
                                <Stack direction="horizontal" className="mt-3">
                                    <MISButton text="Cancel" className="btn popup-btn right-margin10 btn-outline-secondary w-50" type="button" FnOnClick={() => props.callBack(false)} />
                                    <MISButton text="Confirm" type="button" className="popup-btn right-margin10 btn btn-danger w-50" FnOnClick={() => onSubmit({ salesforceCaseId: null, salesforceCaseUrl: null })} />
                                </Stack>
                            ) : (
                                <div className="mt-3 d-flex">
                                    <MISSpinner />
                                </div>
                            )
                    }
                </>
                :
                < form onSubmit={handleSubmit(onSubmit)} className="form">
                    <label>Case ID</label>
                    <input {...register("salesforceCaseId", { required: true })}
                        defaultValue={props.salesForceParams?.salesforceCaseId}
                        onKeyDown={handleEnterPress} maxLength={20}
                        className={`form-control mt-1 ms-0 w-100 ${(errors.salesforceCaseId) ? 'is-invalid' : ''}`} />
                    {errors.salesforceCaseId && <div className="invalid-feedback">Case ID is required</div>}
                    <label className="mt-3">URL <span className="text-muted">(Optional)</span></label>
                    <input {...register("salesforceCaseUrl")} className="form-control mt-1 ms-0 w-100" defaultValue={props.salesForceParams?.salesforceCaseUrl}></input>

                    {
                        !mutation.isLoading
                            ? (
                                <Stack direction="horizontal" className="mt-3">
                                    <MISButton text="Cancel" className="btn popup-btn right-margin10 btn-outline-secondary w-100" type="button" FnOnClick={() => props.callBack(false)} />
                                    <MISButton text={props.modalType === ModalTypes.Add ? "Create" : "Confirm"} className="popup-btn btn btn-primary crbc-bg-color w-100" type="submit" FnOnClick={handleSubmit(onSubmit)} />
                                </Stack>
                            ) : (
                                <div className="mt-3 d-flex">
                                    <MISSpinner />
                                </div>
                            )
                    }
                </form>
            }
        </Stack>
    );
}