import BaseService from "./BaseService";

export default class ClientTypeService extends BaseService {
    controllerName = "clientType";

    async GetConsumerClientTypes(): Promise<any> {
        const resp = await this.Get("consumer-clients", {});
    
        return resp ? resp.data : null;
      }
}