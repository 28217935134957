import axios from "axios";
import UserService from "./UserService";
import ExtractService from "./ExtractService";
import TransformService from "./TransformService";
import TaskService from "./TaskService";
import EstablishmentService from "./EstablishmentService";
import ProcessService from "./ProcessService";
import ToastService from "./ToastService";
import SalesforceService from "./SalesforceService";
import UserTypeService from "./UserTypeService";
import ConfigurationService from "./ConfigurationService";
import RoleService from "./RoleService";
import ClientTypeService from "./ClientTypeService";

export class ServiceManager {
  public static UserService: UserService = new UserService();
  public static ExtractService: ExtractService = new ExtractService();
  public static TransformService: TransformService = new TransformService();
  public static TaskService: TaskService = new TaskService();
  public static EstablishmentService: EstablishmentService = new EstablishmentService();
  public static ProcessService: ProcessService = new ProcessService();
  public static ToastService: ToastService = new ToastService();
  public static SalesforceService: SalesforceService = new SalesforceService();
  public static UserTypeService: UserTypeService = new UserTypeService();
  public static ConfigurationService: ConfigurationService = new ConfigurationService();
  public static RoleService: RoleService = new RoleService();
  public static ClientTypeService: ClientTypeService = new ClientTypeService();
}

let interceptorId: number | null = null;

export const setAxiosTokenInterceptor = async (accessToken: undefined | string): Promise<void> => {
  if (interceptorId !== null) {
    axios.interceptors.request.eject(interceptorId);
  }

  interceptorId = axios.interceptors.request.use(async config => {
    const requestConfig = config
    if (accessToken) {
      requestConfig.headers.Authorization = `Bearer ${accessToken}`
    } 
    return requestConfig
  })
}