import { ButtonGroup, Card, Dropdown, DropdownButton, OverlayTrigger, Spinner, Stack, Tooltip } from "react-bootstrap";
import CRBCLogo from "../../../../components/logo";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../../layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faArrowLeft, faBoxOpen, faCopy, faExclamationCircle, faEye, faTrashAlt, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import MISButton from "../../../../components/button";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ServiceManager } from "../../../../services/ServiceManager";
import React, { useState } from "react";
import CustomModal from "../../../../components/modal";
import IconElement from "../../../../components/icon";
import Skeleton from "react-loading-skeleton";
import { APIStatusCode, Permissions } from "../../../../app/enums";
import { useForm } from "react-hook-form";
import { usePermissionCheck } from "../../../../app/common/helper/Permissions";
import MISTooltip from "../../../../components/tooltip";
import { PERMISSION_REQUIRED } from "../../../../app/constants";


export default function ConsumerClients(props: any) {

    let navigate = useNavigate();
    const { establishmentId } = useParams();
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedClientTypeToDelete, setSelectedClientTypeToDelete] = useState('');
    const [selectedClientToDelete, setSelectedClientToDelete] = useState('');
    const canEditEstablishments = usePermissionCheck(Permissions.CanEditEstablishments);

    const handleCloseCreateModal = () => setShowCreateModal(false);
    const handleCloseDeleteModal = () => setShowDeleteModal(false);

    const { isLoading, data } = useQuery([`estbalishment-consumer-clients-${establishmentId}`], () =>
        ServiceManager.EstablishmentService.GetConsumerClients(establishmentId), { refetchOnWindowFocus: false }
    );

    const { isLoading: isConsumerClientTypesLoading, data: consumerClientTypes } = useQuery([`consumer-clients-types`], () =>
        ServiceManager.ClientTypeService.GetConsumerClientTypes(), { refetchOnWindowFocus: false }
    );

    const ClientCreateForm = () => {
        const queryClient = useQueryClient();
        const { establishmentId } = useParams();
        const [selectedClientType, setSelectClientType] = useState('');

        const mutation = useMutation({
            mutationFn: () => {
                return ServiceManager.EstablishmentService.CreateClient(establishmentId, selectedClientType);
            },
            onError: (error, variables, context) => {

            },
            onSuccess: (data, variables, context) => {
                if (data === null) {
                    ServiceManager.ToastService.showError("Unable to add Connected App.");
                }
                else {
                    if (data.status === APIStatusCode.Created) {
                        ServiceManager.ToastService.showSuccess("Connected App added successfully.");
                        queryClient.invalidateQueries({ queryKey: [`estbalishment-consumer-clients-${establishmentId}`] });
                        handleCloseCreateModal();
                    }
                    else {
                        const errors = data?.response?.data?.errors;
                        if (errors) {
                            Object.keys(errors).forEach(field => {
                                ServiceManager.ToastService.showError(`${field}: ${errors[field][0]}`);
                            });
                        }
                        else {
                            ServiceManager.ToastService.showError(data?.message);
                        }
                    }
                }
            }
        });
        const { register, handleSubmit, setValue, clearErrors, formState: { errors } } = useForm();
        const onSubmit = () => {
            if (data) {
                const isClientExist = data.some((x: any) => x.clientType === parseInt(selectedClientType));
                if (isClientExist) {
                    ServiceManager.ToastService.showError("App with the specified type already exists.");
                    return;
                }
            }
            mutation.mutate();
        };

        const handleClientTypeSelect = (eventKey: any) => {
            setValue('clientTypeId', eventKey);
            setSelectClientType(eventKey);
            clearErrors('clientTypeId');
        }

        return (
            <Stack>
                <form onSubmit={handleSubmit(onSubmit)} className="form">
                    <label className="mt-1">Connected App</label>
                    <Dropdown className="m-auto mt-1" onSelect={(event, e) => handleClientTypeSelect(event)}>
                        <Dropdown.Toggle className="btn btn-light w-100 d-flex justify-content-between align-items-center"
                            {...register(`clientTypeId`, { required: 'App is required' })}>
                            {selectedClientType && consumerClientTypes ? consumerClientTypes.find((x: any) => x.clientTypeID === parseInt(selectedClientType)).clientTypeName : 'Select'}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="timer-dropdown w-100">
                            {!isConsumerClientTypesLoading && consumerClientTypes &&
                                consumerClientTypes.map((clientType: any) => (
                                    <Dropdown.Item key={clientType.clientTypeID} eventKey={clientType.clientTypeID}>
                                        {clientType.clientTypeName}
                                    </Dropdown.Item>
                                ))
                            }
                        </Dropdown.Menu>
                    </Dropdown>

                    {errors.clientTypeId && <div className="invalid-feedback" style={{ display: 'block', color: '#DC3245' }}>App is required</div>}

                    {
                        !mutation.isLoading
                            ? (<Stack direction="horizontal" className="mt-3 pb-2">
                                <MISButton text="Cancel" className="btn popup-btn right-margin10 btn-outline-secondary w-100" FnOnClick={handleCloseCreateModal} />
                                <MISButton text="Add" className="popup-btn btn btn-primary crbc-bg-color w-100" type="submit" FnOnClick={handleSubmit(onSubmit)} />
                            </Stack>
                            ) : (
                                <div className="d-flex justify-content-center mt-3">
                                    <Spinner
                                        animation="border"
                                        variant="info"
                                        role="status"
                                    >
                                    </Spinner>
                                </div>
                            )
                    }
                </form>
            </Stack>
        )
    }

    const ClientCard = (props: any) => {
        const [revealCredentials, setRevealCredentials] = useState(false);
        const [showResetModal, setShowResetModal] = useState(false);
        const [showClientIdTooltip, setShowClientidTooltip] = useState(false);
        const [showClientSecretTooltip, setShowClientSecretTooltip] = useState(false);
        const [selectedClientId, setSelectedClientId] = useState('');
        const handleCloseResetModal = () => setShowResetModal(false);

        const clientCardOptionsDropdown: { value: number, text: string }[] = [
            { value: 0, text: "View" },
            { value: 1, text: "Delete" },
            { value: 2, text: "Reset Credentials" }
        ];

        const checkCredentials = () => {
            setRevealCredentials(true);
        }

        function handleClientOptionClick(option: number) {
            switch (option) {
                case 0:
                    navigate(`/establishment/${establishmentId}/comsumer-clients/${props?.data?.clientID}/custom-fields`);
                    break;
                case 1:
                    setSelectedClientTypeToDelete(props.data?.clientType);
                    setSelectedClientToDelete(props.data?.clientID);
                    setShowDeleteModal(true);
                    break;
                case 2:
                    setSelectedClientId(props.data?.clientID);
                    setShowResetModal(true);
                    break;
                default:
                    break;
            }

        }

        const ResetConfirmationForm = () => {
            const { establishmentId } = useParams();
            const queryClient = useQueryClient();

            const resetMutation = useMutation(async () => {
                return await ServiceManager.ConfigurationService.ResetClientSecret(establishmentId, selectedClientId);
            }, {
                onSuccess: (data) => {
                    if (data === null) {
                        ServiceManager.ToastService.showError("Unable to reset client secret.");
                    }
                    else {
                        if (data.status === APIStatusCode.NoContent) {
                            ServiceManager.ToastService.showSuccess("Client secret reset successfully.");
                            queryClient.invalidateQueries({ queryKey: [`estbalishment-consumer-clients-${establishmentId}`] });
                            handleCloseResetModal();
                        }
                        else {
                            const errors = data?.response?.data?.errors;
                            if (errors) {
                                Object.keys(errors).forEach(field => {
                                    ServiceManager.ToastService.showError(`${field}: ${errors[field][0]}`);
                                });
                            }
                            else {
                                ServiceManager.ToastService.showError(data?.message);
                            }
                        }
                    }
                },
            });

            return (
                <Stack>
                    <p className="heading text-center">Reset Keys</p>
                    <p className="text-center">Generating new keys for this client will cause it to stop communicating with MIS Central until the new keys are configured in that client. Are you sure you want to proceed?</p>
                    {
                        !resetMutation.isLoading
                            ? (<Stack direction="horizontal" className="mt-3">
                                <MISButton text="Cancel" className="popup-btn right-margin10 btn btn-outline-secondary w-50" FnOnClick={handleCloseResetModal} />
                                <MISButton text="Reset" className="popup-btn right-margin10 btn btn-warning w-50" FnOnClick={() => { resetMutation.mutate(); }} />
                            </Stack>
                            ) : (
                                <div className="d-flex justify-content-center mt-3">
                                    <Spinner
                                        animation="border"
                                        variant="info"
                                        role="status"
                                    >
                                    </Spinner>
                                </div>
                            )
                    }
                </Stack>
            )
        }

        return (
            <div className="client">
                <Card style={{height: '15em'}}>
                    <Card.Body className="d-flex flex-column justify-content-between">
                        <Stack direction="horizontal" gap={3}>
                            <div className="d-flex justify-content-center align-items-center blue-bg rounded-3 package-icon">
                                <div className="d-flex justify-content-center align-items-center crbc-icon package-icon-inner rounded-3 white-background-shadow">
                                    <CRBCLogo />
                                </div>
                            </div>
                            <Stack>
                                <Card.Title as="h6">{props.data.clientTypeName}</Card.Title>
                            </Stack>
                            <DropdownButton as={ButtonGroup} title={<FontAwesomeIcon icon={faEllipsisV} />} id="consumer-client-dropdown-id" className="timer-dropdown">
                                {
                                    clientCardOptionsDropdown.map((data, index) =>
                                        <React.Fragment key={`clientCard${index}`}>
                                            {
                                                (canEditEstablishments) ? (<Dropdown.Item key={data.value} onClick={() => handleClientOptionClick(data.value)}>
                                                    {data.text}
                                                </Dropdown.Item>) : (!canEditEstablishments && data.value === 0) ? (<Dropdown.Item key={data.value} onClick={() => handleClientOptionClick(data.value)}>
                                                    {data.text}
                                                </Dropdown.Item>) : (!canEditEstablishments && (data.value === 1 || data.value === 2)) ? (
                                                    <MISTooltip message={PERMISSION_REQUIRED} placement="bottom">
                                                        <Dropdown.Item key={data.value} id="disabled-cc-option">
                                                            {data.text}
                                                        </Dropdown.Item>
                                                    </MISTooltip>) : (<span></span>)
                                            }
                                        </React.Fragment>
                                    )
                                }
                            </DropdownButton>
                        </Stack>
                        {!revealCredentials &&
                            <MISButton text="Reveal"
                                className="btn btn-light w-100 button-light mt-2"
                                FnOnClick={() => checkCredentials()}
                                icon={<FontAwesomeIcon icon={faEye} />}></MISButton>
                        }
                        {revealCredentials &&
                            <div className="container">
                                <div className="row my-2">
                                    <div className="ps-0 col-3 font-family14">ID</div>
                                    <div className="ps-0 col-8 font-11">{props.data.clientID}</div>
                                    <div className="ps-0 col-1">
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={<Tooltip id="tooltip">Copied to clipboard</Tooltip>}
                                            show={showClientIdTooltip}
                                        >
                                            <FontAwesomeIcon className="cursor-pointer crbc-color"
                                                title="Copy to clipboard" icon={faCopy} onClick={() => {
                                                    navigator.clipboard.writeText(props.data.clientID);
                                                    setShowClientidTooltip(true);
                                                    setTimeout(() => {
                                                        setShowClientidTooltip(false);
                                                    }, 2000);

                                                }} />
                                        </OverlayTrigger>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="ps-0 col-3 font-family14">Secret</div>
                                    <div className="ps-0 col-8 font-11">{props.data.secretKey}</div>
                                    <div className="ps-0 col-1">
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={<Tooltip id="tooltip">Copied to clipboard</Tooltip>}
                                            show={showClientSecretTooltip}
                                        >
                                            <FontAwesomeIcon className="cursor-pointer crbc-color"
                                                title="Copy to clipboard" icon={faCopy} onClick={() => {
                                                    navigator.clipboard.writeText(props.data.secretKey);
                                                    setShowClientSecretTooltip(true);
                                                    setTimeout(() => {
                                                        setShowClientSecretTooltip(false);
                                                    }, 2000);
                                                }} />
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </div>
                        }
                    </Card.Body>
                    {
                        <Card.Footer>
                            <Stack direction="horizontal">
                                <div style={{ height: '65px', width: '100%', overflowY: 'auto' }}>
                                    {props.data?.customFields?.map((customField: any) => {
                                        if (customField) {
                                            return (
                                                <span className="badge rounded-pill bg-primary me-2 mb-2" key={customField.customFieldName}>
                                                    {customField.customFieldName}
                                                </span>
                                            );
                                        }
                                        return null;
                                    })}
                                    {props.data?.customFields.length === 0 &&
                                        <div className="w-100 text-center">
                                            <span className="text-muted">No custom fields added yet</span>
                                        </div>
                                    }
                                </div>
                            </Stack>
                        </Card.Footer>
                    }
                </Card>

                <CustomModal isShow={showResetModal} handleClose={handleCloseResetModal} header={<IconElement iconType={faExclamationCircle} headerClass="warning-icon ms-6" color="#DC6803" />} size="md">
                    <ResetConfirmationForm />
                </CustomModal>
            </div>
        )
    }

    const DeleteClientForm = () => {
        const queryClient = useQueryClient();
        const mutation = useMutation({
            mutationFn: () => {
                return ServiceManager.EstablishmentService.DeleteClient(establishmentId, selectedClientTypeToDelete, selectedClientToDelete);
            },
            onError: (error, variables, context) => {

            },
            onSuccess: (data, variables, context) => {
                if (data === null) {
                    ServiceManager.ToastService.showError("Unable to delete Connected App.");
                }
                else {

                    if (data.status === APIStatusCode.NoContent) {
                        ServiceManager.ToastService.showSuccess("Connected App deleted successfully.");
                        queryClient.invalidateQueries({ queryKey: [`estbalishment-consumer-clients-${establishmentId}`] });
                        handleCloseDeleteModal();
                    }
                    else {
                        const errors = data?.response?.data?.errors;
                        if (errors) {
                            Object.keys(errors).forEach(field => {
                                ServiceManager.ToastService.showError(`${field}: ${errors[field][0]}`);
                            });
                        }
                        else {
                            ServiceManager.ToastService.showError(data?.message);
                        }
                    }
                }
            }
        });
        return (
            <Stack>
                <p className="heading text-center mt-2">Delete App</p>
                <p className="text-center">All associated Custom Fields for this app will also be deleted, and not be visible to the end consumer. This action cannot be undone.</p>
                {
                    !mutation.isLoading
                        ? (<Stack direction="horizontal" className="mt-3">
                            <MISButton text="Cancel" className="popup-btn right-margin10 btn btn-outline-secondary w-50" FnOnClick={handleCloseDeleteModal} />
                            <MISButton text="Delete" className="popup-btn right-margin10 btn btn-danger w-50" FnOnClick={() => { mutation.mutate(); }} />
                        </Stack>
                        ) : (
                            <div className="d-flex justify-content-center mt-3">
                                <Spinner
                                    animation="border"
                                    variant="info"
                                    role="status"
                                >
                                </Spinner>
                            </div>
                        )
                }
            </Stack>
        )
    }

    const ModalHeader = ({ heading, title }: any) => {
        return (
            <Stack>
                <p className="heading">{heading}</p>
            </Stack>
        )
    }


    return (
        <Layout title="Connected Apps"
            backArrow={
                <FontAwesomeIcon icon={faArrowLeft} onClick={() => navigate(-1)} className="me-4 fs-5 cursor-pointer" />
            }
            button={
                canEditEstablishments ?
                    <MISButton text="Connected App" className="btn btn-primary crbc-bg-color" FnOnClick={() => setShowCreateModal(true)} icon={<FontAwesomeIcon icon={faAdd} />}></MISButton>
                    :
                    <MISTooltip message={PERMISSION_REQUIRED} placement="bottom">
                        <div className="disabled">
                            <MISButton text="Connected App" className="btn btn-secondary" FnOnClick={() => null} disabled icon={<FontAwesomeIcon icon={faAdd} />}></MISButton>
                        </div>
                    </MISTooltip>
            }
        >
            {!isLoading && data && data.length === 0 &&
                <div className="stats-widget-box">
                    <Stack className="d-flex justify-content-center align-items-center">
                        <FontAwesomeIcon icon={faBoxOpen} size="4x" className="me-4 grey-color"></FontAwesomeIcon>
                        <span className="text-muted mt-3">No apps connected yet</span>
                    </Stack>
                </div>
            }

            {!isLoading && data &&
                <div className="container-fluid">
                    <div className="row clients-container">
                        {data.map((client: any) => {
                            return <div className="col-xl-4 col-6 mb-2">
                                <ClientCard data={client} />
                            </div>
                        })}
                    </div>
                </div>
            }

            {isLoading &&
                <div className="container-fluid mis-loader">
                    <div className="row">
                        <div className="col-xl-4 col-6">
                            <Skeleton height={`15em`} />
                        </div>
                        <div className="col-xl-4 col-6">
                            <Skeleton height={`15em`} />
                        </div>
                        <div className="col-xl-4 col-6">
                            <Skeleton height={`15em`} />
                        </div>
                    </div>
                </div>
            }

            <CustomModal isShow={showCreateModal} handleClose={handleCloseCreateModal} header={<ModalHeader heading="Add New Connected App" />} size="md">
                <ClientCreateForm />
            </CustomModal>

            <CustomModal isShow={showDeleteModal} handleClose={handleCloseDeleteModal} header={<IconElement iconType={faTrashAlt} headerClass="error-icon ms-6" color="#D92D20" />} size="md">
                <DeleteClientForm />
            </CustomModal>

        </Layout>
    );
}