import { createColumnHelper } from "@tanstack/react-table";
import { EstablishmentDataType } from "../../../../app/enums";
import PagedFilterTable from "../../../../components/table";
import { ServiceManager } from "../../../../services/ServiceManager";
import { ITransformProperties } from "../../../../models/TransformData";

interface ICustomFieldsModal {
    callFrom: EstablishmentDataType;
    crbcId: any;
    establishmentId: any
}

const CustomFieldsModal: React.FC<ICustomFieldsModal> = ({ callFrom, crbcId, establishmentId }) => {

    async function fetchData(options: {
        pageIndex: number;
        pageSize: number;
        searchValue: string
    }) {
        if (callFrom === EstablishmentDataType.Transform) {
            const transformData = await ServiceManager.EstablishmentService.GetTransformProperties(establishmentId, crbcId);
            return transformData;
        }
        else {
            const finalData = await ServiceManager.EstablishmentService.GetFinalProperties(establishmentId, crbcId);
            return finalData;
        }
    }

    const columnHelper = createColumnHelper<ITransformProperties>();
    const columns = [
        columnHelper.accessor('propertyName', {
            header: () => <span>Field Name</span>,
            size: 25

        }),
        columnHelper.accessor('propertyValue', {
            header: () => <span>Field Value</span>,
            size: 50
        })
    ];

    const transformFinalcolumns = [
        columnHelper.accessor('propertyName', {
            header: () => <span>Field Name</span>,
            size: 25

        }),
        columnHelper.accessor('propertyValue', {
            header: () => <span>Field Value</span>,
            size: 50
        }),
        columnHelper.accessor('clientTypeName', {
            header: () => <span>App</span>,
            size: 25
        })

    ];

    return (
        <div id="listView-data">
            <PagedFilterTable
                tableKey={`additional-properties-${crbcId}`}
                columns={callFrom === EstablishmentDataType.TransformFinal ? transformFinalcolumns : columns}
                data={[]}
                usePagination={false}
                pageRecords={25}
                fnFetchData={fetchData}
                useSearch={false}
            />
        </div>
    );
};

export default CustomFieldsModal;