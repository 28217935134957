import Layout from "../layout";
import jwt_decode from "jwt-decode";
import { useAuth } from "react-oidc-context";
import React, {useEffect, useState} from "react";
import MisStats from "../../components/misstats/mis-stats";
import {Permissions, timeUnit} from "../../app/enums";
import TimeFilter from "../../components/filter/time-filter";
import {usePermissionCheck} from "../../app/common/helper/Permissions";
import { useNavigate } from "react-router-dom";
export default function Dashboard() {
  const auth = useAuth();
  const [selectedTimer, setSelectedTimer] = useState(12);
  const [selectedTimeUnit, setselectedTimeUnit] = useState(timeUnit.hour);
  const [finishDate, setFinishDate] = useState(new Date());
  var defaultStartDate = new Date();
  defaultStartDate.setHours(defaultStartDate.getHours() - 12); // default date will be 12 hours/720 Minutes
  const [startDate, setStartDate] = useState(defaultStartDate);

  var jwt: any;
  let canViewDashboard: boolean = usePermissionCheck(Permissions.CanViewDashboard);
  const navigate = useNavigate();

  if (auth.isAuthenticated) {
    jwt = jwt_decode(auth.user?.access_token ?? "", {header: false});
  }

  useEffect(() => {
    if (!canViewDashboard) {
      navigate('/healthboard');
    }
  }, [canViewDashboard,navigate]);
  
  function setTimes(
    time: any,
    timeUnit: timeUnit,
    callBackStartDate: Date,
    callBackfinishDate: Date,
    isRefresh?: boolean
  ) {
    if (time === "") {
      return;
    }

    setSelectedTimer(time);
    setselectedTimeUnit(timeUnit);
    setFinishDate(callBackfinishDate);
    setStartDate(callBackStartDate);
  }

  return (
    <React.Fragment>
      {auth.isAuthenticated && (
        <Layout title={auth.isAuthenticated && "Welcome " + jwt.name}>
          <div className="row">
            <TimeFilter
              selectedTimer={selectedTimer}
              selectedTimeUnit={selectedTimeUnit}
              setTimes={setTimes}
              isRefresh={true}
            ></TimeFilter>
            <div className="col-xxl-4 col-6">
              <MisStats
                serviceName="Task"
                headingText="Client Extracts"
                selectedTimer={selectedTimer}
                startDate={startDate}
                finishDate={finishDate}
              ></MisStats>
            </div>
            <div className="col-xxl-4 col-6">
              <MisStats
                serviceName="Extract"
                headingText="Data Extracts"
                selectedTimer={selectedTimer}
                startDate={startDate}
                finishDate={finishDate}
              ></MisStats>
            </div>
            <div className="col-xxl-4 col-6">
              <MisStats
                serviceName="Transform"
                headingText="Transform Jobs"
                selectedTimer={selectedTimer}
                startDate={startDate}
                finishDate={finishDate}
              ></MisStats>
            </div>
          </div>
        </Layout>
      )}
    </React.Fragment>
  );
}
