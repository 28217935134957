import { Stack } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { usePermissionCheck } from "../../../../../app/common/helper/Permissions";
import { Permissions } from "../../../../../app/enums";
import MISTooltip from "../../../../../components/tooltip";
import { PERMISSION_REQUIRED } from "../../../../../app/constants";

export interface ISubTaskCardProps {
    subTaskIndex: number;
    headingText: any;
    databaseServer: string;
    databaseName: string; // currently in hours
    photos: boolean;
    selectedUserTypes: [];
    allUserTypes: [],
    onDelete: any
}
export default function SubTaskCard(props: ISubTaskCardProps) {

    const { establishmentId, taskId } = useParams();
    let navigate = useNavigate();
    const canEditEstablishments = usePermissionCheck(Permissions.CanEditEstablishments);

    return (
        <div className="subtask-widget-box">
            <div className="card">
                <Stack direction="horizontal">
                    <div className="header">
                        {props.headingText}
                    </div>
                    <div className="d-flex flex-row stats-widget-box-header-button" style={{ cursor: 'default' }}>
                        {
                            canEditEstablishments ?
                                <FontAwesomeIcon
                                    title="Delete Subtask"
                                    className="cursor-pointer red-color"
                                    icon={faTrashAlt}
                                    size="lg"
                                    onClick={() => {
                                        props.onDelete(props.subTaskIndex);
                                    }}
                                />
                                :
                                <MISTooltip message={PERMISSION_REQUIRED} placement="top">
                                    <FontAwesomeIcon
                                        icon={faTrashAlt}
                                        size="lg"
                                        className="disabled"
                                    />
                                </MISTooltip>
                        }                        
                        <p className="me-3"></p>
                        {
                            canEditEstablishments ?
                                <FontAwesomeIcon
                                    title="Edit Subtask"
                                    className="cursor-pointer"
                                    icon={faPencil}
                                    size="lg"
                                    onClick={() => {
                                        navigate(`/establishment/${establishmentId}/tasks/${taskId}/subtasks/${props.subTaskIndex}`);
                                    }}
                                />
                                :
                                <MISTooltip message={PERMISSION_REQUIRED} placement="top">
                                    <FontAwesomeIcon
                                        icon={faPencil}
                                        size="lg"
                                        className="disabled"
                                    />
                                </MISTooltip>
                        }   
                    </div>
                </Stack>
                <Stack direction="vertical">
                    <Stack direction="horizontal">
                        <div style={{ height: '65px', overflowY: 'auto' }}>
                            {props.selectedUserTypes?.map((userTypeId: any) => {
                                const userType: any = props.allUserTypes.find((type: any) => type.userTypeID === userTypeId);
                                if (userType) {
                                    return (
                                        <span className="badge rounded-pill bg-primary me-2 mb-2" key={userType.userTypeID}>
                                            {userType.userTypeID} - {userType.userTypeName}
                                        </span>
                                    );
                                }
                                return null;
                            })}
                        </div>
                    </Stack>
                    <hr style={{ width: '100%', borderTop: '1px solid #CEDDF3', margin: 0, opacity: 0.5 }} />
                    <div style={{ height: '140px', overflowY: 'auto' }}>
                        <div className="card-subheader">Database Server</div>
                        <div className="card-subheader-text"> {props.databaseServer ? props.databaseServer : "Not Found"}</div>
                        <div className="card-subheader">Database Name</div>
                        <div className="card-subheader-text"> {props.databaseName ? props.databaseName : "Not Found"} </div>
                        <div className="card-subheader">Photos</div>
                        <div className={`card-subheader-text ${props.photos ? "enabled" : "not-enabled"}`}>{props.photos ? 'Yes' : 'No'}</div>
                    </div>
                </Stack>
            </div>
        </div>
    );
}