import axios, { CancelTokenSource, AxiosResponse } from "axios";

export default class BaseService {
  controllerName = "ping";
  baseHeaders = {
    "Access-Control-Allow-Origin": "*",
    "Content-type": "Application/json",
  };
  baseHeadersFile = {
    "content-type": "multipart/form-data",
  };
  timeout: number;

  constructor() {
    this.timeout = 60000;
    axios.defaults.timeout = this.timeout;
  }

  GetToken(): CancelTokenSource {
    const tokenSource = axios.CancelToken.source();
    setTimeout(() => {
      tokenSource.cancel();
    }, this.timeout);
    return tokenSource;
  }

  protected async Get(
    action: string,
    body: object
  ): Promise<AxiosResponse | any | void> {
    const headers = await this.assembleHeaders();
    try {
      const cancelToken = this.GetToken();
      const response = await axios.get(this.assembleEndpoint(action, body), {
        cancelToken: cancelToken.token,
        headers: { ...headers },
      });

      return response;
    } catch (e) {
      return e;
    }
  }

  protected async GetBlob(
    action: string,
    body: object
  ): Promise<AxiosResponse | any | void> {
    const headers = await this.assembleHeaders();
    try {
      const cancelToken = this.GetToken();
      const response = await axios.get(this.assembleEndpoint(action, body), {
        cancelToken: cancelToken.token,
        headers: { ...headers },
        responseType: "blob",
      });

      return response;
    } catch (e) {
      return e;
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  protected async Post(
    action: string,
    body: object
  ): Promise<AxiosResponse | any | void> {
    const headers = await this.assembleHeaders();

    try {
      const cancelToken = this.GetToken();
      const response = await axios.post(this.assembleEndpoint(action), body, {
        cancelToken: cancelToken.token,
        headers: { ...headers },
      });

      return response;
    } catch (e) {
      return e;
    }
  }

  protected async PostFile(
    action: string,
    body: any
  ): Promise<AxiosResponse | any> {
    const headers = await this.assembleHeadersFile();

    try {
      const cancelToken = this.GetToken();
      const response = await axios.post(this.assembleEndpoint(action), body, {
        cancelToken: cancelToken.token,
        headers: { ...headers },
      });

      return response;
    } catch (e) {
      return e;
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  protected async Patch(
    action: string,
    body: object
  ): Promise<AxiosResponse | any | void> {
    const headers = await this.assembleHeaders();

    try {
      const cancelToken = this.GetToken();
      const response = await axios.patch(this.assembleEndpoint(action), body, {
        cancelToken: cancelToken.token,
        headers: { ...headers },
      });

      return response;
    } catch (e) {
      return e;
    }
  }
  protected async Put(
    action: string,
    body: object
  ): Promise<AxiosResponse | any | void> {
    const headers = await this.assembleHeaders();
    try {
      const cancelToken = this.GetToken();
      const response = await axios.put(this.assembleEndpoint(action), body, {
        cancelToken: cancelToken.token,
        headers: { ...headers },
      });

      return response;
    } catch (e) {
      return e;
    }
  }

  protected async Delete(action: string): Promise<AxiosResponse | any | void> {
    const headers = await this.assembleHeaders();

    try {
      const cancelToken = this.GetToken();
      const response = await axios.delete(this.assembleEndpoint(action), {
        cancelToken: cancelToken.token,
        headers: { ...headers },
      });

      return response;
    } catch (e) {
      return e;
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  protected assembleEndpoint(action: string, data?: object): string {
    const apiAddress = process.env.REACT_APP_MIS_CENTRAL_API_ADDRESS;
    let endpoint;
    if (action) endpoint = `${apiAddress}/${this.controllerName}/${action}`;
    else endpoint = `${apiAddress}/${this.controllerName}`;

    if (data) {
      let query = "";

      Object.keys(data).forEach((k) => {
        query = `${query}${k}=${data[k as keyof typeof data]}&`;
      });

      endpoint = `${endpoint}?${query}`;
    }

    //console.log("service - " + endpoint);

    return endpoint;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  async assembleHeaders(): Promise<object> {
    return { ...this.baseHeaders };
  }
  async assembleHeadersFile(): Promise<object> {
    return { ...this.baseHeadersFile };
  }
}
