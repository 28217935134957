import React, { useState } from "react";
import PagedFilterTable from "../table";
import { createColumnHelper } from "@tanstack/react-table";
import "bootstrap/dist/css/bootstrap.css";
import { ServiceManager } from "../../services/ServiceManager";
import { Button } from "react-bootstrap";
import IEstablishment from "../../models/Establishment";
import { NavLink } from "react-router-dom";
import MISTooltip from "../tooltip";

interface IEstablishmentListProps {
    handleClose: any;
    handleEstablishmentFilter: any;
}
export default function EstablishmentList(props: IEstablishmentListProps) {
    const [rowSelection, setRowSelection] = useState(null);
    const [hasChilds, setHasChilds] = useState(false);
    const [tableJobs, setTableJobs] = useState([]);
    const columnHelper = createColumnHelper<IEstablishment>();
    const columns = [
        columnHelper.display({
            id: "select_establishmentID",
            size: 1,
            cell: props => <input type="radio" className="cursor-pointer" checked={rowSelection === props.row.original.establishmentID} onChange={(e) => { onRowClick(props.row); }}></input>,
        }),
        columnHelper.accessor('name', {
            header: () => <span>Name</span>,
            size: 150,
            cell: ({ row }) => (
                <NavLink to={`/establishment/${row.original.establishmentID}`}>
                    {row.original.name}
                </NavLink>
            ),
        }),
        columnHelper.accessor('salesforceExternalID', {
            header: () => <span>External ID</span>,
            size: 150
        })
    ];

    function onRowClick(row: any) {
        const selectedValue = row.original.establishmentID;
        setRowSelection((prevValue) => (prevValue === selectedValue ? null : selectedValue));
        setHasChilds(row.original.parent === null);
    }

    async function fetchData(options: {
        pageIndex: number;
        pageSize: number;
        searchValue: string;
    }) {
        if (options.searchValue === undefined) { return false; }
        const establishmentResponse = await ServiceManager.EstablishmentService.GetAllWithChildern(false, options.pageIndex + 1, options.pageSize, options.searchValue, 0);
        setTableJobs(establishmentResponse);
        return establishmentResponse;
    }

    function onClose() {
        try {
            props.handleClose();
        } catch (e) {
            console.error(e);
        }
    }

    function handleApply(includeChildren: boolean) {
        try {
            props.handleEstablishmentFilter(rowSelection, includeChildren);
            props.handleClose();

        } catch (e) {
            console.error(e);
        }
    }


    return (
        <React.Fragment>
            <div id="establishmentFilterTable">
                <PagedFilterTable
                    tableKey={"establishments-filter"}
                    columns={columns}
                    data={tableJobs}
                    usePagination={true}
                    useSearch={true}
                    pageRecords={8}
                    fnFetchData={fetchData}
                />
            </div>
            <div className="mt-4">
                <Button
                    variant="popup-btn right-margin10 btn-outline-secondary"
                    onClick={() => onClose()}
                >
                    Cancel
                </Button>
                <MISTooltip message={'Filters the Healthboard to show only results for selected establishment.'} placement="top">
                    <Button
                        type="submit"
                        variant="popup-btn btn btn-primary crbc-bg-color me-2 "
                        className={` ${rowSelection === null ? "disabled" : ""}`}
                        onClick={() => handleApply(false)}
                    >
                        Apply
                    </Button>
                </MISTooltip>
                {
                    hasChilds &&
                    <MISTooltip message={'Filters the Healthboard to show results for selected establishment and any child establishments.'} placement="top">
                        <Button
                            type="submit"
                            variant="popup-btn btn btn-primary crbc-bg-color "
                            className={` ${rowSelection === null ? "disabled" : ""}`}
                            onClick={() => handleApply(true)}
                        >
                            Apply Org
                        </Button>
                    </MISTooltip>
                }
            </div>
        </React.Fragment>
    )
};