import React, { useState } from "react";
import { Col, Row, Tab } from "react-bootstrap";
import NavTabs from "./view-data/nav-tabs";
import RawData from "./view-data/raw-data";
import TransformData from "./view-data/transform-data";
import TransformFinal from "./view-data/transform-final";
import RawXml from "./view-data/raw-xml";
import { EstablishmentDataType } from "../../app/enums";

export default function EstablishmentData(props: any) {
    const [activeKey, setActiveKey] = useState(EstablishmentDataType.RowXML);

    function callbackActiveTab(selectedKey: any) {
        setActiveKey(selectedKey);
    }

    return (
        <div className="mt-1">
            <Tab.Container id="establishment-people-data" defaultActiveKey={activeKey}>
                <Row className="people-data">
                    <Col sm={12}>
                        <NavTabs callbackActiveTab={callbackActiveTab} />
                    </Col>
                </Row>
                <Row className="mt-1">
                    <Col sm={12}>
                        <Tab.Content>
                            <Tab.Pane eventKey={activeKey}>
                                {activeKey === EstablishmentDataType.RowXML && <RawXml />}
                                {activeKey === EstablishmentDataType.RowData && <RawData />}
                                {activeKey === EstablishmentDataType.Transform && <TransformData />}
                                {activeKey === EstablishmentDataType.TransformFinal && <TransformFinal />}
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>

    );
}