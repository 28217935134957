import { createColumnHelper } from "@tanstack/react-table";
import { useState } from "react";
import { Button, Stack } from "react-bootstrap";
import { ServiceManager } from "../../../services/ServiceManager";
import ITransformData from "../../../models/TransformData";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import PagedFilterDataTable from "../../../components/table/filter-table";
import { FilterTableCallFrom } from "../../../app/enums";

interface IForceRefresh {
    establishmentId: any;
    handleClose: any;
}

export default function ForceRefresh(props: IForceRefresh) {

    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const [selectAll, setSelectAll] = useState(false);
    const [tableJobs, setTableJobs] = useState([]);    
    const columnHelper = createColumnHelper<ITransformData>();
    const columns = [
        columnHelper.display({
            id: "select_data",
            size: 1,
            header: () => 
                <input type="checkbox" checked={selectAll} onChange={handleSelectAllChange} />,
            cell: props =>
                <input
                    type="checkbox" className="cursor-pointer"
                    checked={selectedOptions.includes(props.row.original.crbcID)}
                    onChange={() => handleCheckboxChange(props.row.original.crbcID)}
                />,
        }),
        columnHelper.accessor('crbcID', {
            header: () => <span>Crbc ID</span>
        }),
        columnHelper.accessor('establishmentID2', {
            header: () => <span>Establishment ID</span>
        }),
        columnHelper.accessor('misID', {
            header: () => <span>Mis ID</span>
        }),
        columnHelper.accessor('misID2', {
            header: () => <span>Mis ID 2</span>
        }),
        columnHelper.accessor('userTypeID', {
            header: () => <span>User Type ID</span>
        }),
        columnHelper.accessor('title', {
            header: () => <span>Title</span>
        }),
        columnHelper.accessor('surname', {
            header: () => <span>Surname</span>
        }),
        columnHelper.accessor('firstName', {
            header: () => <span>First Name</span>
        }),
        columnHelper.accessor('legalSurname', {
            header: () => <span>Legal Surname</span>
        }),
        columnHelper.accessor('legalFirstName', {
            header: () => <span>Legal First Name</span>
        }),
        columnHelper.accessor('year', {
            header: () => <span>Year</span>
        }),
        columnHelper.accessor('regClass', {
            header: () => <span>Reg Class</span>
        }),
        columnHelper.accessor('dateOfBirth', {
            header: () => <span>Date of Birth</span>,
            cell: ({ row }) => (
                <Stack>
                    <span>{row.original.dateOfBirth ? format(new Date(row.original.dateOfBirth), "dd/MM/yyyy") : ''}</span>
                </Stack>
            )
        }),
        columnHelper.accessor('gender', {
            header: () => <span>Gender</span>,
            cell: ({ row }) => (
                <Stack>
                    <span>{row.original.gender === 'M' ? 'Male' : row.original.gender === 'F' ? 'Female' :  'Not Specified'}</span>
                </Stack>
            )
        }),
        columnHelper.accessor('isFsm', {
            header: () => <span>Fsm</span>,
            cell: ({ row }) => (
                <Stack>
                    <span>{row.original.isFsm ? 'Yes' : 'No'}</span>
                </Stack>
            )
        }),
        columnHelper.accessor('dateFsmExpiry', {
            header: () => <span>Fsm Expiry</span>,
            cell: ({ row }) => (
                <Stack>
                    <span className="font-dark">{row.original.dateFsmExpiry ? format(new Date(row.original.dateFsmExpiry), "p") : ''}</span>
                    <span>{row.original.dateFsmExpiry ? format(new Date(row.original.dateFsmExpiry), "dd-MM-yyyy") : ''}</span>
                </Stack>
            )
        }),
        columnHelper.accessor('admissionNumber', {
            header: () => <span>Admission Number</span>
        }),
        columnHelper.accessor('staffCode', {
            header: () => <span>Staff Code</span>
        }),
        columnHelper.accessor('dietaryPreferences', {
            header: () => <span>Dietary Preferences</span>
        }),
        columnHelper.accessor('dietaryNeeds', {
            header: () => <span>Dietary Needs</span>
        }),
        columnHelper.accessor('parentalConsent', {
            header: () => <span>Parental Consent</span>,
            cell: ({ row }) => (
                <span className={row.original.parentalConsent ? 'parent-consent-td' : ''}>{row.original.parentalConsent ?? ''}</span>
            )
        }),
        columnHelper.accessor('photoHash', {
            header: () => <span>Photo Hash</span>
        }),
        columnHelper.accessor('hasPhoto', {
            header: () => <span>Has Photo</span>,
            cell: ({ row }) => (
                <Stack>
                    <span>{row.original.hasPhoto ? 'Yes' : 'No'}</span>
                </Stack>
            )
        }),
        columnHelper.accessor('isActive', {
            header: () => <span>Status</span>,
            cell: ({ row }) => (
                <div className={`p-1 ps-2 pe-2 ${row.original.isActive ? 'queue-completed' : 'queue-notexecuted'}`}>
                    <FontAwesomeIcon icon={faCircle} size="2xs" className="icon" />
                    <span className="ms-2">{row.original.isActive ? 'Active' : 'Disabled'}</span>
                </div>
            )
        }),
        columnHelper.accessor('forceRefresh', {
            header: () => <span>Force Refresh</span>,
            cell: ({ row }) => (
                <Stack>
                    <span>{row.original.forceRefresh ? 'Yes' : 'No'}</span>
                </Stack>
            )
        }),
        columnHelper.accessor('dateLastActive', {
            header: () => <span>Date Last Active</span>,
            cell: ({ row }) => (
                <Stack>
                    <span>{row.original.dateLastActive ? format(new Date(row.original.dateLastActive), "dd/MM/yyyy") : ''}</span>
                </Stack>
            )
        }),
        columnHelper.accessor('dateCreated', {
            header: () => <span>Date Created</span>,
            cell: ({ row }) => (
                <Stack>
                    <span>{row.original.dateCreated ? format(new Date(row.original.dateCreated), "dd/MM/yyyy") : ''}</span>
                </Stack>
            )
        }),
        columnHelper.accessor('dateModified', {
            header: () => <span>Date Modified</span>,
            cell: ({ row }) => (
                <Stack>
                    <span>{row.original.dateModified ? format(new Date(row.original.dateModified), "dd/MM/yyyy") : ''}</span>
                </Stack>
            )
        }),
        columnHelper.accessor('dateLeaving', {
            header: () => <span>Date Leaving</span>,
            cell: ({ row }) => (
                <Stack>
                    <span>{row.original.dateLeaving ? format(new Date(row.original.dateLeaving), "dd/MM/yyyy") : ''}</span>
                </Stack>
            )
        }),        
        columnHelper.accessor('upn', {
            header: () => <span>UPN</span>
        }),
    ];

    const handleSelectAllChange = () => {
        setSelectAll(!selectAll);
        const selectedData: string[] = selectedOptions.concat(tableJobs.map((option: any) => option.crbcID)).flat().filter((value) => typeof value === 'string');
        setSelectedOptions(selectAll ? [] : selectedData);
    };

    const handleCheckboxChange = (value: string) => {
        if (selectedOptions.includes(value)) {
            setSelectedOptions(selectedOptions.filter((item) => item !== value));
        } else {
            setSelectedOptions([...selectedOptions, value]);
        }
        const updatedSelectedOptions = selectedOptions.includes(value)
            ? selectedOptions.filter((item) => item !== value)
            : [...selectedOptions, value];
        setSelectAll(updatedSelectedOptions.length === tableJobs.length);
    };

    function onClose() {
        try {
            props.handleClose();
        } catch (e) {
            console.error(e);
        }
    }

    async function handleApply() {
        try {
            await ServiceManager.EstablishmentService.UpdatePeopleForceRefresh(props.establishmentId, selectedOptions);
            setSelectedOptions([]);
            ServiceManager.ToastService.showSuccess("Force Refresh successful. The next time the MIS extract runs, all data for chosen users will be updated/replaced accordingly.");
            props.handleClose();

        } catch (e) {
            ServiceManager.ToastService.showError("Error in force refresh");
            console.error(e);
        }
    }

    async function fetchData(options: {
        pageIndex: number;
        pageSize: number;
        searchValue: string;
        year: string;
        userType: number;
      }) {
        const transformData = await ServiceManager.EstablishmentService.GetTransformPeopleData(props.establishmentId, options.pageIndex + 1, 
            options.pageSize, options.searchValue, options.year, options.userType);
        setTableJobs(transformData);
        if(transformData?.length > 0){
            setSelectAll(transformData.every((data: any) => selectedOptions.includes(data.crbcID)));
        }
        return transformData;
    }
    return (
        <>
            <div id="listView-data" className="mt-3">
                <PagedFilterDataTable
                    tableKey={"force-refresh"}
                    columns={columns}
                    data={tableJobs}
                    usePagination={true}
                    useSearch={true}
                    pageRecords={10}
                    fnFetchData={fetchData}
                    title={<> <h5 className="ps-2 pt-2"><b>Force Refresh</b></h5> </>}
                    establishmentId={props.establishmentId} 
                    tableCallFrom={FilterTableCallFrom.ForceRefresh}
                />
            </div>
            <div className="mt-4">
                <Button
                    variant="popup-btn right-margin10 btn-outline-secondary"
                    onClick={() => onClose()}
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    variant="popup-btn btn btn-primary crbc-bg-color "
                    className={` ${selectedOptions.length === 0 ? "disabled" : ""}`} 
                    onClick={() => handleApply()} >
                    Force Refresh
                </Button>
            </div>
        </>
    );
}