import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MISButton from "../../../../components/button";
import Layout from "../../../layout";
import { faAdd, faArrowLeft, faEye, faEyeSlash, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import PagedFilterTable from "../../../../components/table";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import IExtractClient, { IClientConfiguration } from "../../../../models/ExtractClient";
import { ServiceManager } from "../../../../services/ServiceManager";
import { APIStatusCode, MisType, Permissions } from "../../../../app/enums";
import CustomModal from "../../../../components/modal";
import { Col, Container, Dropdown, Row, Spinner, Stack } from "react-bootstrap";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import IconElement from "../../../../components/icon";
import { SubmitHandler, useForm } from "react-hook-form";
import { usePermissionCheck } from "../../../../app/common/helper/Permissions";
import MISTooltip from "../../../../components/tooltip";
import { PERMISSION_REQUIRED } from "../../../../app/constants";

export default function ExtractClientSetup() {
    const [newExtractClient, setNewExtractClient] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [configurationId, setConfigurationId] = useState(0);
    const [tableJobs, setTableJobs] = useState([]);
    const canEditEstablishments = usePermissionCheck(Permissions.CanEditEstablishments);

    const handleCloseDeleteModal = () => setShowDeleteModal(false);

    const { establishmentId } = useParams();
    let navigate = useNavigate();

    const columnHelper = createColumnHelper<IExtractClient>();
    const columns = [
        columnHelper.accessor('clientID', {
            header: () => <span>Client ID</span>,
            size: 40
        }),
        columnHelper.accessor('misType', {
            header: () => <span>MIS</span>,
            cell: ({ row }) => (
                <span>{MisType[row.original.misType]?.toUpperCase()}</span>
            ),
            size: 25
        }),
        columnHelper.accessor('clientConfiguration.username', {
            header: () => <span>Username</span>,
            size: 23
        }),
        columnHelper.accessor('actions', {
            header: () => <span>Actions</span>,
            size: 7,
            cell: ({ row }) => (
                <div>
                    <FontAwesomeIcon
                        title="View"
                        className="cursor-pointer me-4"
                        icon={faEye}
                        size="lg"
                        onClick={() => { viewExtractClient(row.original.clientConfiguration.simsConfigurationID) }}
                    />
                    {
                        canEditEstablishments ?
                            <FontAwesomeIcon
                                title="Delete"
                                className="cursor-pointer"
                                icon={faTrashAlt}
                                size="lg"
                                onClick={() => { setShowDeleteModal(true); setConfigurationId(row.original.clientConfiguration.simsConfigurationID) }}
                            />
                            :
                            <MISTooltip message={PERMISSION_REQUIRED} placement="top">
                                <FontAwesomeIcon
                                    icon={faTrashAlt}
                                    size="lg"
                                    className="disabled"
                                />
                            </MISTooltip>
                    }

                </div>
            )
        })
    ];

    const addNewExtractClient = (isTrue: boolean) => {
        setNewExtractClient(isTrue);
    }

    const viewExtractClient = (configId: any) => {
        navigate(configId.toString())
    }

    async function fetchData(options: {
        pageIndex: number;
        pageSize: number;
        searchValue: string
    }) {
        const establishmentResponse = await ServiceManager.ConfigurationService.GetExtractClients(establishmentId);
        setTableJobs(establishmentResponse);
        return establishmentResponse;
    }

    const DeleteExtractClientModal = () => {
        const queryClient = useQueryClient();
        const mutation = useMutation({
            mutationFn: () => {
                return ServiceManager.ConfigurationService.DeleteSimsConfigurations(establishmentId, configurationId);
            },
            onError: (error, variables, context) => {

            },
            onSuccess: (data, variables, context) => {
                if (data === null) {
                    ServiceManager.ToastService.showError("Unable to delete extract client.");
                }
                else {
                    if (data.status === APIStatusCode.NoContent) {
                        ServiceManager.ToastService.showSuccess("Extract Client deleted successfully.");
                        queryClient.invalidateQueries({ queryKey: ["extract-clients-view"] });
                        handleCloseDeleteModal();
                    }
                    else {
                        let errorMessage = (data.response && data.response.data) ? data.response.data : data.message;
                        ServiceManager.ToastService.showError(errorMessage.toString());
                    }
                }
            }
        });
        return (
            <Stack>
                <p className="heading text-center mt-2">Delete Extract Client</p>
                <p className="text-center">Are you sure you wish to delete this Extract Client? This will also delete associated User Type, Transform and Override data. This action cannot be undone.</p>
                {
                    !mutation.isLoading
                        ? (<Stack direction="horizontal" className="mt-3">
                            <MISButton text="Cancel" className="popup-btn right-margin10 btn btn-outline-secondary w-50" FnOnClick={handleCloseDeleteModal} />
                            <MISButton text="Delete" className="popup-btn right-margin10 btn btn-danger w-50" FnOnClick={() => { mutation.mutate(); }} />
                        </Stack>
                        ) : (
                            <div className="d-flex justify-content-center mt-3">
                                <Spinner
                                    animation="border"
                                    variant="info"
                                    role="status"
                                >
                                </Spinner>
                            </div>
                        )
                }
            </Stack>
        )
    }

    const CreateExtractClientForm = () => {
        const queryClient = useQueryClient();
        const [selectedMisType, setSelectedMisType] = useState(null);
        const [showPassword, setShowPassword] = useState(false);

        const mutation = useMutation({
            mutationFn: (model: IClientConfiguration) => {
                return ServiceManager.ConfigurationService.CreateSimsConfigurations(establishmentId, model);
            },
            onError: (error, variables, context) => {

            },
            onSuccess: (data, variables, context) => {
                if (data === null) {
                    ServiceManager.ToastService.showError("Unable to create extract client.");
                }
                else {
                    if (data.status === APIStatusCode.Created) {
                        ServiceManager.ToastService.showSuccess("Extract Client created successfully.");
                        queryClient.invalidateQueries({ queryKey: ["extract-clients-view"] });
                        addNewExtractClient(false);
                    }
                    else {
                        const errors = data?.response?.data?.errors;
                        if (errors) {
                            Object.keys(errors).forEach(field => {
                                ServiceManager.ToastService.showError(`${field}: ${errors[field][0]}`);
                            });
                        }
                        else {
                            ServiceManager.ToastService.showError(data?.message);
                        }
                    }
                }
            }
        });
        const { register, handleSubmit, clearErrors, setValue, formState: { errors } } = useForm<IClientConfiguration>();
        const onSubmit: SubmitHandler<IClientConfiguration> = (data) => {
            mutation.mutate(data);
        };
        const firstCol = 2, secondCol = 4;

        const handleSelect = (eventKey: any) => {
            setValue('misType', eventKey);
            setSelectedMisType(eventKey);
            clearErrors('misType');
        };

        const togglePasswordVisibility = () => {
            setShowPassword((prev) => !prev);
        };

        return (
            <Stack>
                <form onSubmit={handleSubmit(onSubmit)} className="form">
                    <Container className="mt-3">
                        <Row className="pb-3">
                            <Col md={firstCol}>MIS Type</Col>
                            <Col md={secondCol}>
                                <Dropdown className="m-auto" onSelect={handleSelect as any}>
                                    <Dropdown.Toggle className="btn btn-secondary crbc-bg-color w-100 d-flex justify-content-between align-items-center"
                                        {...register('misType', { required: 'MIS type is required' })}>
                                        {selectedMisType ? MisType[selectedMisType]?.toUpperCase() : 'Select'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="timer-dropdown w-100">
                                        <Dropdown.Item eventKey="1">SIMS</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                {errors.misType && <div className="invalid-feedback" style={{ display: 'block', color: '#DC3245' }}>MIS Type is required</div>}
                            </Col>
                        </Row>
                        <Row className="pb-3">
                            <Col md={firstCol}>SIMS Path</Col>
                            <Col md={secondCol}>
                                <input {...register("simsPath", { required: 'SIMS Path is required', pattern: { value: /^[a-zA-Z]:\\(?:[^\\/:*?"<>|]+\\)*[^\\/:*?"<>|]*$/, message: 'Invalid Windows path', }, })}
                                    className={`form-control mt-1 ms-0 w-100 ${(errors.simsPath) ? 'is-invalid' : ''}`} />
                                {errors.simsPath && <div className="invalid-feedback">{errors.simsPath.message}</div>}
                            </Col>
                        </Row>
                        <Row className="pb-3">
                            <Col md={firstCol}>Username</Col>
                            <Col md={secondCol}>
                                <input {...register("username", { required: true })} className={`form-control mt-1 ms-0 w-100 ${(errors.username) ? 'is-invalid' : ''}`} maxLength={50} />
                                {errors.username && <div className="invalid-feedback">Username is required</div>}
                            </Col>
                        </Row>
                        <Row className="pb-3">
                            <Col md={firstCol}>Password</Col>
                            <Col md={secondCol}>
                                <div className="password-input-container">
                                    <input {...register("password", { required: 'Password is required' })} type={showPassword ? 'text' : 'password'}
                                        className={`form-control password-input mt-1 ms-0 w-100 ${(errors.password) ? 'is-invalid' : ''}`} maxLength={512} />
                                    <button type="button" onClick={togglePasswordVisibility} className="password-toggle" >
                                        {!errors.password && (showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />)}
                                    </button>
                                </div>
                                {errors.password && <div className="invalid-feedback" style={{ display: 'block', color: '#DC3245' }}>{errors.password.message}</div>}
                            </Col>
                        </Row>
                        {
                            !mutation.isLoading
                                ? (
                                    <Row className="mt-5">
                                        <Col md={firstCol}></Col>
                                        <Col md={secondCol}>
                                            <MISButton text="Cancel" className="btn popup-btn right-margin10 btn-outline-secondary" FnOnClick={() => addNewExtractClient(false)} />
                                            <MISButton text="Create" className="popup-btn btn btn-primary crbc-bg-color" type="submit" FnOnClick={handleSubmit(onSubmit)} />
                                        </Col>
                                    </Row>
                                )
                                : (
                                    <Row>
                                        <Col md={firstCol}></Col>
                                        <Col md={secondCol}>
                                            <div className="d-flex justify-content-center mt-3">
                                                <Spinner animation="border" variant="info" role="status" > </Spinner>
                                            </div>
                                        </Col>
                                    </Row>
                                )
                        }
                    </Container>
                </form>
            </Stack>
        );
    }

    return (
        <>
            {
                newExtractClient === false
                    ? (
                        <Layout title="Extract Client Setup"
                            button={
                                canEditEstablishments ?
                                    <MISButton text="Add New Client" className="btn btn-primary crbc-bg-color" FnOnClick={() => addNewExtractClient(true)} icon={<FontAwesomeIcon icon={faAdd} />}></MISButton>
                                    :
                                    <MISTooltip message={PERMISSION_REQUIRED} placement="bottom">
                                        <div className="disabled">
                                            <MISButton text="Add New Client" className="btn btn-secondary" FnOnClick={() => null} disabled icon={<FontAwesomeIcon icon={faAdd} />}></MISButton>
                                        </div>
                                    </MISTooltip>
                            }
                            backArrow={<FontAwesomeIcon icon={faArrowLeft} onClick={() => navigate(-1)} className="me-4 fs-5 cursor-pointer" />}
                        >
                            <div id="extractClientSetup">
                                <PagedFilterTable
                                    tableKey={"extract-clients-view"}
                                    columns={columns}
                                    data={tableJobs}
                                    usePagination={false}
                                    useSearch={false}
                                    pageRecords={10}
                                    fnFetchData={fetchData}
                                />
                            </div>
                        </Layout>
                    ) :
                    (
                        <Layout title="Add New Extract Client" backArrow={<FontAwesomeIcon icon={faArrowLeft} onClick={() => addNewExtractClient(false)} className="me-4 fs-5 cursor-pointer" />}>
                            <CreateExtractClientForm />
                        </Layout>
                    )
            }
            <CustomModal isShow={showDeleteModal} handleClose={handleCloseDeleteModal} header={<IconElement iconType={faTrashAlt} headerClass="error-icon ms-6" color="#D92D20" />} size="md">
                <DeleteExtractClientModal />
            </CustomModal>
        </>
    );
}