import {useState} from "react";
import {Col, Nav, Row, Tab} from "react-bootstrap";
import Layout from "../layout";
import UsersView from "./users";
import {usePermissionCheck} from "../../app/common/helper/Permissions";
import {Permissions} from "../../app/enums";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {PERMISSION_REQUIRED} from "../../app/constants";
import {faAdd} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import RolesView from "./roles";
import MISTooltip from "../../components/tooltip";
import MISButton from "../../components/button";

export default function Settings() {
    const navigate = useNavigate();
    const canViewUsers = usePermissionCheck(Permissions.CanViewUsers);
    const canManageRoles = usePermissionCheck(Permissions.CanManageRoles);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const tabKeyUrl = params.get('tabKey');
    let tabKey = '';

    if (tabKeyUrl) {
        tabKey = tabKeyUrl === "users" && canViewUsers ? tabKeyUrl : "";
        tabKey = tabKeyUrl === "roles" && canManageRoles ? tabKeyUrl : "";
    }

    const [key, setKey] = useState(tabKey ? tabKey : (canViewUsers ? "users" : canManageRoles ? "roles" : ""));

    function createNewRole() {
        navigate('/role');
    }

    const TooltipTopNavItem = ({title, eventKey, enable}: any) => {
        return (
            enable ?
                (
                    <Nav.Item>
                        <Nav.Link eventKey={eventKey} disabled={!enable}>{title}</Nav.Link>
                    </Nav.Item>
                )
                :
                (
                    <MISTooltip message={PERMISSION_REQUIRED} placement="top">
                        <Nav.Item className="disabled">
                            <Nav.Link eventKey={eventKey} disabled={!enable}><span
                                className="disabled">{title}</span></Nav.Link>
                        </Nav.Item>
                    </MISTooltip>
                )
        )
    }

    return (
        <Layout
            title="Settings"
            hideBorderBottom={true}
            button={
                key === "roles"
                    ?
                    canManageRoles
                        ?
                        <MISButton text="Create New Role"
                                   className="btn btn-primary crbc-bg-color"
                                   FnOnClick={createNewRole}
                                   icon={<FontAwesomeIcon icon={faAdd}/>}></MISButton>
                        :
                        <MISTooltip message={PERMISSION_REQUIRED} placement="bottom">
                            <div className="disabled">
                                <MISButton text="Create New Role"
                                           className="btn btn-secondary"
                                           FnOnClick={createNewRole}
                                           disabled
                                           icon={<FontAwesomeIcon icon={faAdd}/>}></MISButton>
                            </div>
                        </MISTooltip>
                    :
                    undefined
            }
        >
            <Tab.Container id="settingTabs" defaultActiveKey={key}>
                <Row className="mt-2">
                    <Col sm={12}>
                        <Nav variant="tabs" onSelect={(k: any) => setKey(k)}>
                            <TooltipTopNavItem title={'Users'} eventKey={'users'} enable={canViewUsers}/>
                            <TooltipTopNavItem title={'Roles'} eventKey={'roles'} enable={canManageRoles}/>
                        </Nav>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Tab.Content>
                            <Tab.Pane eventKey="users">{key === "users" && <UsersView/>}</Tab.Pane>
                            <Tab.Pane eventKey="roles">{key === "roles" && <RolesView/>}</Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </Layout>
    )
}