import { createColumnHelper } from "@tanstack/react-table";
import { Stack } from "react-bootstrap";
import format from "date-fns/format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { ServiceManager } from "../../../services/ServiceManager";
import IExtractData from "../../../models/ExtractData";
import { useParams } from "react-router-dom";
import { toGuid } from "../../../app/common/helper/Guid";
import PagedFilterDataTable from "../../../components/table/filter-table";
import { FilterTableCallFrom } from "../../../app/enums";
import CustomModal from "../../../components/modal";
import { useState } from "react";

export default function RawData(props: any) {

    const { establishmentId } = useParams();
    const [showParentalConsentModal, setShowParentalConsentModal] = useState(false);
    const [parentalConsentModalValue, setParentalConsentModalValue] = useState("");

    const columnHelper = createColumnHelper<IExtractData>();
    const columns = [
        columnHelper.accessor('clientID', {
            header: () => <span>Client ID</span>
        }),
        columnHelper.accessor('misID', {
            header: () => <span>Mis ID</span>
        }),
        columnHelper.accessor('misID2', {
            header: () => <span>Mis ID 2</span>
        }),
        columnHelper.accessor('userTypeID', {
            header: () => <span>User Type ID</span>
        }),
        columnHelper.accessor('misTypeID', {
            header: () => <span>Mis Type ID</span>
        }),
        columnHelper.accessor('title', {
            header: () => <span>Title</span>
        }),
        columnHelper.accessor('surname', {
            header: () => <span>Surname</span>
        }),
        columnHelper.accessor('firstName', {
            header: () => <span>First Name</span>
        }),
        columnHelper.accessor('legalSurname', {
            header: () => <span>Legal Surname</span>
        }),
        columnHelper.accessor('legalFirstName', {
            header: () => <span>Legal First Name</span>
        }),
        columnHelper.accessor('year', {
            header: () => <span>Year</span>
        }),
        columnHelper.accessor('regClass', {
            header: () => <span>Reg Class</span>
        }),
        columnHelper.accessor('dateOfBirth', {
            header: () => <span>Date of Birth</span>,
            cell: ({ row }) => (
                <Stack>
                    <span>{row.original.dateOfBirth ? format(new Date(row.original.dateOfBirth), "dd/MM/yyyy") : ''}</span>
                </Stack>
            )
        }),
        columnHelper.accessor('gender', {
            header: () => <span>Gender</span>,
            cell: ({ row }) => (
                <Stack>
                    <span>{row.original.gender === 'M' ? 'Male' : row.original.gender === 'F' ? 'Female' : 'Not Specified'}</span>
                </Stack>
            )
        }),
        columnHelper.accessor('isFsm', {
            header: () => <span>Fsm</span>,
            cell: ({ row }) => (
                <Stack>
                    <span>{row.original.isFsm ? 'Yes' : 'No'}</span>
                </Stack>
            )
        }),
        columnHelper.accessor('dateFsmExpiry', {
            header: () => <span>Fsm Expiry</span>,
            cell: ({ row }) => (
                <Stack>
                    <span className="font-dark">{row.original.dateFsmExpiry ? format(new Date(row.original.dateFsmExpiry), "p") : ''}</span>
                    <span>{row.original.dateFsmExpiry ? format(new Date(row.original.dateFsmExpiry), "dd-MM-yyyy") : ''}</span>
                </Stack>
            )
        }),
        columnHelper.accessor('admissionNumber', {
            header: () => <span>Admission Number</span>
        }),
        columnHelper.accessor('staffCode', {
            header: () => <span>Staff Code</span>
        }),
        columnHelper.accessor('photoID', {
            header: () => <span>Photo ID</span>
        }),
        columnHelper.accessor('photoFileName', {
            header: () => <span>Photo File Name</span>
        }),
        columnHelper.accessor('photoHash', {
            header: () => <span>Photo Hash</span>
        }),
        columnHelper.accessor('dietaryPreferences', {
            header: () => <span>Dietary Preferences</span>
        }),
        columnHelper.accessor('dietaryNeeds', {
            header: () => <span>Dietary Needs</span>
        }),
        columnHelper.accessor('parentalConsent', {
            header: () => <span>Parental Consent</span>,
            cell: ({ row }) => (
                <span style={{ width: "400px", padding: "0" }} onClick={() => setParentalConsentModalData(row.original.parentalConsent)} className={row.original.parentalConsent ? 'parent-consent-td' : ''}>
                    {row.original.parentalConsent ?? ''}
                </span>
            )
        }),

        columnHelper.accessor('isActive', {
            header: () => <span>Status</span>,
            cell: ({ row }) => (
                <div className={`p-1 ps-2 pe-2 ${row.original.isActive ? 'queue-completed' : 'queue-notexecuted'}`}>
                    <FontAwesomeIcon icon={faCircle} size="2xs" className="icon" />
                    <span className="ms-2">{row.original.isActive ? 'Active' : 'Disabled'}</span>
                </div>
            )
        }),
        columnHelper.accessor('isApplicant', {
            header: () => <span>Is Applicant</span>,
            cell: ({ row }) => (
                <Stack>
                    <span>{row.original.isApplicant ? 'Yes' : 'No'}</span>
                </Stack>
            )
        }),
        columnHelper.accessor('dateLastActive', {
            header: () => <span>Date Last Active</span>,
            cell: ({ row }) => (
                <Stack>
                    <span>{row.original.dateLastActive ? format(new Date(row.original.dateLastActive), "dd/MM/yyyy") : ''}</span>
                </Stack>
            )
        }),
        columnHelper.accessor('dateCreated', {
            header: () => <span>Date Created</span>,
            cell: ({ row }) => (
                <Stack>
                    <span>{row.original.dateCreated ? format(new Date(row.original.dateCreated), "dd/MM/yyyy") : ''}</span>
                </Stack>
            )
        }),
        columnHelper.accessor('dateModified', {
            header: () => <span>Date Modified</span>,
            cell: ({ row }) => (
                <Stack>
                    <span>{row.original.dateModified ? format(new Date(row.original.dateModified), "dd/MM/yyyy") : ''}</span>
                </Stack>
            )
        }),
        columnHelper.accessor('upn', {
            header: () => <span>UPN</span>
        }),
    ];

    function setParentalConsentModalData(modalVal: any) {
        setParentalConsentModalValue(modalVal);
        setShowParentalConsentModal(true);
    }

    async function fetchData(options: {
        pageIndex: number;
        pageSize: number;
        searchValue: string;
        clientId: string
    }) {
        const transformData = await ServiceManager.EstablishmentService.GetExtractPeopleData(toGuid(establishmentId ?? ""),
            options.searchValue, options.clientId, options.pageIndex + 1, options.pageSize);
        return transformData;
    }

    return (
        <>
            <div id="listView-data" >
                <PagedFilterDataTable
                    tableKey={`extract-data-${establishmentId}`}
                    columns={columns}
                    data={[]}
                    usePagination={true}
                    pageRecords={25}
                    fnFetchData={fetchData}
                    useSearch={true}
                    tableCallFrom={FilterTableCallFrom.ExtractedData}
                    establishmentId={establishmentId}
                />
            </div>
            <CustomModal isShow={showParentalConsentModal} handleClose={() => setShowParentalConsentModal(false)} header={<p className="heading">Parental Consent</p>} size="md">
                {parentalConsentModalValue}
            </CustomModal>
        </>
    )
}