import { Tab, Tabs } from "react-bootstrap";
import Layout from "../layout";
import ClientExtracts from "./client-extracts";
import ExtractServiceView from "./extract-service";
import TransformServiceView from "./transform-service";
import {useEffect, useState} from "react";
import {MisServiceType, Permissions} from "../../app/enums";
import {usePermissionCheck} from "../../app/common/helper/Permissions";
import {useNavigate} from "react-router-dom";

export default function Healthboard() {

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const type = params.get('type');

  const [activeTab, setActiveTab] = useState(type ?? MisServiceType.Task);
  let canViewHealthboard: boolean = usePermissionCheck(Permissions.CanViewHealthboard);
  const navigate = useNavigate();

  const handleTabSelect = (selectedTab: any) => {
    setActiveTab(selectedTab);
  };

  useEffect(() => {
    if (!canViewHealthboard) {
      navigate("/establishment")
    }
  }, [canViewHealthboard,navigate]);

  return (
    <Layout title="Healthboard" hideBorderBottom={true}>
      <Tabs activeKey={activeTab} onSelect={handleTabSelect}>
        <Tab eventKey={MisServiceType.Task} title="Client Extracts">
          <div className="mt-3">
            {activeTab === MisServiceType.Task && <ClientExtracts />}
          </div>
        </Tab>
        <Tab eventKey={MisServiceType.Extract} title="Extract Service">
          <div className="mt-3">
            {activeTab === MisServiceType.Extract && <ExtractServiceView />}
          </div>
        </Tab>
        <Tab eventKey={MisServiceType.Transform} title="Transform Service">
          <div className="mt-3">
            {activeTab === MisServiceType.Transform && <TransformServiceView />}
          </div>
        </Tab>
      </Tabs>
    </Layout>
  );
};