import React, {useState} from "react";
import {createColumnHelper} from "@tanstack/react-table";
import "bootstrap/dist/css/bootstrap.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle} from "@fortawesome/free-solid-svg-icons";
import {ServiceManager} from "../../../services/ServiceManager";
import PagedFilterTable from "../../../components/table";
import IUser from "../../../models/User";
import MISButton from "../../../components/button";

interface IPopupUsersList {
    handleClose: any;
    handleUserSelection: any;
    existingUsersData: Array<any> | null;
    loadingModalState: boolean
}

export default function PopupUsersList(props: IPopupUsersList) {
    const [rowSelection, setRowSelection] = useState([]);
    const [apiData, setapiData] = useState(Array<IUser>);
    const [tableJobs] = useState([]);
    const [checked, setChecked] = useState(false);
    const columnHelper = createColumnHelper<IUser>();
    const columns = [
        columnHelper.display({
            header: () => (
                <span>
          <input
              type="checkbox"
              checked={checked}
              onChange={(event) => {
                  onSelectAll(event);
              }}
          ></input>
        </span>
            ),
            id: "select_userID",
            cell: (rowdata) => (
                <input
                    type="checkbox"
                    checked={rowSelection.find((x) => x === rowdata.row.original.loginID)}
                    disabled={props.existingUsersData?.find(
                        (x) => x.loginID === rowdata.row.original.loginID
                    )}
                    onChange={(e) => {
                        onRowClick(rowdata.row);
                    }}
                ></input>
            ),
        }),
        columnHelper.accessor("displayName", {
            header: () => <span>Name</span>,
            size: 40,
            cell: ({row}) => (
                <span
                    className={`${
                        props.existingUsersData?.find(
                            (x) => x.loginID === row.original.loginID
                        )
                            ? "disabled-text"
                            : ""
                    }`}
                >
          {row.original.displayName}
        </span>
            ),
        }),
        columnHelper.accessor("isActive", {
            header: () => <span>Status</span>,
            size: 10,
            cell: ({row}) => (
                <span
                    className={`p-1 ps-2 pe-2 ${
                        row.original.isActive ? "active" : "disabled"
                    }`}
                >
          <FontAwesomeIcon icon={faCircle} size="2xs" className="icon"/>{" "}
                    <span className="ms-1">
            {row.original.isActive ? "Active" : "Disabled"}
          </span>
        </span>
            ),
        }),
        columnHelper.accessor("emailAddress", {
            header: () => <span>Email Address</span>,
            size: 50,
            cell: ({row}) => (
                <span
                    className={`${
                        props.existingUsersData?.find(
                            (x) => x.loginID === row.original.loginID
                        )
                            ? "disabled-text"
                            : ""
                    }`}
                >
          {row.original.emailAddress}
        </span>
            ),
        }),
    ];

    function onSelectAll(event: any) {
        setChecked(!checked);
        var allUserIds: Array<string> = apiData?.map((x) => x.loginID) || [];
        setRowSelection((prevSelected: any) => {
            if (!checked) {
                var selection = allUserIds.filter((item) => !props.existingUsersData?.map(x => x.loginID).includes(item));

                return selection;
            } else {
                prevSelected.length = 0;
                return prevSelected;
            }
        });
    }

    function onRowClick(row: any) {
        const selectedValue: string = row.original.loginID;

        setRowSelection((prevSelected: any) => {
            if (prevSelected.includes(selectedValue)) {
                return prevSelected.filter((id: any) => id !== selectedValue);
            } else {
                return [...prevSelected, selectedValue];
            }
        });
    }

    function removeExistingSelection() {

        setChecked(false);
        setRowSelection([]);
    }

    async function fetchData(options: {
        pageIndex: number;
        pageSize: number;
        searchValue: string;
    }) {
        let IncludeActive = true;
        let IncludeInactive = false;
        let IncludeRoles = false;
        let Username = "";
        let DisplayName = options.searchValue ? options.searchValue : "";
        let EmailAddress = options.searchValue ? options.searchValue : "";

        removeExistingSelection();

        const usersResponse = await ServiceManager.UserService.GetAll(
            options.pageIndex + 1,
            options.pageSize,
            IncludeActive,
            IncludeInactive,
            IncludeRoles,
            Username,
            DisplayName,
            EmailAddress
        );
        setapiData(usersResponse?.users);
        return usersResponse ? usersResponse?.users : null;
    }

    function onClose() {
        try {
            props.handleClose();
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <React.Fragment>
            <div className="role-users popup-users-list">
                <PagedFilterTable
                    tableKey={"role-users-list"}
                    columns={columns}
                    data={tableJobs}
                    usePagination={true}
                    useSearch={true}
                    pageRecords={8}
                    fnFetchData={fetchData}
                    title={
                        <>
                            <h5 className="ps-3 pt-3">
                                <b>Users</b>
                            </h5>
                        </>
                    }
                />
            </div>
            <div className="mt-4">
                <MISButton
                    className="btn right-margin10 btn-outline-secondary"
                    FnOnClick={() => onClose()}
                    text="Cancel"
                ></MISButton>
                <MISButton
                    type="button"
                    variant=""
                    className={`btn btn-primary crbc-bg-color  ${
                        props.loadingModalState ||
                        rowSelection === null ||
                        rowSelection?.length === 0
                            ? "disabled"
                            : ""
                    }`}
                    FnOnClick={function () {
                        props.handleUserSelection(rowSelection);
                    }}
                    text="Add to Role"
                ></MISButton>
            </div>
        </React.Fragment>
    );
}
