import React from 'react';
import { faBuildingColumns, faEllipsisV, faUserLarge, faSignOut, faUser, faSignal, faGear, faHeartPulse } from '@fortawesome/free-solid-svg-icons';
import SideBarLink from './sidebar-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import jwt_decode from "jwt-decode";
import { useAuth } from "react-oidc-context";
import Button from '../button';
import { usePermissionCheck } from '../../app/common/helper/Permissions';
import { Permissions } from '../../app/enums';
import { PERMISSION_REQUIRED } from '../../app/constants';
import MISTooltip from '../tooltip';

interface IAuthenticatedSideBarItemsProps {
    isAuthenticated: boolean;
    logout: any;
}

export default function AuthenticatedSideBarItems(props: IAuthenticatedSideBarItemsProps) {
    const auth = useAuth();
    var jwt : any;
    const CanViewEstablishments = usePermissionCheck(Permissions.CanViewEstablishments);
    const CanViewDashboard = usePermissionCheck(Permissions.CanViewDashboard);
    const CanViewHealthboard = usePermissionCheck(Permissions.CanViewHealthboard);

    if(auth.isAuthenticated) {
        jwt = jwt_decode(auth.user?.access_token ?? '', { header: false });
    }

    function logout() {
        auth.signoutRedirect();
    }

    return (
        props.isAuthenticated ? (
            <React.Fragment>
                <ul className="nav nav-pills flex-column mb-auto">
                    {CanViewDashboard
                        ?
                        <SideBarLink href="/" text="Dashboard" icon={faSignal} liClass='nav-item' navClass='nav-link'/>
                        :
                        <MISTooltip message={PERMISSION_REQUIRED} placement="bottom">
                            <div>
                                <SideBarLink href="/" text="Dashboard" icon={faSignal} liClass='nav-item li-disabled'
                                             navClass='nav-link'/>
                            </div>
                        </MISTooltip>
                    }
                    {CanViewHealthboard
                        ?
                        <SideBarLink href="/healthboard" text="Healthboard" icon={faHeartPulse} liClass='nav-item'
                                     navClass='nav-link'/>
                        :
                        <MISTooltip message={PERMISSION_REQUIRED} placement="bottom">
                            <div>
                                <SideBarLink href="/healthboard" text="Healthboard" icon={faHeartPulse}
                                             liClass='nav-item li-disabled' navClass='nav-link'/>
                            </div>
                        </MISTooltip>
                    }

                    {CanViewEstablishments
                        ?
                        <SideBarLink href="/establishment" text="Establishment" icon={faBuildingColumns}
                                     liClass='nav-item' navClass='nav-link'/>
                        :
                        <MISTooltip message={PERMISSION_REQUIRED} placement="bottom">
                            <div>
                                <SideBarLink href="/establishment" text="Establishment" icon={faBuildingColumns}
                                             liClass='nav-item li-disabled' navClass='nav-link'/>
                            </div>
                        </MISTooltip>
                    }
                </ul>
                <ul className="nav nav-pills flex-column mb-0">
                    <SideBarLink href="/setting" text="Settings" icon={faGear} liClass='nav-item' navClass='nav-link' />
                </ul>
                <hr />
                <div className='dropdown dropup'>
                    <a href="#!" className="d-flex align-items-center link-dark text-decoration-none" id="dropdownUser2"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        <div className="d-flex justify-content-start align-items-center mb-1">
                            <FontAwesomeIcon className="avatar me-2" icon={faUser} />

                            <div className="user-detail">
                                <h4 className="mb-0 mt-0" title={jwt.name}>{jwt.name}</h4>
                                <p className="mb-0" title={jwt.emailaddress}>{jwt.emailaddress}</p>
                            </div>
                            <div className="icon">
                                <FontAwesomeIcon className="link-dark bi me-2" icon={faEllipsisV} />
                            </div>
                        </div>
                    </a>
                    <ul className="dropdown-menu text-small shadow w-100" aria-labelledby="dropdownUser2">
                        <SideBarLink href="/" text="View Profile" icon={faUserLarge} navClass='dropdown-item'/>
                        <Button text="Log Out" className="dropdown-item"
                         FnOnClick={() => logout()} icon={<FontAwesomeIcon icon={faSignOut} />}></Button>
                    </ul>
                </div>
            </React.Fragment>
        ) : (null)
    );
}