import { createColumnHelper } from "@tanstack/react-table";
import IEstablishment from "../../models/Establishment";
import { useState } from "react";
import { ServiceManager } from "../../services/ServiceManager";
import Layout from "../layout";
import { ExtractStatus, ExtractStatusStyles, FilterTableCallFrom, MisType, Permissions } from "../../app/enums";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import CreateEstablishment from "./create";
import MISButton from "../../components/button";
import { usePermissionCheck } from "../../app/common/helper/Permissions";
import MISTooltip from "../../components/tooltip";
import { PERMISSION_REQUIRED } from "../../app/constants";
import PagedFilterDataTable from "../../components/table/filter-table";

export default function Establishment() {
  const [isCreateEstablishment, setCreateEstablishment] = useState(false);
  const [tableJobs, setTableJobs] = useState([]);
  const canEditEstablishments = usePermissionCheck(Permissions.CanEditEstablishments);

  const columnHelper = createColumnHelper<IEstablishment>();
  const columns = [
    columnHelper.accessor('name', {
      header: () => <span>Name</span>,
      size: 20,
      cell: ({ row }) => (
        <NavLink to={`/establishment/${row.original.establishmentID}`}>
          {row.original.name}
        </NavLink>
      ),
    }),
    columnHelper.accessor('parent', {
      header: () => <span>Parent</span>,
      size: 20,
      cell: ({ row }) => (
        <NavLink to={`/establishment/${row.original.parent?.establishmentID}`}>
          {row.original.parent?.name}
        </NavLink>
      ),
    }),
    columnHelper.accessor('misType', {
      header: () => <span>MIS</span>,
      size: 20,
      cell: ({ row }) => (
        <span>{MisType[row.original.misType]?.toUpperCase()}</span>
      ),
    }),
    columnHelper.accessor('extractStatus', {
      header: () => <span>Extract Status</span>,
      size: 20,
      cell: ({ row }) => (
        <div className={`p-1 ps-2 pe-2 status-text status-box ${ExtractStatusStyles[row.original.extractStatus]}`}>
          <span>{ExtractStatus[row.original.extractStatus]}</span>
        </div>

      ),
    }),
    columnHelper.accessor('salesforceExternalID', {
      header: () => <span>External ID</span>,
      size: 25
    })
  ];
  async function fetchData(options: {
    pageIndex: number;
    pageSize: number;
    searchValue: string;
    extractStatus: number
  }) {
    const establishmentResponse = await ServiceManager.EstablishmentService.GetAllWithChildern(false, options.pageIndex + 1, options.pageSize, options.searchValue, options.extractStatus);

    setTableJobs(establishmentResponse);
    return establishmentResponse;
  }

  async function navigate(isTrue: boolean) {
    setCreateEstablishment(isTrue);
  }

  return (
    isCreateEstablishment === false ? (
      <Layout title="Establishments"
        button={
          canEditEstablishments
            ?
            <MISButton text="Create New Establishment"
              className="btn btn-primary crbc-bg-color"
              FnOnClick={() => navigate(true)}
              icon={<FontAwesomeIcon icon={faAdd} />}></MISButton>
            :
            <MISTooltip message={PERMISSION_REQUIRED} placement="bottom">
              <div className="disabled">
                <MISButton text="Create New Establishment"
                  className="btn btn-secondary"
                  FnOnClick={() => null}
                  disabled
                  icon={<FontAwesomeIcon icon={faAdd} />}></MISButton>
              </div>
            </MISTooltip>
        }
      >
        <div id="establishmentsTable">
          <PagedFilterDataTable
            tableKey={"establishments"}
            columns={columns}
            data={tableJobs}
            usePagination={true}
            useSearch={true}
            pageRecords={15}
            fnFetchData={fetchData}
            tableCallFrom={FilterTableCallFrom.Establishment}
          />
        </div>
      </Layout>
    )
      :
      (
        <Layout title="Create New Establishment"
          backArrow={
            <FontAwesomeIcon icon={faArrowLeft} onClick={() => navigate(false)} className="me-4 fs-5 cursor-pointer" />
          }>
          <CreateEstablishment createEstablishment={setCreateEstablishment} />
        </Layout>
      )
  );
}