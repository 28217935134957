import { Modal } from "react-bootstrap"

interface ICustomModalProps {
  isShow: boolean,
  handleClose: any,
  size: any,
  header?: any,
  children: any,
  customClass?: any,
  isShowHeader?: boolean,
  fullscreen?: any | false,
  headerBgColor?: string | null
}

export default function CustomModal(props: ICustomModalProps) {
  return (
    <Modal backdrop="static" show={props.isShow} onHide={props.handleClose} keyboard={false} size={props.size} centered contentClassName={props.customClass} fullscreen={props.fullscreen}>
      {
        props.isShowHeader !== false ?
        <Modal.Header className="border-bottom-0 pb-0" closeButton style={{backgroundColor: props.headerBgColor ?? "white"}}>
          <Modal.Title className="display-content">{props.header}</Modal.Title>
        </Modal.Header>
        : ""
      }
      <Modal.Body className="mt-1">{props.children}</Modal.Body>
      <Modal.Footer className="border-top-0"></Modal.Footer>
    </Modal>
  )
}
