import React, { useState } from "react";
import { Button, Stack } from "react-bootstrap";
import SearchBar from "../../components/search";
import { ServiceManager } from "../../services/ServiceManager";
import { APIStatusCode } from "../../app/enums";
import IAccount from "../../models/Account";
import CustomModal from "../../components/modal";
import IconElement from "../../components/icon";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import SimpleTable from "../../components/simpletable";
import { SOMETHING_WENT_WRONG } from "../../app/constants";
import MISButton from "../../components/button";
import { useNavigate } from "react-router-dom";
import MISSpinner from "../../components/spinner";

interface IEstablishmentCreate {
    createEstablishment?: any
}

export default function CreateEstablishment(props: IEstablishmentCreate) {
    const handleCloseSaveModal = () => setShowSaveModal(false);
    const [isSpinner, setSpinner] = useState<boolean>();
    const [searchValue, setSearchValue] = useState<string>();
    const [establishments, setEstablishments] = useState<IAccount[]>();
    const [showSaveModal, setShowSaveModal] = useState(false);
    let navigate = useNavigate();

    const columnHelper = createColumnHelper<IAccount>();
    const columns = [
        columnHelper.accessor('externalSalesforceId', {
            header: () => <span>External ID</span>,
            size: 25,
            cell: ({ row }) => (
                <a href={row.original.salesforceUrl} className="underline" target="_blank" rel="noreferrer">
                    {row.original.externalSalesforceId}
                </a>
            )
        }),
        columnHelper.accessor('name', {
            header: () => <span>Name</span>,
            size: 25
        }),
        columnHelper.accessor('city', {
            header: () => <span>City</span>,
            size: 25
        }),
        columnHelper.accessor('postCode', {
            header: () => <span>PostCode</span>,
            size: 25
        })
    ];

    async function getEstablishmentInternal() {
        const establishmentData = await ServiceManager.EstablishmentService.GetByAccountId(searchValue, false);
        if ((establishmentData.response && establishmentData.response?.status === APIStatusCode.NotFound) 
            || (establishmentData && !establishmentData.enabled)) {
            return true;
        }
        else if(establishmentData && establishmentData.establishmentID && establishmentData.enabled) {
            ServiceManager.ToastService.showError("An establishment already exists for the account specified");
            return false;
        }
        else {
            let error = ""
            if (establishmentData.response) {
                error = establishmentData.response.data;
            }
            else {
                error = establishmentData.message;
            }
            ServiceManager.ToastService.showError(error);
            return false;
        }
    }

    async function getEstablishmentSalesforce() {
        const establishmentData = await ServiceManager.SalesforceService.GetByExternalId(searchValue);
        if (establishmentData && establishmentData.externalSalesforceId) {
            let establishments: IAccount[] = [];
            establishments.push(establishmentData);
            setEstablishments(establishments);
        }
        else {
            let error = ""
            if (establishmentData.response) {
                error = establishmentData.response.data;
            }
            else {
                error = establishmentData.message;
            }
            ServiceManager.ToastService.showError(error);
            setEstablishments(undefined);
            return false;
        }
    }

    async function fetchEstablishmentData() {
        setSpinner(true);
        if (searchValue) {
            const internalEstablishmentRsp = await getEstablishmentInternal();
            if (internalEstablishmentRsp) {
                await getEstablishmentSalesforce();
            }
            setSpinner(false);
        }
        else {
            ServiceManager.ToastService.showError("Enter Salesforce External ID");
            setSpinner(false);
        }
    }

    function clickCancel() {
        setEstablishments(undefined);
    }

    async function saveEstablishments() {
        setSpinner(true);
        let response = await ServiceManager.EstablishmentService.Synchronise(establishments?.at(0)?.externalSalesforceId);
        if (response && (response.status === APIStatusCode.Ok || response.status === APIStatusCode.Created)) {
            ServiceManager.ToastService.showSuccess("Successfuly added new establishment");
            props.createEstablishment(false);
            setShowSaveModal(false);
            navigate(`/establishment/${response?.data?.establishmentID.toString()}`)
        }
        else {
            if (response.response) {
                if (response.status === APIStatusCode.NotFound) {
                    let errorMessage = response.data.errors.errors;
                    ServiceManager.ToastService.showError(response.response.data.errors ? errorMessage.toString() : "No establishment exists with the account ID specified in salesforce");
                    clickCancel();
                }
                else if (response.response.data) {
                    if (response.response.data?.error) {
                        let errors = Object.values(response.response.data.errors)
                        ServiceManager.ToastService.showError(`${errors.join('\r\n')}' '`);
                    }
                    else if (response.response.data?.detail) {
                        ServiceManager.ToastService.showError(response.response?.data?.detail);
                    }
                    else if (response.response.data?.title) {
                        ServiceManager.ToastService.showError(response.response.data?.title);
                    }
                    else {
                        ServiceManager.ToastService.showError(SOMETHING_WENT_WRONG);
                    }
                }
            }
            else {
                ServiceManager.ToastService.showError(response.message);
            }
        }
        setSpinner(false);
    }

    const SaveConfirmationForm = () => {
        return (
            <Stack>
                <h5 className="text-center">Create account</h5>
                <p className="text-center">Do you want to create this account and all child account records.</p>
                <Stack direction="horizontal" className="mt-3">
                    {
                        (isSpinner === true)
                            ?
                            <Stack>
                                <MISSpinner/>
                            </Stack>
                            :
                            <React.Fragment>
                                <Button variant="popup-btn right-margin10 btn-outline-secondary w-50" onClick={handleCloseSaveModal}>
                                    Cancel
                                </Button>
                                <Button variant="popup-btn btn-primary crbc-bg-color w-50" onClick={saveEstablishments}>
                                    Confirm
                                </Button>
                            </React.Fragment>
                    }
                </Stack>
            </Stack>
        )
    }

    return (
        <React.Fragment>
            <label className="mt-3 label-text">Enter Salesforce External ID </label>
            <Stack direction="horizontal">
                <SearchBar placeholder="Acc-xxxx" className="w-25" onChange={(searchText: string) => setSearchValue(searchText)} />
                <MISButton disabled={!searchValue} text="Search" className="popup-btn btn btn-primary crbc-bg-color" FnOnClick={() => fetchEstablishmentData()} />
            </Stack>
            {
                (isSpinner === true)
                    ?
                    <Stack className="mt-5">
                        <MISSpinner/>
                    </Stack>
                    : null
            }

            {
                (establishments !== undefined)
                    ?
                    <div id="createEstablishment" className="mt-3 ms-2">
                        <SimpleTable
                            tableKey={"establishments"}
                            columns={columns}
                            data={establishments}
                        />
                        <Stack direction="horizontal" className="mt-5">
                            <MISButton disabled={isSpinner} text="Cancel" className="popup-btn btn btn-outline-secondary me-2" FnOnClick={() => clickCancel()} />
                            <MISButton disabled={isSpinner} text="Create" className="popup-btn btn btn-primary crbc-bg-color" FnOnClick={() => setShowSaveModal(true)} />
                        </Stack>
                    </div>
                    :
                    null
            }

            <CustomModal isShow={showSaveModal} handleClose={handleCloseSaveModal} header={<IconElement iconType={faInfoCircle} headerClass="blue-icon ms-6" color="#0060DF" />} size="md">
                <SaveConfirmationForm />
            </CustomModal>
        </React.Fragment>
    );

}