import React from "react";
import SimpleTable from "../../components/simpletable";
import IEstablishment from "../../models/Establishment";
import { createColumnHelper } from "@tanstack/react-table";
import { NavLink } from "react-router-dom";

export default function ChildEstablishments(props: any) {
    const columnHelper = createColumnHelper<IEstablishment>();
    const columns = [
        columnHelper.accessor('name', {
            header: () => <span>Name</span>,
            size: 25,
            cell: ({ row }) => (
              <NavLink to={`/establishment/${row.original.establishmentID}`}>
                {row.original.name}
              </NavLink>
            ),
          }),
        columnHelper.accessor('salesforceExternalID', {
            header: () => <span>External ID</span>,
            size: 25
          })
    ];

    return (
      <React.Fragment>
        {props.childEstablishments !== null && props.childEstablishments.length > 0 &&
          <div className="mt-3 child-establishments">
            <SimpleTable
              tableKey={"establishments"}
              columns={columns}
              data={props.childEstablishments}
            />
          </div>
        }
        {(props.childEstablishments === null || props.childEstablishments.length === 0) &&
          <div className="text-muted mt-3"><p>No child establishments found</p></div>
        }
      </React.Fragment>
    );

}