import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { CloseButton, Dropdown, Stack } from "react-bootstrap";
import CustomModal from "../modal";
import { ExtractStatus } from "../../app/enums";

interface IFilterProps {
    callbackExtractStatus: any;
}
interface IFilterListProps {
    type: any;
    value: any;
}

export default function EstablishmentListViewFilter(props: IFilterProps) {
    const [FilterList, setFilterList] = useState<IFilterListProps[]>([]);
    const [showStatusModal, setShowStatusModal] = useState(false);
    const handleCloseStatusModal = () => setShowStatusModal(false);

    var counter = 0;
    useEffect(() => {
        //counter to call setTimes function only once
        if (counter === 0) {
            updateFiltersFromUrl();
            counter++;
        }
        // eslint-disable-next-line
    }, []);

    const updateFiltersFromUrl = () => {
        const params = new URLSearchParams(window.location.search);
        if (params.get('status')) {
            selectStatusFilter(params.get('status'));
        }
        else {
            selectDefaultStatus();
        }
    }

    //Show Modals Functions
    const handleShowStatusModal = () => {
        setShowStatusModal(true);
    };
    //End

    //Filter Selection Functions
    function selectDefaultStatus() {
        const params = new URLSearchParams(window.location.search);
        if (params.get('status')) {
            selectStatusFilter(params.get('status'));
        }
        setShowStatusModal(false);
    }
    function selectStatusFilter(statusId: any) {
        deleteFilter("status");
        if (!(FilterList.findIndex(obj => obj.type === 'status') > -1)) {

            let id = Number(statusId)
            let extStatus = ExtractStatus[id]
            let filter = { value: extStatus, type: 'status' } as IFilterListProps;
            setFilterList((prev) => [...prev, filter]);
        }
        props.callbackExtractStatus(statusId);
        setShowStatusModal(false);
    }
    function deleteFilter(type: string) {
        setFilterList((filterList) => filterList.filter((current) => current.type !== type));
        const index = FilterList.findIndex(obj => obj.type === type);
        if (index !== -1) {
            FilterList.splice(index, 1);
        }
    }
    //End

    const handleRemoveFilter = (event: any) => {
        event.preventDefault();
        const data = event.target.id ? event.target.id : "";
        let newFilterList = [...FilterList];
        var index = newFilterList.findIndex(obj => obj.type === event.target.id)
        if (index > -1) {
            newFilterList.splice(index, 1);
            setFilterList(newFilterList);
            if (data === "status") {
                deleteFilter("status");
                props.callbackExtractStatus(0);
            }
        }
    };

    const ModalHeader = ({ heading, title }: any) => {
        return (
            <Stack>
                <p className="heading">{heading}</p>
                <p className="text-muted">{title}.</p>
            </Stack>
        )
    }

    const StatusModal = () => {
        return (
            <Stack>
                <Dropdown onSelect={selectStatusFilter}>
                    <Dropdown.Toggle className="btn btn-secondary crbc-bg-color w-100 d-flex justify-content-between align-items-center">
                        Status
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="timer-dropdown">
                        <Dropdown.Item eventKey="1">Active</Dropdown.Item>
                        <Dropdown.Item eventKey="3">Inactive</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Stack>
        )
    }

    return (
        <React.Fragment>
            <Stack direction="horizontal" gap={3} className="w-100">
                <Dropdown>
                    <Dropdown.Toggle variant="primary" className="dropdown-filter crbc-bg-color" style={{ fontSize: 14 }}>
                        <FontAwesomeIcon icon={faFilter} /> Filter
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="timer-dropdown">
                        <Dropdown.Item onClick={handleShowStatusModal} className="mb-1 font-14">Status</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                {FilterList.map((element, index) => {
                    return (
                        <div key={index} id={element.type}>
                            <div className="btn selected-filter-color" id={element.type}>
                                {
                                    <div className="filter-wrap d-flex align-items-center font-14"> <div id={element.type} onClick={() => setShowStatusModal(true)}>
                                        {element.value}
                                    </div><CloseButton id={element.type} value={element.value} className="ms-2 p-0" onClick={handleRemoveFilter} /></div>
                                }
                            </div>
                        </div>
                    );
                })}
            </Stack>
            <CustomModal isShow={showStatusModal} handleClose={handleCloseStatusModal} header={<ModalHeader heading="Status" title="Please select a Status" />} size="sm">
                <StatusModal />
            </CustomModal>
        </React.Fragment>
    );
}