import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Placement } from "react-bootstrap/esm/types";

interface ITooltip {
    message: string,
    placement: Placement,
    children: any
}

export default function MISTooltip(props: ITooltip) {
    return (
        <OverlayTrigger
            overlay={<Tooltip>{props.message}</Tooltip>}
            placement={props.placement}
        >
            {props.children}
        </OverlayTrigger>
    );
};