import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Layout from "../../../layout";
import { faAdd, faArrowLeft, faPen, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row, Spinner, Stack } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ServiceManager } from "../../../../services/ServiceManager";
import { APIStatusCode, MisType, Permissions, TaskType } from "../../../../app/enums";
import { format } from "date-fns";
import MISButton from "../../../../components/button";
import SubTaskCard from "./sub-tasks/sub-task-card";
import ISubTask from "../../../../models/SubTask";
import CustomModal from "../../../../components/modal";
import IconElement from "../../../../components/icon";
import { useState } from "react";
import { usePermissionCheck } from "../../../../app/common/helper/Permissions";
import MISTooltip from "../../../../components/tooltip";
import { PERMISSION_REQUIRED } from "../../../../app/constants";

export default function TaskDetail(props: any) {

    let navigate = useNavigate();
    const { establishmentId, taskId } = useParams();
    const [subTaskModal, setSubTaskModal] = useState<any>(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const handleCloseDeleteModal = () => setShowDeleteModal(false);
    const canEditEstablishments = usePermissionCheck(Permissions.CanEditEstablishments);

    const { isLoading, data } = useQuery([`task-details-${establishmentId}`], () =>
        ServiceManager.EstablishmentService.GetTaskById(establishmentId, taskId), {
            refetchOnWindowFocus: false
        }
    );

    const { isLoading: isUserTypesLoading, data: userTypes } = useQuery([`estbalishment-user-types-${establishmentId}`], () =>
        ServiceManager.UserTypeService.GetByEstablishmentId(establishmentId), { refetchOnWindowFocus: false }
    );

    const DeleteSubTaskModal = () => {
        const queryClient = useQueryClient();
        const mutation = useMutation({
            mutationFn: (model: ISubTask) => {
                return ServiceManager.EstablishmentService.UpdateSubTasks(establishmentId, taskId, model);
            },
            onError: (error, variables, context) => {

            },
            onSuccess: (data, variables, context) => {
                if (data === null) {
                    ServiceManager.ToastService.showError("Unable to delete subtask.");
                }
                else {

                    if (data.status === APIStatusCode.Ok) {
                        ServiceManager.ToastService.showSuccess("Subtask deleted successfully.");
                        queryClient.invalidateQueries({ queryKey: [`task-details-${establishmentId}`] });
                        handleCloseDeleteModal();
                    }
                    else {
                        const errors = data?.response?.data?.errors;
                        if (errors) {
                            Object.keys(errors).forEach(field => {
                                ServiceManager.ToastService.showError(`${field}: ${errors[field][0]}`);
                            });
                        }
                        else{
                            ServiceManager.ToastService.showError(data?.message);
                        }
                    }
                }
            }
        });
        return (
            <Stack>
                <p className="heading text-center mt-2">Delete Subtask</p>
                <p className="text-center">Are you sure? This means that data will not be extracted for selected User Types within this Subtask. This action cannot be undone.</p>
                {
                    !mutation.isLoading
                    ? (<Stack direction="horizontal" className="mt-3">
                        <MISButton text="Cancel" className="popup-btn right-margin10 btn btn-outline-secondary w-50" FnOnClick={handleCloseDeleteModal} />
                        <MISButton text="Delete" className="popup-btn right-margin10 btn btn-danger w-50" FnOnClick={() => { mutation.mutate(subTaskModal); }} />
                    </Stack>
                    ) : (
                        <div className="d-flex justify-content-center mt-3">
                            <Spinner
                                animation="border"
                                variant="info"
                                role="status"
                            >
                            </Spinner>
                        </div>
                    )
                }
            </Stack>
        )
    }

    const handleDeleteSubTask = (index: number) => {
        let taskSettings = data?.taskSettings ? JSON.parse(data?.taskSettings) : {};
        taskSettings.Extracts.splice(index, 1);
        setSubTaskModal(taskSettings);
        setShowDeleteModal(true);
    }

    const HandleSubTaskCard = () => {
        let taskSettings = data != null ? JSON.parse(data?.taskSettings) : null;
        return (
            taskSettings?.Extracts?.map((data: any, index: any) => {
                var subTaskCount = index + 1;
                return (
                    <div className="col-xxl-3 col-6">
                        <SubTaskCard
                            key={subTaskCount}
                            subTaskIndex={index}
                            headingText={"Subtask-" + subTaskCount}
                            databaseServer={data.DatabaseServer}
                            databaseName={data?.DatabaseName}
                            photos={data?.Photos}
                            selectedUserTypes={data?.UserTypes}
                            allUserTypes={userTypes}
                            onDelete={() => handleDeleteSubTask(index)}
                        ></SubTaskCard>
                    </div>
                );
            })
        )
    };
    return (
        <Layout title={"Task Details"}
            backArrow={
                <FontAwesomeIcon icon={faArrowLeft} onClick={() => navigate(-1)} className="me-4 fs-5 cursor-pointer" />
            }
            button={<>
                {
                    canEditEstablishments ?
                        <>
                            <MISButton text="Extract Subtasks" className="btn btn-primary crbc-bg-color me-2" FnOnClick={() => navigate('subtasks')} icon={<FontAwesomeIcon icon={faAdd} />}></MISButton>
                            <MISButton text="Edit" className="btn btn-secondary" FnOnClick={() => navigate('edit')} icon={<FontAwesomeIcon icon={faPen} />}></MISButton>
                        </>
                        :
                        <MISTooltip message={PERMISSION_REQUIRED} placement="bottom">
                            <div className="disabled">
                                <MISButton text="Extract Subtasks" className="btn btn-secondary me-2" FnOnClick={() => null} disabled icon={<FontAwesomeIcon icon={faAdd} />}></MISButton>
                                <MISButton text="Edit" className="btn btn-secondary" FnOnClick={() => null} disabled icon={<FontAwesomeIcon icon={faPen} />}></MISButton>
                            </div>
                        </MISTooltip>
                }
            </>}
        >
            <div style={{ height: '86vh', overflowY: 'auto', overflowX: 'hidden' }}>
                <div className="stats-widget-box" style={{ height: 'auto' }}>
                    <Stack direction="horizontal">
                        <div className="stats-widget-box-header">
                            Task Details
                        </div>
                    </Stack>
                    <hr style={{ width: '100%', borderTop: '1px solid #CEDDF3', margin: 0, opacity: 0.5 }} />
                    <Stack>
                        {!isLoading && data != null &&
                            <Row>
                                <Col xl={6} sm={5} className="text-break">
                                    <Row className="pb-2">
                                        <Col md={4}><b>Schedule</b></Col>
                                        <Col md={8}>{data.schedule}</Col>
                                    </Row>
                                    <Row className="pb-2">
                                        <Col md={4}><b>MIS</b></Col>
                                        <Col md={8}>{MisType[data.misType]?.toUpperCase()}</Col>
                                    </Row>
                                    <Row className="pb-2">
                                        <Col md={4}><b>Priority</b></Col>
                                        <Col md={8}>{data.priority}</Col>
                                    </Row>
                                    <Row className="pb-2">
                                        <Col md={4}><b>Task Type</b></Col>
                                        <Col md={8}>{TaskType[data.taskType]?.toUpperCase()}</Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}><b>Enabled</b></Col>
                                        <Col md={8}><div className={data.enabled ? "enabled" : "not-enabled"}><span>{data.enabled ? 'Yes' : 'No'}</span></div></Col>
                                    </Row>
                                </Col>
                                <Col xl={6} sm={5} className="text-break">
                                    <Row className="pb-2">
                                        <Col md={4}><b>Next Executes</b></Col>
                                        <Col md={8}>{data.dateNextExecutes ? format(new Date(data.dateNextExecutes), "dd/MM/yyyy hh:mm a") : ''}</Col>
                                    </Row>
                                    <Row className="pb-2">
                                        <Col md={4}><b>Last Executed</b></Col>
                                        <Col md={8}>{data.dateLastExecuted ? format(new Date(data.dateLastExecuted), "dd/MM/yyyy hh:mm a") : ''}</Col>
                                    </Row>
                                    <Row className="pb-2">
                                        <Col md={4}><b>Started</b></Col>
                                        <Col md={8}>{data.dateStarted ? format(new Date(data.dateStarted), "dd/MM/yyyy hh:mm a") : ''}</Col>
                                    </Row>
                                    <Row className="pb-2">
                                        <Col md={4}><b>Created</b></Col>
                                        <Col md={8}>{data.dateCreated ? format(new Date(data.dateCreated), "dd/MM/yyyy hh:mm a") : ''}</Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}><b>Modified</b></Col>
                                        <Col md={8}>{data.dateModified ? format(new Date(data.dateModified), "dd/MM/yyyy hh:mm a") : ''}</Col>
                                    </Row>
                                </Col>
                            </Row>
                        }
                        {isLoading &&
                            <Row>
                                <Col md={5}><Skeleton height={20} count={4} /></Col>
                                <Col md={5}><Skeleton height={20} count={4} /></Col>
                                <Col md={2}></Col>
                            </Row>
                        }
                    </Stack>
                </div>
                <div className="row" style={{ height: 'auto' }}>
                    {!isUserTypesLoading && <HandleSubTaskCard />}
                </div>
            </div>

            <CustomModal isShow={showDeleteModal} handleClose={handleCloseDeleteModal} header={<IconElement iconType={faTrashAlt} headerClass="error-icon ms-6" color="#D92D20" />} size="md">
                <DeleteSubTaskModal />
            </CustomModal>
        </Layout >
    )
}