import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MISButton from "../../../../components/button";
import Layout from "../../../layout";
import { faRefresh, faAdd, faArrowLeft, faSave, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Container, Dropdown, Row, Stack } from "react-bootstrap";
import { useFieldArray, useForm, } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ServiceManager } from "../../../../services/ServiceManager";
import { useEffect, useRef, useState } from "react";
import { APIStatusCode, Permissions } from "../../../../app/enums";
import { ErrorMessage } from "@hookform/error-message";
import ICustomFields from "../../../../models/CustomFields";
import Skeleton from "react-loading-skeleton";
import { usePermissionCheck } from "../../../../app/common/helper/Permissions";
import MISTooltip from "../../../../components/tooltip";
import { PERMISSION_REQUIRED, UNSAVED_CHANGE_MESSAGE } from "../../../../app/constants";


export default function CustomFields() {
    let navigate = useNavigate();
    const { establishmentId, clientId } = useParams();
    const queryClient = useQueryClient();
    const [selectedFields, setSelectedFields] = useState<string[]>([]);
    const [clienTypeName, setclienTypeName] = useState<string>();
    const canEditEstablishments = usePermissionCheck(Permissions.CanEditEstablishments);

    const refSubmitButtom = useRef<HTMLButtonElement>(null);

    const { isLoading: isCustomFieldsLoading, data: customFields } = useQuery([`consumer-Fields-${establishmentId}-${clientId}`], () =>
        ServiceManager.EstablishmentService.GetCustomFields(establishmentId, clientId), { refetchOnWindowFocus: false }
    );

    const { isLoading: isFinalPropertiesLoading, data: finalProperties } = useQuery([`people-additional-properties${establishmentId}`], () =>
        ServiceManager.EstablishmentService.GetFinalAdditionalProperties(establishmentId), { refetchOnWindowFocus: false, cacheTime: 0 }
    );

    const { isLoading: isConsumerClientsLoading, data: consumerClients } = useQuery([`estbalishment-consumer-clients-${establishmentId}`], () =>
        ServiceManager.EstablishmentService.GetConsumerClients(establishmentId), { refetchOnWindowFocus: false }
    );
    const mutation = useMutation({
        mutationFn: (model: ICustomFields) => {
            return ServiceManager.EstablishmentService.UpdateCustomFields(establishmentId, clientId, model);
        },
        onError: (error, variables, context) => {

        },
        onSuccess: (data, variables, context) => {
            if (data === null) {
                ServiceManager.ToastService.showError("Unable to save custom fields.");
            }
            else {
                if (data.status === APIStatusCode.Ok) {
                    ServiceManager.ToastService.showSuccess("Custom fields saved successfully.");
                    queryClient.invalidateQueries({ queryKey: [`consumer-Fields-${establishmentId}-${clientId}`] });
                }
                else {
                    const errors = data?.response?.data?.errors;
                    if (errors) {
                        Object.keys(errors).forEach(field => {
                            ServiceManager.ToastService.showError(`${field}: ${errors[field][0]}`);
                        });
                    }
                    else {
                        ServiceManager.ToastService.showError(data?.response?.data);
                    }
                }
            }
        }
    });

    const isUnsavedChanges = (fieldMappings: any) => {
        const prevFormData = getValues().data;
        const filteredFormData = prevFormData.filter((obj: any) => Object.values(obj).some(value => value !== ''));
        const customFieldData = fieldMappings.data;
        if ((filteredFormData && filteredFormData.length === 0) && (customFieldData && customFieldData.length === 0)) {
            return false;
        }

        const sortedFormData = JSON.stringify(filteredFormData.sort());
        const sortedCustomFieldDataData = JSON.stringify(customFieldData ? customFieldData.sort() : []);

        return sortedFormData !== sortedCustomFieldDataData;
    }

    useEffect(() => {
        if (!isCustomFieldsLoading && customFields) {
            const handleBeforeUnload = (event: any) => {
                if (isUnsavedChanges(customFields)) {
                    const message = UNSAVED_CHANGE_MESSAGE;
                    event.returnValue = message;
                    return message;
                }
            };
            window.addEventListener('beforeunload', handleBeforeUnload);
            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customFields]);

    const handleUnsavedChangesClick = () => {
        if (isUnsavedChanges(customFields)) {
            const shouldLeave = window.confirm(UNSAVED_CHANGE_MESSAGE);
            if (shouldLeave) {
                navigate(-1);
            }
        }
        else {
            navigate(-1);
        }
    }

    const onSubmit = (data: any, e: any) => {
        let customFieldMapping: any = [];
        let fieldMappings = data.data;
        fieldMappings.forEach(function (fieldMapping: any) {
            customFieldMapping.push({
                customFieldName: fieldMapping.customFieldName,
                additionalPropertyName: fieldMapping.additionalPropertyName,
            });

        });
        mutation.mutate(customFieldMapping);

    };

    const handleFieldSelect = (eventKey: any, index: any) => {
        const updatedValues = [...selectedFields];
        updatedValues[index] = eventKey;
        setSelectedFields(updatedValues);

        setValue(`data[${index}].additionalPropertyName`, eventKey);
        setValue(`data[${index}].customFieldName`, eventKey);

        clearErrors(`data[${index}].additionalPropertyName`);
    }

    const { register, handleSubmit, clearErrors, setValue, reset, getValues, control, formState: { errors } } = useForm();
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'data',
    });

    const handleRemove = (event: any, index: any) => {
        event.preventDefault();
        remove(index)
        reset({ data: getValues().data });

        setSelectedFields(selectedFields.filter((_, i) => i !== index));
    };
    const validateDuplicateScriptText = (value: any, allValues: any) => {
        const scriptTextValues = allValues.data.map((item: any) => item.customFieldName);
        const isDuplicate = scriptTextValues.filter((text: any) => text === value).length > 1;
        if (isDuplicate) {
            return 'Duplicate Mapped Field is not allowed';
        }
        return undefined;
    };

    const isInitialMount = useRef(true);
    useEffect(() => {
        if (!isCustomFieldsLoading && customFields) {
            reset({ data: [] });
            const customfieldTypes: string[] = [];
            if (customFields?.data?.length > 0) {
                customFields?.data?.forEach((item: any, index: any) => {
                    append(item);

                    setValue(`data[${index}].additionalPropertyName`, item.additionalPropertyName);
                    customfieldTypes[index] = item.additionalPropertyName;

                    reset({ data: getValues().data });
                });
                setSelectedFields(customfieldTypes);
            }
            else {
                if (isInitialMount.current) {
                    isInitialMount.current = false;
                }
            }
        }
        if (!isConsumerClientsLoading && consumerClients) {
            var client = consumerClients.find((element: any) => {
                return element.clientID === clientId;
            })
            setclienTypeName(client.clientTypeName);
        }
    }, [isCustomFieldsLoading, customFields, isConsumerClientsLoading, consumerClients, clientId, append, setValue, reset, getValues]);


    const calculateMaxHeight = () => {
        const viewportHeight = window.innerHeight;
        const maxHeightPercentage = 80;
        return `${(viewportHeight * maxHeightPercentage) / 100}px`;
    };

    return (
        <Layout title={clienTypeName + "-Custom Fields"}
            button={
                canEditEstablishments ?
                    <MISButton text="Save" className="btn btn-primary crbc-bg-color me-2" type="submit" form="hook-form" FnOnClick={() => refSubmitButtom?.current?.click()} icon={<FontAwesomeIcon icon={faSave} />}></MISButton>
                    :
                    <MISTooltip message={PERMISSION_REQUIRED} placement="bottom">
                        <div className="disabled">
                            <MISButton text="Save" className="btn btn-secondary me-2" FnOnClick={() => null} disabled icon={<FontAwesomeIcon icon={faSave} />}></MISButton>
                        </div>
                    </MISTooltip>
            }
            backArrow={<FontAwesomeIcon icon={faArrowLeft} onClick={() => handleUnsavedChangesClick()} className="me-4 fs-5 cursor-pointer" />}
        >
            <Stack direction="horizontal">
                {
                    canEditEstablishments ?
                        <>
                            <MISButton text="New" className="btn btn-secondary btn-small me-2" FnOnClick={() => { append({}) }} icon={<FontAwesomeIcon icon={faAdd} />}></MISButton>
                            <MISButton text="Clear All" className="btn btn-secondary btn-small me-2" FnOnClick={() => { reset({ data: [] }) }} icon={<FontAwesomeIcon icon={faRefresh} />}></MISButton>
                        </>
                        :
                        <MISTooltip message={PERMISSION_REQUIRED} placement="bottom">
                            <div className="disabled">
                                <MISButton text="New" className="btn btn-secondary btn-small me-2" disabled FnOnClick={() => null} icon={<FontAwesomeIcon icon={faAdd} />}></MISButton>
                                <MISButton text="Clear All" className="btn btn-secondary btn-small me-2" disabled FnOnClick={() => null} icon={<FontAwesomeIcon icon={faRefresh} />}></MISButton>
                            </div>
                        </MISTooltip>
                }
            </Stack>
            <Stack className="mt-2">
                <form onSubmit={handleSubmit(onSubmit)} className="form">
                    <Container className="mt-3" style={{ height: calculateMaxHeight(), overflowY: 'auto' }}>
                        {isFinalPropertiesLoading ? (
                            <div className="mis-loader">
                                <Row className="pb-3">
                                    <Col md={3}><Skeleton height={20} /></Col>
                                    <Col md={3}><Skeleton height={20} /></Col>
                                    <Col md={4}><Skeleton height={20} /></Col>
                                    <Col md={2}><Skeleton height={20} /></Col>
                                </Row>
                                <Row className="pb-3">
                                    <Col md={3}><Skeleton height={20} /></Col>
                                    <Col md={3}><Skeleton height={20} /></Col>
                                    <Col md={4}><Skeleton height={20} /></Col>
                                    <Col md={2}><Skeleton height={20} /></Col>
                                </Row>
                                <Row className="pb-3">
                                    <Col md={3}><Skeleton height={20} /></Col>
                                    <Col md={3}><Skeleton height={20} /></Col>
                                    <Col md={4}><Skeleton height={20} /></Col>
                                    <Col md={2}><Skeleton height={20} /></Col>
                                </Row>
                            </div>
                        ) : (
                            <>
                                {fields.map((field, index) => (
                                    <div>
                                        <Row className="pb-3">
                                            <Col md={3}>
                                                <label className="mt-1 mb-1 font-weight-bold">Field</label>
                                                <Dropdown className="m-auto" onSelect={(event) => handleFieldSelect(event, index)}>
                                                    <Dropdown.Toggle
                                                        {...register(`data[${index}].additionalPropertyName`, { required: 'Field is required' })}
                                                        className={`btn btn-light w-100 d-flex justify-content-between align-items-center form-control ${(finalProperties.length === 0) ? 'is-invalid' : ''}`}
                                                        disabled={!canEditEstablishments}
                                                    >
                                                        <span className="text-ellipsis" title={`${selectedFields[index] || 'Select'}`}>{selectedFields[index] || 'Select'}</span>
                                                    </Dropdown.Toggle>
                                                    {finalProperties.length === 0 && <div className="invalid-feedback">Additional fields does not exists</div>}
                                                    <Dropdown.Menu className="timer-dropdown w-100">
                                                        {!isFinalPropertiesLoading && finalProperties &&
                                                            finalProperties.map((fields: any, index: any) => (

                                                                <Dropdown.Item key={index} eventKey={fields.propertyName} className="text-ellipsis" title={fields.propertyName}>
                                                                    {fields.propertyName}
                                                                </Dropdown.Item>
                                                            ))
                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <ErrorMessage
                                                    errors={errors}
                                                    name={`data[${index}].additionalPropertyName`}
                                                    render={({ message }: any) => <div className="invalid-feedback" style={{ display: 'block', color: '#DC3245' }}>{message}</div>}
                                                />
                                            </Col>
                                            <Col md={7}>
                                                <label className="mt-1 mb-1 font-weight-bold">Mapped Client Field</label>
                                                <Stack direction="horizontal" gap={3}>
                                                    <input {...register(`data[${index}].customFieldName`, { required: "Mapped Client Field is required", validate: { duplicate: validateDuplicateScriptText } })} autoFocus={true} defaultValue={selectedFields[index]}
                                                        className={`form-control ms-0 w-100 ${(errors.customFieldName) ? 'is-invalid' : ''}`} disabled={!canEditEstablishments} />

                                                    {
                                                        canEditEstablishments ?
                                                            <button className="btn btn-light red-color" onClick={(event) => handleRemove(event, index)}>
                                                                <FontAwesomeIcon icon={faTrashCan} />
                                                            </button>
                                                            :
                                                            <MISTooltip message={PERMISSION_REQUIRED} placement="bottom">
                                                                <div className="disabled">
                                                                    <button className="btn btn-light red-color" onClick={() => null} disabled>
                                                                        <FontAwesomeIcon icon={faTrashCan} />
                                                                    </button>
                                                                </div>
                                                            </MISTooltip>
                                                    }
                                                </Stack>
                                                <ErrorMessage
                                                    errors={errors}
                                                    name={`data[${index}].customFieldName`}
                                                    render={({ message }: any) => <div className="invalid-feedback" style={{ display: 'block', color: '#DC3245' }}>{message}</div>}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                            </>
                        )}
                    </Container>
                    <button hidden={true} ref={refSubmitButtom} type={"submit"} />
                </form>
            </Stack>
        </Layout>
    );


}