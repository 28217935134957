import React, { useEffect, useState } from "react";
import Layout from "../layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { ServiceManager } from "../../services/ServiceManager";
import SyncActions from "./sync/sync";
import EstablishmentData from "./data";
import { Stack, Tab, Tabs } from "react-bootstrap";
import ChildEstablishments from "./child-establishments";
import Skeleton from "react-loading-skeleton";
import ConfigActions from "./config/config";
import SyncSalesforce from "./sync/sync-salesforce";

export default function EstablishmentDetail(props: any) {

    const [salesforceExternalId, setSalesforceExternalId] = useState('');
    let navigate = useNavigate();
    const { establishmentId } = useParams();

    const { isLoading, data } = useQuery(["establishmentDetail-" + establishmentId], () =>
        ServiceManager.EstablishmentService.GetById(establishmentId, true), {
            refetchOnWindowFocus: false
        }
    );

    useEffect(() => {
        if (!isLoading && data !== null) {
            setSalesforceExternalId(data.salesforceExternalID);
        }
    }, [isLoading, data]);

    return (
        <Layout title={data?.name ?? ""}
            backArrow={
                <FontAwesomeIcon icon={faArrowLeft} onClick={() => navigate(-1)} className="me-4 fs-5 cursor-pointer" />
            }
            button={
                <Stack direction="horizontal" gap={3}>
                    <ConfigActions establishmentId={establishmentId} />
                    <SyncActions establishmentId={establishmentId} />
                    <SyncSalesforce establishmentId={establishmentId} salesforceExternalId={salesforceExternalId} />
                </Stack>
            }>

            <div className="establishment-details">
                {!isLoading && data != null &&
                    <React.Fragment>
                        <Tabs defaultActiveKey="data">
                            <Tab eventKey="data" title="Data">
                                <EstablishmentData />
                            </Tab>
                            <Tab eventKey="children" title="Child Establishments">
                                <ChildEstablishments childEstablishments={data?.children} />
                            </Tab>
                        </Tabs>
                    </React.Fragment>
                }
                {isLoading &&
                    <React.Fragment>
                        <div className="w-25">
                            <Skeleton height={20} count={4} />
                        </div>
                        <div className="mt-5">
                            <Skeleton height={20} count={6} />
                        </div>
                    </React.Fragment>
                }
            </div>

        </Layout>
    );
}