import BaseService from "./BaseService";

export default class TaskService extends BaseService {
  controllerName = "task";

  async GetLogs(dateStart: string, dateFinish: string, establishmentID: string,
    misType: number, status: number, salesForceID: string, includeChildren: boolean, sortBy: string, sortOrder: string, pageIndex: number, pageSize: number): Promise<any> {
    let data = {
      dateStart: dateStart,
      dateFinish: dateFinish,
      establishmentID: establishmentID,
      misType: misType,
      status: status,
      salesForceCaseID: salesForceID,
      includeChildren: includeChildren,
      sortBy: sortBy,
      sortOrder: sortOrder,
      page: pageIndex,
      pageSize: pageSize
    };
    if (!data.dateStart || !data.dateFinish) {
      return null;
    }
    let filteredData = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value !== '' && value !== -1 && value !== null)
    );
    const resp = await this.Get(``, filteredData);
    return resp.data ? resp.data : resp;
  }

  async GetTaskStats(dateStart: string, dateFinish: string) {
    const params: any = {
      DateStart: dateStart,
      DateFinish: dateFinish
    }

    const resp = await this.Get(`statistics`, params);

    return resp.data ? resp.data : resp;
  }

  async UpdateCaseIdAndUrl(taskID: number, salesforceCaseId: string, SalesforceCaseUrl: string): Promise<any> {
    const params: any = {
      SalesforceCaseId: salesforceCaseId,
      SalesforceCaseUrl: SalesforceCaseUrl
    }
    const resp = await this.Patch(`${taskID}/salesforce`, params);
    return resp.data ? resp.data : resp;
  }
}