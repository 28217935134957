import IRole from "../models/Role";
import {GUID, toGuid} from "../app/common/helper/Guid";
import BaseService from "./BaseService";

export default class RoleService extends BaseService {
    controllerName = "role";

    async Create(role: IRole): Promise<any> {
        const resp = await this.Post("", role);
        return resp;
    }

    async Update(roleId: any, role: IRole): Promise<any> {
        const resp = await this.Put(roleId, role);
        return resp;
    }

    async GetById(roleId: any): Promise<any> {
        const resp = await this.Get(`${roleId}`, {});
        return resp ? resp.data : null;
    }

    async GetUsersById(roleId: any): Promise<any> {
        const resp = await this.Get(`${roleId}/users`, {});
        return resp ? resp.data : null;
    }

    async GetAll(): Promise<any> {
        const resp = await this.Get("", {});
        return resp ? resp.data : null;
    }

    async DeleteById(roleId: string): Promise<any> {
        const resp = await this.Delete(`${roleId}`);
        return resp;
    }

    async DeleteUserById(roleId: string, userId: string): Promise<any> {
        const resp = await this.Delete(`${roleId}/user/${userId}`);
        return resp;
    }

    async AssignRole(roleId: any, userId: string): Promise<any> {
        const resp = await this.Put(`${roleId}/user/${userId}`, {});
        return resp.data ? resp.data : resp;
    }

    async AssignUserRoles(roleId: GUID, userId: GUID): Promise<any> {
        const resp = await this.Put(toGuid(`${roleId}/user/${userId}`), {});
        return resp.data ? resp.data : resp;
    }

    async UnAssignUserRoles(roleId: GUID, userId: GUID): Promise<any> {
        const resp = await this.Delete(toGuid(`${roleId}/user/${userId}`));
        return resp.data ? resp.data : resp;
    }
}
