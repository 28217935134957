import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MISButton from "../button";
import CustomModal from "../modal";
import { useState } from "react";
import PagedFilterTable from "../table";
import { createColumnHelper } from "@tanstack/react-table";
import IExtractClient from "../../models/ExtractClient";
import { MisType } from "../../app/enums";
import { ServiceManager } from "../../services/ServiceManager";
import { Button, CloseButton, Stack } from "react-bootstrap";

interface IExtractedDataFilter {
    establishmentId: any;
    callbackClientId: any;
}

export default function ExtractedDataFilter(props: IExtractedDataFilter) {

    const [showClientModal, setShowClientModal] = useState(false);
    const [rowSelection, setRowSelection] = useState(null);
    const [tableJobs, setTableJobs] = useState([]);
    const [filterList, setFilterList] = useState<string[]>([]);

    const columnHelper = createColumnHelper<IExtractClient>();
    const columns = [
        columnHelper.display({
            id: "select_clientId",
            size: 1,
            cell: props => <input type="radio" className="cursor-pointer crbc-color" checked={rowSelection === props.row.original.clientID} onChange={(e) => { onRowClick(props.row); }}></input>,
        }),
        columnHelper.accessor('clientID', {
            header: () => <span>Client ID</span>,
            size: 70
        }),
        columnHelper.accessor('misType', {
            header: () => <span>MIS</span>,
            cell: ({ row }) => (
                <span>{MisType[row.original.misType]?.toUpperCase()}</span>
            ),
            size: 30
        })
    ];

    async function fetchData(options: {
        pageIndex: number;
        pageSize: number;
        searchValue: string
    }) {
        const establishmentResponse = await ServiceManager.ConfigurationService.GetExtractClients(props.establishmentId);
        setTableJobs(establishmentResponse);
        return establishmentResponse;
    }

    function onClose() {
        try {
            setShowClientModal(false);
        } catch (e) {
            console.error(e);
        }
    }

    function handleApply() {
        try {
            setFilterList([]);
            setFilterList((prev) => [...prev, String(rowSelection)]);
            props.callbackClientId(rowSelection);
            setShowClientModal(false);

        } catch (e) {
            console.error(e);
        }
    }

    function onRowClick(row: any) {
        const selectedValue = row.original.clientID;
        setRowSelection((prevValue) => (prevValue === selectedValue ? null : selectedValue));
    }

    const handleValueChange = (event: any) => {
        event.preventDefault();
        setShowClientModal(true);
    }

    const handleRemoveFilter = (event: any) => {
        event.preventDefault();
        const data = event.target.id;
        let newFilterList = [...filterList];
        let index = newFilterList.indexOf(data);
        if (index > -1) {
            newFilterList.splice(index, 1);
            setFilterList(newFilterList);
            setRowSelection(null);
            props.callbackClientId(null);
        }
    };

    const ClientsModal = () => {
        return (
            <>
                <div id="extractClientSetup">
                    <PagedFilterTable
                        tableKey={"extract-clients-view-filter"}
                        columns={columns}
                        data={tableJobs}
                        usePagination={true}
                        useSearch={false}
                        pageRecords={10}
                        fnFetchData={fetchData}
                    />
                </div>
                <div className="mt-4">
                    <Button
                        variant="popup-btn right-margin10 btn-outline-secondary"
                        onClick={() => onClose()}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        variant="popup-btn btn btn-primary crbc-bg-color "
                        className={` ${rowSelection === null ? "disabled" : ""}`}
                        onClick={() => handleApply()}
                    >
                        Apply
                    </Button>
                </div>
            </>
        );
    }

    return (
        <>
            <Stack direction="horizontal" gap={3}>
                <MISButton text="Filter"
                    className="btn btn-primary crbc-bg-color d-flex align-items-center"
                    FnOnClick={() => setShowClientModal(true)}
                    textClass="ms-0 text-center font-14"
                    icon={<FontAwesomeIcon icon={faFilter} className="font-14" />}></MISButton>
                {filterList?.map((element, index) => {
                    return (
                        <div key={index} id={element}>
                            <div className="btn selected-filter-color ms-2" id={element}>
                                <div className="filter-wrap d-flex align-items-center font-14">
                                    <div id={element} onClick={handleValueChange} title={element}>
                                        {`${element.substring(0, 6)}...${element.slice(-6)}`}
                                    </div>
                                    <CloseButton id={element} value={element} className="ms-2 p-0" onClick={handleRemoveFilter} />
                                </div>
                            </div>
                        </div>
                    );
                })}
            </Stack>

            <CustomModal isShow={showClientModal} handleClose={() => setShowClientModal(false)} header={<p className="heading">Clients</p>} size="lg">
                {ClientsModal()}
            </CustomModal>
        </>
    );
}