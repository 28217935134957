import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { Alert, Col, Container, Row, Spinner, Stack } from "react-bootstrap";
import { ServiceManager } from "../../../../../services/ServiceManager";
import { APIStatusCode } from "../../../../../app/enums";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import MISButton from "../../../../../components/button";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Layout from "../../../../layout";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import ISubTask from "../../../../../models/SubTask";
import Select from "react-select";

export default function CreateSubTasks(props: any) {
    const { establishmentId, taskId, subTaskIndex } = useParams();
    const queryClient = useQueryClient();
    const [userTypesList, setUserTypesList] = useState<any[]>([]);
    const [currentTaskSettings, setCurrentTaskSettings] = useState<any>();
    const [selectedUserTypes, setSelectedUserTypes] = useState<any[]>([]);
    let navigate = useNavigate();

    const { isLoading : isUserTypesLoading, data : userTypes } = useQuery([`estbalishment-user-types-${establishmentId}`], () =>
     ServiceManager.UserTypeService.GetByEstablishmentId(establishmentId), { refetchOnWindowFocus: false }
    );

    const { data : taskDetails } = useQuery([`task-details-${establishmentId}`], () =>
        ServiceManager.EstablishmentService.GetTaskById(establishmentId, taskId), { refetchOnWindowFocus: false }
    );

    const mutation = useMutation({
        mutationFn: (model: ISubTask) => {
            return ServiceManager.EstablishmentService.UpdateSubTasks(establishmentId, taskId, model);
        },
        onError: (error, variables, context) => {

        },
        onSuccess: (data, variables, context) => {
            if (data === null) {
                ServiceManager.ToastService.showError(`Unable to ${subTaskIndex ? 'update' : 'add'} subtask.`);
            }
            else {
                if (data.status === APIStatusCode.Ok) {
                    ServiceManager.ToastService.showSuccess(`Subtask ${subTaskIndex ? 'updated' : 'created'} successfully.`);
                    queryClient.invalidateQueries({ queryKey: [`task-details-${establishmentId}`] });
                    navigate(-1);
                }
                else {
                    const errors = data?.response?.data?.errors;
                    if (errors) {
                        Object.keys(errors).forEach(field => {
                            ServiceManager.ToastService.showError(`${field}: ${errors[field][0]}`);
                        });
                    }
                    else {
                        ServiceManager.ToastService.showError(data?.message);
                    }
                }
            }
        }
    });

    useEffect(() => {
        if (userTypes) {
            let arrayObj: any[] = [];
            userTypes?.forEach((userType: any) => {
                arrayObj.push({
                    value: userType.userTypeID,
                    label: userType.userTypeName,
                    userTypeId: userType.userTypeID,
                });
            });
             setUserTypesList(arrayObj);
        }
    }, [userTypes]);

    useEffect(() => {
        if (taskDetails) {
            const taskSettings = JSON.parse(taskDetails.taskSettings);
            const defaultTaskSettings = subTaskIndex ? taskSettings.Extracts[parseInt(subTaskIndex, 10)] : '';

            setCurrentTaskSettings(defaultTaskSettings);
            if (defaultTaskSettings.UserTypes) {
                let arrayObj: any[] = [];
                userTypes.forEach((userType: any) => {
                    if (defaultTaskSettings.UserTypes.indexOf(userType.userTypeID) > -1) {
                        arrayObj.push({
                            value: userType.userTypeID,
                            label: userType.userTypeName,
                            userTypeId: userType.userTypeID,
                        });
                    }
                });

                setSelectedUserTypes(arrayObj);
            }
        }
    }, [taskDetails, subTaskIndex, userTypes]);

    function removeEmptyProperties(obj : any) {
        for (var propName in obj) {
          if (!obj[propName]) {
            delete obj[propName];
          } else if (typeof obj[propName] === 'object') {
            removeEmptyProperties(obj[propName]);
            if (Object.keys(obj[propName]).length === 0) {
              delete obj[propName];
            }
          }
        }
      }

    const { register, handleSubmit, control, formState: { errors } } = useForm<ISubTask>();
    const onSubmit: SubmitHandler<ISubTask> = (data) => {
        removeEmptyProperties(data);
        let taskSettings = taskDetails?.taskSettings ? JSON.parse(taskDetails?.taskSettings) : {};

        if(subTaskIndex) {
            taskSettings.Extracts[parseInt(subTaskIndex, 10)] = data;
        }
        else {
            taskSettings.Extracts = [...(taskSettings.Extracts ?? []), data];
        }
        mutation.mutate(taskSettings);
    };
    const firstCol = 3, secondCol = 4;

    return (
        <Layout title={subTaskIndex ? "Edit Extract Subtasks" : "Extract Subtasks"}
            backArrow={<FontAwesomeIcon icon={faArrowLeft} onClick={() => navigate(-1)} className="me-4 fs-5 cursor-pointer" />}
        >
        <Stack>
            {!isUserTypesLoading && userTypes.length === 0 &&
                <Alert key={'danger'} variant={'danger'}>
                    No User Types set up for establishment
                </Alert>
            }
                <form onSubmit={handleSubmit(onSubmit)} className="form">
                    <Container className="mt-3">
                        <Row className="pb-3">
                            <Col md={firstCol}>Database Server <span className="text-muted">(Optional)</span></Col>
                            <Col md={secondCol}>
                                {(currentTaskSettings || !subTaskIndex) &&
                                    <input {...register("DatabaseServer")} defaultValue={currentTaskSettings?.DatabaseServer} className={`form-control mt-1 ms-0 w-100`} />
                                }
                            </Col>
                        </Row>
                        <Row className="pb-3">
                            <Col md={firstCol}>Database Name <span className="text-muted">(Optional)</span></Col>
                            <Col md={secondCol}>
                                {(currentTaskSettings || !subTaskIndex) &&
                                    <input {...register("DatabaseName")} defaultValue={currentTaskSettings?.DatabaseName} className={`form-control mt-1 ms-0 w-100`} />
                                }
                            </Col>
                        </Row>
                        <Row className="pb-3">
                            <Col md={firstCol}>User Type <span className="text-muted">(Mandatory)</span></Col>
                            <Col md={secondCol}>
                            {(selectedUserTypes?.length > 0 || !subTaskIndex) && <Controller
                                control={control}
                                name="UserTypes"
                                defaultValue={currentTaskSettings?.UserTypes}
                                rules={{
                                    required: true,
                                }}
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                }) => (
                                <Select
                                    options={userTypesList}
                                    className={`${(errors.UserTypes) ? 'is-invalid' : ''}`}
                                    isMulti
                                    defaultValue={selectedUserTypes}
                                    closeMenuOnSelect={false}
                                    menuPosition={'fixed'}
                                    menuPlacement="auto"
                                    minMenuHeight={300}
                                    onChange={val => onChange(val.map(c => c.value))}
                                    />  
                                    )}
                                />
                            }
                                {errors.UserTypes && <div className="invalid-feedback">At leaset one User Type is required</div>}
                            </Col>
                        </Row>
                        <Row className="pb-3">
                            <Col md={firstCol}>Photos</Col>
                            <Col md={secondCol}>
                                {(currentTaskSettings || !subTaskIndex) &&
                                    <input type="checkbox" {...register("Photos")} defaultChecked={currentTaskSettings?.Photos || false} className={`mt-1 ms-0`} />
                                }
                            </Col>
                        </Row>
                        {
                            !mutation.isLoading
                                ? (
                                    <Row className="mt-3">
                                        <Col md={firstCol}></Col>
                                        <Col md={secondCol}>
                                            <MISButton text="Cancel" className="btn popup-btn right-margin10 btn-outline-secondary" type="button" FnOnClick={() => navigate(-1)} />
                                            <MISButton text={subTaskIndex ? "Update" : "Create"} className="popup-btn btn btn-primary crbc-bg-color" type="submit" FnOnClick={handleSubmit(onSubmit)} />
                                        </Col>
                                    </Row>
                                )
                                : (
                                    <Row>
                                        <Col md={firstCol}></Col>
                                        <Col md={secondCol}>
                                            <div className="d-flex justify-content-center mt-3">
                                                <Spinner animation="border" variant="info" role="status" > </Spinner>
                                            </div>
                                        </Col>
                                    </Row>
                                )
                        }
                    </Container>
                </form>
        </Stack>
        </Layout>
    );
}