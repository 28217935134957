import { Button, CloseButton, Dropdown, Stack } from "react-bootstrap";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import CustomModal from "../modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ServiceManager } from "../../services/ServiceManager";
import { useForm } from "react-hook-form";

interface IForceRefreshFilter{
    establishmentId: any;
    callbackYear: any;
    callbackUserType: any;
}

interface IYearForm {
    year: string;
}

export default function ForceRefreshFilter(props: IForceRefreshFilter){
    const { register, handleSubmit, reset, formState: { errors }, setError } = useForm<IYearForm>();
    const [FilterList, setFilterList] = useState<string[]>([]);
    const [showYearModal, setShowYearModal] = useState(false);
    const [showUserTypeModal, setShowUserTypeModal] = useState(false);
    const [userTypes, setUserTypes] = useState([]);
    const [yearSearchValue, setYearSearchValue] = useState<string>('');
    const handleCloseYearModal = () => {
        setTimeout(() => {
            reset();
        }, 1);
        setShowYearModal(false);
        setYearSearchValue('');
    };
    const handleCloseUserTypeModal = () => setShowUserTypeModal(false);

    useEffect(() => {
        async function getAllUserTypes() {
            const userTypes = await ServiceManager.EstablishmentService.GetAllUserTypes(props.establishmentId);
            setUserTypes(userTypes);
        }
        getAllUserTypes()
        // eslint-disable-next-line
    }, []);    

    const handleShowYearModal = () => {
        setShowYearModal(true);
    };
    const handleShowUserTypeModal = () => {
        setShowUserTypeModal(true);
    };

    const ModalHeader = ({ heading, title }: any) => {
        return (
            <Stack>
                <p className="heading">{heading}</p>
                <p className="text-muted">{title}.</p>
            </Stack>
        )
    };

    const handleRemoveFilter = (event: any) => {
        event.preventDefault();
        const data = event.target.id;
        let newFilterList = [...FilterList];
        let index = newFilterList.indexOf(data);
        if (index > -1) {
            newFilterList.splice(index, 1);
            setFilterList(newFilterList);
            if (data === "Year") {
                props.callbackYear(null);
            }
            else if (data === "User Type") {
                props.callbackUserType(null);
            }
        }
    };

    const handleYearFieldChange = (event: any) => {
        setYearSearchValue(event.target.value);
    };

    const handleEnterPress = (event: any) => {
        if (event.key === 'Enter') {
            if (!event.target.value) {
                event.preventDefault();
                setError('year', {
                    message: 'Please entery any year',
                });
                return;
            }
            selectYearFilter();
        }
    };

    function selectYearFilter() {
        if (FilterList.indexOf("Year") < 0) {
            setFilterList((prev) => [...prev, "Year"]);
        }
        props.callbackYear(yearSearchValue);
        setShowYearModal(false);
        handleCloseYearModal();        
    }
    function selectUserTypeFilter(userType: any) {
        if (FilterList.indexOf("User Type") < 0) {
            setFilterList((prev) => [...prev, "User Type"]);
        }
        props.callbackUserType(userType);
        setShowUserTypeModal(false);
    }

    const YearModal = () => {
        return (
            <Stack>
                <form onSubmit={handleSubmit(selectYearFilter)}>
                    <input type='text' className={`form-control mt-1 ms-0 w-100 ${(errors.year) ? 'is-invalid' : ''}`} placeholder="Search by year" value={yearSearchValue} 
                        {...register('year', { required: 'Please entery any year' })}
                        onChange={handleYearFieldChange}
                        onKeyDown={handleEnterPress}/>
                    {errors.year && <p className="invalid-feedback m-1">{errors.year.message}</p>}
                    <Stack direction="horizontal" className="mt-3">
                        <Button variant="popup-btn right-margin10 btn-outline-secondary w-100" onClick={handleCloseYearModal}>
                            Cancel
                        </Button>
                        <Button variant="popup-btn btn btn-primary crbc-bg-color w-100" type="submit">
                            Apply
                        </Button>
                    </Stack>
                </form>
            </Stack>
        )
    }

    const UserTypeModal = () => {
        return (
            <Stack>
                {
                    userTypes?.length > 0 ?
                        <Dropdown onSelect={selectUserTypeFilter}>
                            <Dropdown.Toggle className="btn btn-secondary crbc-bg-color w-100 d-flex justify-content-between align-items-center">
                                User Type
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="timer-dropdown">
                                {userTypes.map((item: any) => (
                                    <Dropdown.Item key={item.userTypeID} eventKey={item.userTypeID}>
                                        {item.userTypeName}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    : <p className="alert alert-danger text-center">No user type found</p>
                }
            </Stack>
        )
    }

    return (
        <>
            <Stack direction="horizontal" className="me-2">
                <Dropdown align={"end"}> 
                    <Dropdown.Toggle variant="primary" className="dropdown-filter crbc-bg-color" style={{fontSize: 14}}>
                        <FontAwesomeIcon icon={faFilter} /> Filter
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="timer-dropdown">
                        <div className="border-bottom2"><Dropdown.Item onClick={handleShowYearModal} className="mb-1 font-14">Year</Dropdown.Item></div>
                        <Dropdown.Item onClick={handleShowUserTypeModal} className="mb-1 font-14">User Type</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                {FilterList.map((element, index) => {
                    return (
                        <div key={index} id={element}>
                            <div className="btn selected-filter-color ms-2" id={element}>
                                {
                                    element !== "Date/Time"
                                        ? <div className="filter-wrap d-flex align-items-center font-14">{element} <CloseButton id={element} className="ms-2 p-0" onClick={handleRemoveFilter} /></div>
                                        : <div className="font-14">{element}</div>
                                }
                            </div>
                        </div>
                    );
                })}
            </Stack>
            <CustomModal isShow={showYearModal} handleClose={handleCloseYearModal} header={<ModalHeader heading="Year" title="Please enter any year" />} size="sm">
                {YearModal()}
            </CustomModal>
            <CustomModal isShow={showUserTypeModal} handleClose={handleCloseUserTypeModal} header={<ModalHeader heading="User Type" title="Please select a user type" />} size="sm">
                <UserTypeModal />
            </CustomModal>
        </>
    );
}