import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MISButton from "../../../../components/button";
import Layout from "../../../layout";
import { faAdd, faArrowLeft, faSave, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Col, Container, Dropdown, Row, Stack } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ServiceManager } from "../../../../services/ServiceManager";
import { useEffect, useRef, useState } from "react";
import { APIStatusCode, ExtractedPersonFieldTypes, Permissions } from "../../../../app/enums";
import { ErrorMessage } from "@hookform/error-message";
import ISimsUserTypeFieldMappings from "../../../../models/SimsUserTypeFieldMappings";
import Skeleton from "react-loading-skeleton";
import { usePermissionCheck } from "../../../../app/common/helper/Permissions";
import MISTooltip from "../../../../components/tooltip";
import { PERMISSION_REQUIRED, UNSAVED_CHANGE_MESSAGE } from "../../../../app/constants";


export default function ExtractClientOverrides() {
    let navigate = useNavigate();
    const { establishmentId, configurationId } = useParams();
    const queryClient = useQueryClient();
    const [selectedUserTypes, setSelectedUserTypes] = useState<string[]>([]);
    const [selectedFieldTypes, setSelectedFieldTypes] = useState<string[]>([]);
    const canEditEstablishments = usePermissionCheck(Permissions.CanEditEstablishments);

    const refSubmitButtom = useRef<HTMLButtonElement>(null);

    const { isLoading : isSimsUserTypesLoading, data : simsUserTypes } = useQuery([`extract-client-user-types-${configurationId}`], () =>
        ServiceManager.ConfigurationService.GetSimsUserTypes(establishmentId, configurationId), { refetchOnWindowFocus: false }
    );

    const { isLoading : isSimsUserTypeFieldMappingsLoading, data : simsUserTypeFieldMappings } = useQuery([`extract-client-user-types-field-mappings-${configurationId}`], () =>
        ServiceManager.ConfigurationService.GetSimsUserTypeFieldMappings(establishmentId, configurationId), { refetchOnWindowFocus: false, cacheTime: 0 }
    );

    const mutation = useMutation({
        mutationFn: (model: ISimsUserTypeFieldMappings) => {
            return ServiceManager.ConfigurationService.UpdateSimsUserTypeFieldMappings(establishmentId, configurationId, model);
        },
        onError: (error, variables, context) => {

        },
        onSuccess: (data, variables, context) => {
            if (data === null) {
                ServiceManager.ToastService.showError("Unable to save overrides.");
            }
            else {
                if (data.status === APIStatusCode.Ok) {
                    ServiceManager.ToastService.showSuccess("Overrides saved successfully.");
                    queryClient.invalidateQueries({ queryKey: [`extract-client-user-types-field-mappings-${configurationId}`] });
                }
                else {
                    let errorMessage = (data.response && data.response.data) ? data.response.data : data.message;
                    ServiceManager.ToastService.showError(errorMessage.toString());
                }
            }
        }
    });

    const isUnsavedChanges = (simsUserTypeFieldMappings : any) => {
        const prevFormData = getValues().data;

        const filteredFormData = prevFormData.filter((obj: any) => {
            const nodeName = obj.fieldName;
            return nodeName !== '' && nodeName !== undefined;
        });

        const overridesData = simsUserTypeFieldMappings;

        if((filteredFormData && filteredFormData.length === 0) && (overridesData && overridesData.length === 0)) {
            return false;
        }

        const sortedFormData = JSON.stringify(filteredFormData.sort());
        const sortedOverridesData = JSON.stringify(overridesData.sort());

        return sortedFormData !== sortedOverridesData;
    }

    useEffect(() => {
        if (!isSimsUserTypeFieldMappingsLoading && simsUserTypeFieldMappings) {
            const handleBeforeUnload = (event: any) => {
                if (isUnsavedChanges(simsUserTypeFieldMappings)) {
                    const message = UNSAVED_CHANGE_MESSAGE;
                    event.returnValue = message;
                    return message;
                }
            };
            window.addEventListener('beforeunload', handleBeforeUnload);
            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [simsUserTypeFieldMappings]);

    const handleUnsavedChangesClick = () => {
        if(isUnsavedChanges(simsUserTypeFieldMappings)) {
            const shouldLeave = window.confirm(UNSAVED_CHANGE_MESSAGE);
            if (shouldLeave) {
                navigate(-1);
            }
        }
        else {
            navigate(-1);
        }
    }

    const onSubmit = (data : any) => {
        let simsUserTypeFieldMappings = data.data;

        simsUserTypeFieldMappings.forEach(function(fieldMapping : any) {
            fieldMapping.fieldType = Number(fieldMapping.fieldType);
        });

        mutation.mutate(simsUserTypeFieldMappings);
    };


    const handleFieldTypeSelect = (eventKey: any, index : any) => {
        const updatedValues = [...selectedFieldTypes];
        updatedValues[index] = eventKey;
        setSelectedFieldTypes(updatedValues);

        setValue(`data[${index}].fieldType`, eventKey);
        clearErrors(`data[${index}].fieldType`);
    };

    const handleUserTypeSelect = (eventKey: any, index : any) => {
        const updatedValues = [...selectedUserTypes];
        updatedValues[index] = eventKey;
        setSelectedUserTypes(updatedValues);

        setValue(`data[${index}].userTypeID`, eventKey);
        clearErrors(`data[${index}].userTypeID`);
    }

    const { register, handleSubmit, clearErrors, setValue, reset, getValues, control, formState: { errors } } = useForm();
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'data',
    });

    const handleRemove = (index : any) => {
        remove(index);    
        reset({ data: getValues().data });

        setSelectedFieldTypes(selectedFieldTypes.filter((_, i) => i !== index));
        setSelectedUserTypes(selectedUserTypes.filter((_, i) => i !== index));
    };

    const isInitialMount = useRef(true);

    useEffect(() => {
        if (!isSimsUserTypeFieldMappingsLoading && simsUserTypeFieldMappings) {
            reset({ data: [] });

            if (simsUserTypeFieldMappings.length > 0) {
                const userTypeIdValues: string[] = [];
                const fieldTypeValues: string[] = [];

                simsUserTypeFieldMappings.forEach((item: any, index: any) => {
                    append(item);

                    setValue(`data[${index}].userTypeID`, item.userTypeID);
                    userTypeIdValues[index] = item.userTypeID;

                    setValue(`data[${index}].fieldType`, item.fieldType);
                    fieldTypeValues[index] = item.fieldType;

                    reset({ data: getValues().data });
                });

                setSelectedUserTypes(userTypeIdValues);
                setSelectedFieldTypes(fieldTypeValues);
            }
            else {
                if (isInitialMount.current) {
                    append({});
                    isInitialMount.current = false;
                }
            }
        }
    }, [isSimsUserTypeFieldMappingsLoading, simsUserTypeFieldMappings, append, setValue, reset, getValues]);

    const calculateMaxHeight = () => {
        const viewportHeight = window.innerHeight;
        const maxHeightPercentage = 80;
        return `${(viewportHeight * maxHeightPercentage) / 100}px`;
      };

    return (
        <Layout title="Overrides"
            button={
                canEditEstablishments ?
                    <MISButton text="Save" className="btn btn-primary crbc-bg-color me-2" type="submit" form="hook-form" FnOnClick={() => refSubmitButtom?.current?.click()} icon={<FontAwesomeIcon icon={faSave} />}></MISButton>
                    :
                    <MISTooltip message={PERMISSION_REQUIRED} placement="bottom">
                        <div className="disabled">
                            <MISButton text="Save" className="btn btn-secondary me-2" FnOnClick={() => null} disabled icon={<FontAwesomeIcon icon={faSave} />}></MISButton>
                        </div>
                    </MISTooltip>
            }
            backArrow={<FontAwesomeIcon icon={faArrowLeft} onClick={() => handleUnsavedChangesClick()} className="me-4 fs-5 cursor-pointer" />}
        >
            {!isSimsUserTypesLoading && simsUserTypes.length === 0 &&
                <Alert key={'danger'} variant={'danger'}>
                    No User Types set up for this extract client
                </Alert>
            }

            <Stack direction="horizontal">
                {
                    canEditEstablishments ?
                        <MISButton text="New" className="btn btn-secondary btn-small me-2" FnOnClick={() => { append({})}} icon={<FontAwesomeIcon icon={faAdd} />}></MISButton>
                        :
                        <MISTooltip message={PERMISSION_REQUIRED} placement="bottom">
                            <div className="disabled">
                                <MISButton text="New" className="btn btn-secondary btn-small me-2" FnOnClick={() => null} disabled
                                    icon={<FontAwesomeIcon icon={faAdd} />}></MISButton>
                            </div>
                        </MISTooltip>
                }
            </Stack>

            <Stack className="mt-2">
                <form onSubmit={handleSubmit(onSubmit)} className="form">
                    <Container className="mt-3" style={{ height: calculateMaxHeight(), overflowY: 'auto' }}>
                        {isSimsUserTypeFieldMappingsLoading ? (
                            <div className="mis-loader">
                                <Row className="pb-3">
                                    <Col md={3}><Skeleton height={20} /></Col>
                                    <Col md={3}><Skeleton height={20} /></Col>
                                    <Col md={4}><Skeleton height={20} /></Col>
                                    <Col md={2}><Skeleton height={20} /></Col>
                                </Row>
                                <Row className="pb-3">
                                    <Col md={3}><Skeleton height={20} /></Col>
                                    <Col md={3}><Skeleton height={20} /></Col>
                                    <Col md={4}><Skeleton height={20} /></Col>
                                    <Col md={2}><Skeleton height={20} /></Col>
                                </Row>
                                <Row className="pb-3">
                                    <Col md={3}><Skeleton height={20} /></Col>
                                    <Col md={3}><Skeleton height={20} /></Col>
                                    <Col md={4}><Skeleton height={20} /></Col>
                                    <Col md={2}><Skeleton height={20} /></Col>
                                </Row>
                            </div>
                        ) : (
                            <>
                                {fields.map((field, index) => (
                                    <div key={field.id}>
                                        <Row className="pb-3">
                                            <Col md={3}>
                                                <label className="mt-1 mb-1 font-weight-bold">User Type</label>
                                                <Dropdown className="m-auto" onSelect={(event, e) => handleUserTypeSelect(event, index)}>
                                                    <Dropdown.Toggle className="btn btn-light w-100 d-flex justify-content-between align-items-center" disabled={!canEditEstablishments}
                                                        {...register(`data[${index}].userTypeID`, { required: 'User Type is required' })}>
                                                        {(selectedUserTypes[index] && simsUserTypes && simsUserTypes.find((x: any) => x.userTypeID === parseInt(selectedUserTypes[index])).userTypeName) || 'Select'}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="timer-dropdown w-100">
                                                        {!isSimsUserTypesLoading && simsUserTypes &&
                                                            simsUserTypes.map((userType: any) => (
                                                                <Dropdown.Item key={userType.userTypeID} eventKey={userType.userTypeID}>
                                                                    {userType.userTypeName}
                                                                </Dropdown.Item>
                                                            ))
                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>

                                                <ErrorMessage
                                                    errors={errors}
                                                    name={`data[${index}].userTypeID`}
                                                    render={({ message }: any) => <div className="invalid-feedback" style={{ display: 'block', color: '#DC3245' }}>{message}</div>}
                                                />
                                            </Col>
                                            <Col md={3}>
                                                <label className="mt-1 mb-1 font-weight-bold">Field</label>
                                                <Dropdown className="m-auto" onSelect={(event) => handleFieldTypeSelect(event, index)}>
                                                    <Dropdown.Toggle className="btn btn-light w-100 d-flex justify-content-between align-items-center" disabled={!canEditEstablishments}
                                                        {...register(`data[${index}].fieldType`, { required: 'Field is required' })}>
                                                        {ExtractedPersonFieldTypes[parseInt(selectedFieldTypes[index])] || 'Select'}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="timer-dropdown w-100">
                                                        {Object.values(ExtractedPersonFieldTypes).filter((x: any) => isNaN(parseInt(x))).map((value, index): any => (
                                                            <Dropdown.Item key={index} eventKey={index}>
                                                                {value}
                                                            </Dropdown.Item>
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>

                                                <ErrorMessage
                                                    errors={errors}
                                                    name={`data[${index}].fieldType`}
                                                    render={({ message }: any) => <div className="invalid-feedback" style={{ display: 'block', color: '#DC3245' }}>{message}</div>}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <label className="mt-1 mb-1 font-weight-bold">XML Node Name</label>
                                               
                                               <Stack direction="horizontal" gap={3}>
                                                    <input {...register(`data[${index}].fieldName`, { required: "XML Node Name is required" })} defaultValue={''} 
                                                    className={`form-control ms-0 w-100 ${(errors.fieldName) ? 'is-invalid' : ''}`} disabled={!canEditEstablishments} />
                                               
                                                    {
                                                        canEditEstablishments ?
                                                            <button className="btn btn-light red-color" onClick={() => handleRemove(index)}>
                                                                <FontAwesomeIcon icon={faTrashCan} />
                                                            </button>
                                                            :
                                                            <MISTooltip message={PERMISSION_REQUIRED} placement="bottom">
                                                                <div className="disabled">
                                                                    <button className="btn btn-light red-color" onClick={() => null} disabled>
                                                                        <FontAwesomeIcon icon={faTrashCan} />
                                                                    </button>
                                                                </div>
                                                            </MISTooltip>
                                                    }
                                               </Stack>
                                                <ErrorMessage
                                                    errors={errors}
                                                    name={`data[${index}].fieldName`}
                                                    render={({ message }: any) => <div className="invalid-feedback" style={{ display: 'block', color: '#DC3245' }}>{message}</div>}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                ))}</>
                        )}
                    </Container>
                    <button hidden={true} ref={refSubmitButtom} type={"submit"} />
                </form>
            </Stack>
        </Layout>
    );


}