import BaseService from "./BaseService";

export default class SalesforceService extends BaseService {
  controllerName = "establishment/salesforce";

  async GetByExternalId(salesforceExternalId: any){
    const resp = await this.Get(`${salesforceExternalId}`, {});

    return resp.data ? resp.data : resp;
  }
}