import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "../../pages/login/protected-routes";
import Login from "../../pages/login";
import Dashboard from "../../pages/dashboard";
import Unauthorised from "../../pages/error/Unauthorised";
import Healthboard from "../../pages/healthboard";
import EstablishmentDetail from "../../pages/establishments/detail";
import Establishment from "../../pages/establishments";
import UserTypes from "../../pages/establishments/config/user-types";
import Tasks from "../../pages/establishments/config/task";
import ExtractClientSetup from "../../pages/establishments/config/extract-client-setup";
import ExtractClientDetails from "../../pages/establishments/config/extract-client-setup/extract-client-details";
import CreateUserTypeForm from "../../pages/establishments/config/extract-client-setup/user-types/create";
import ExtractClientOverrides from "../../pages/establishments/config/extract-client-setup/overrides";
import TransformScript from "../../pages/establishments/config/extract-client-setup/transforms";
import TaskDetail from "../../pages/establishments/config/task/details";
import CreateSubTasks from "../../pages/establishments/config/task/sub-tasks/create";
import CreateUpdateTask from "../../pages/establishments/config/task/create-update";
import ConsumerClients from "../../pages/establishments/config/consumer-clients";
import Settings from "../../pages/setting";
import CustomFields from "../../pages/establishments/config/consumer-clients/custom-fields";
import CreateUpdateRole from "../../pages/setting/roles/create-update-role";
import { Permissions } from "../../app/enums";

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
      <Route path="/healthboard" element={<ProtectedRoute><Healthboard /></ProtectedRoute>} />
      <Route path="/login" element={<Login />} />
      <Route path="/establishment/:establishmentId" element={<ProtectedRoute permission={Permissions.CanViewEstablishments}><EstablishmentDetail /></ProtectedRoute>} />
      <Route path="/establishment/:establishmentId/user-types" element={<ProtectedRoute permission={Permissions.CanViewEstablishments}><UserTypes /></ProtectedRoute>} />
      <Route path="/establishment/:establishmentId/tasks" element={<ProtectedRoute permission={Permissions.CanViewEstablishments}><Tasks /></ProtectedRoute>} />
      <Route path="/establishment/:establishmentId/tasks/new" element={<ProtectedRoute permission={Permissions.CanViewEstablishments}><CreateUpdateTask /></ProtectedRoute>} />
      <Route path="/establishment/:establishmentId/tasks/:taskId/" element={<ProtectedRoute permission={Permissions.CanViewEstablishments}><TaskDetail /></ProtectedRoute>} />
      <Route path="/establishment/:establishmentId/tasks/:taskId/edit" element={<ProtectedRoute permission={Permissions.CanViewEstablishments}><CreateUpdateTask /></ProtectedRoute>} />
      <Route path="/establishment/:establishmentId/tasks/:taskId/subtasks" element={<ProtectedRoute permission={Permissions.CanViewEstablishments}><CreateSubTasks /></ProtectedRoute>} />
      <Route path="/establishment/:establishmentId/tasks/:taskId/subtasks/:subTaskIndex" element={<ProtectedRoute permission={Permissions.CanViewEstablishments}><CreateSubTasks /></ProtectedRoute>} />
      <Route path="/establishment" element={<ProtectedRoute permission={Permissions.CanViewEstablishments}><Establishment /></ProtectedRoute>} />
      <Route path="/establishment/:establishmentId/extract-clients" element={<ProtectedRoute permission={Permissions.CanViewEstablishments}><ExtractClientSetup /></ProtectedRoute>} />
      <Route path="/establishment/:establishmentId/extract-clients/:configurationId" element={<ProtectedRoute permission={Permissions.CanViewEstablishments}><ExtractClientDetails /></ProtectedRoute>} />
      <Route path="/establishment/:establishmentId/extract-clients/:configurationId/user-type/new" element={<ProtectedRoute permission={Permissions.CanViewEstablishments}><CreateUserTypeForm /></ProtectedRoute>} />
      <Route path="/establishment/:establishmentId/extract-clients/:configurationId/user-type/:userTypeId" element={<ProtectedRoute permission={Permissions.CanViewEstablishments}><CreateUserTypeForm /></ProtectedRoute>} />
      <Route path="/establishment/:establishmentId/extract-clients/:configurationId/overrides" element={<ProtectedRoute permission={Permissions.CanViewEstablishments}><ExtractClientOverrides /></ProtectedRoute>} />
      <Route path="/establishment/:establishmentId/extract-clients/:configurationId/transform/:clientId" element={<ProtectedRoute permission={Permissions.CanViewEstablishments}><TransformScript /></ProtectedRoute>} />
      <Route path="/establishment/:establishmentId/consumer-clients" element={<ProtectedRoute permission={Permissions.CanViewEstablishments}><ConsumerClients /></ProtectedRoute>} />
      <Route path="/establishment/:establishmentId/comsumer-clients/:clientId/custom-fields" element={<ProtectedRoute permission={Permissions.CanViewEstablishments}><CustomFields /></ProtectedRoute>} />
      <Route path="/setting" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
      <Route path="/role/:roleId?" element={<ProtectedRoute permission={Permissions.CanManageRoles}><CreateUpdateRole /></ProtectedRoute>} />
      <Route path="/error/unauthorised" element={<Unauthorised />} />
    </Routes>
  );
}
