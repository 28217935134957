import { createColumnHelper } from "@tanstack/react-table";
import PagedFilterTable from "../../../components/table";
import IRoles from "../../../models/Roles";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { ServiceManager } from "../../../services/ServiceManager";
import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { APIStatusCode } from "../../../app/enums";
import { Spinner, Stack } from "react-bootstrap";
import MISButton from "../../../components/button";
import CustomModal from "../../../components/modal";
import IconElement from "../../../components/icon";
import Form from 'react-bootstrap/Form';

export default function RolesView() {
    const [tableJobs, setTableJobs] = useState([]);
    const [roleId, setRoleId] = useState<string>();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const handleCloseDeleteModal = () => setShowDeleteModal(false);

    const columnHelper = createColumnHelper<IRoles>();
    const columns = [
        columnHelper.accessor('roleName', {
            header: () => <span>Role Name</span>,
            size: 30,
            cell: ({ row }) => (
                <NavLink to={`/role/${row.original.roleID}`}>
                    {row.original.roleName}
                </NavLink>
            ),
        }),
        columnHelper.accessor('description', {
            header: () => <span>Description</span>,
            size: 40
        }),
        columnHelper.accessor('defaultForUsers', {
            header: () => <span>Default for User</span>,
            size: 20,
            cell: ({ row }) => (
                <Form.Check
                    type="checkbox"
                    disabled
                    checked={row.original.defaultForUsers}
                />
                ),
        }),
        columnHelper.accessor("roleID", {
            header: () => <span>Actions</span>,
            cell: ({ row }) => (
                <FontAwesomeIcon
                    className="cursor-pointer me-5"
                    icon={faTrashAlt}
                    size="lg"
                    onClick={() => {
                        processDelete(row.original.roleID);
                    }}
                />
            ),
            size: 10,
        })
    ]

    async function fetchData() {
        const packagesResponse = await ServiceManager.RoleService.GetAll();
        setTableJobs(packagesResponse);
        return packagesResponse;
    }

    const processDelete = (roleId: any) => {
        setRoleId(roleId);
        setShowDeleteModal(true);
    }

    const DeleteRoleModal = () => {
        const queryClient = useQueryClient();
        const mutation = useMutation({
            mutationFn: () => {
                return ServiceManager.RoleService.DeleteById(roleId ?? "");
            },
            onError: (error, variables, context) => {

            },
            onSuccess: (data, variables, context) => {
                if (data === null) {
                    ServiceManager.ToastService.showError("Unable to delete role.");
                }
                else {

                    if (data.status === APIStatusCode.NoContent) {
                        ServiceManager.ToastService.showSuccess("Role deleted successfully.");
                        queryClient.invalidateQueries({ queryKey: ["roles-table"] });
                        handleCloseDeleteModal();
                    }
                    else {
                        const errors = data?.response?.data?.errors;
                        if (errors) {
                            Object.keys(errors).forEach(field => {
                                ServiceManager.ToastService.showError(`${field}: ${errors[field][0]}`);
                            });
                        }
                        else {
                            ServiceManager.ToastService.showError(data?.response?.data ?? data?.message);
                        }
                    }
                }
            }
        });
        return (
            <Stack>
                <p className="heading text-center mt-2">Delete Role</p>
                <p className="text-center">Are you sure? This will also remove the Role from any users assigned. This action cannot be undone.</p>
                {
                    !mutation.isLoading
                        ? (<Stack direction="horizontal" className="mt-3">
                            <MISButton text="Cancel" className="popup-btn right-margin10 btn btn-outline-secondary w-50" FnOnClick={handleCloseDeleteModal} />
                            <MISButton text="Delete" className="popup-btn right-margin10 btn btn-danger w-50" FnOnClick={() => { mutation.mutate(); }} />
                        </Stack>
                        ) : (
                            <div className="d-flex justify-content-center mt-3">
                                <Spinner animation="border" variant="info" role="status"></Spinner>
                            </div>
                        )
                }
            </Stack>
        )
    }

    return (
        <>
            {<div id="rolesTable" className="mt-3">
                <PagedFilterTable
                    tableKey={"roles-table"}
                    columns={columns}
                    data={tableJobs}
                    usePagination={false}
                    useSearch={false}
                    pageRecords={50}
                    fnFetchData={fetchData}
                    title={
                        <div className="mb-2">
                            <h5 className="ps-3 pt-3"><b>Roles</b></h5>
                            <span style={{ fontSize: "14px" }} className="ps-3">
                                Depending on the assigned role, you can access particular features. You can view permissions of a role by clicking on the Role Name.
                            </span>
                        </div>}
                />
            </div>}
            
            <CustomModal isShow={showDeleteModal} handleClose={handleCloseDeleteModal} header={<IconElement iconType={faTrashAlt} headerClass="error-icon ms-6" color="#D92D20" />} size="md">
                <DeleteRoleModal />
            </CustomModal>
        </>
    )
}