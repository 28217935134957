export enum APIStatusCode {
    Ok = 200,
    Created = 201,
    NoContent = 204,
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    Conflict = 409,
}
export enum LogLevelName {
    Critical = 'Critical',
    Error = 'Error',
    Warning = 'Warning',
}


export enum LogLevel {
    Trace = 0,
    Debug = 1,
    Information = 2,
    Warning = 3,
    Error = 4,
    Critical = 5,
    None = 6,
}
export enum LogLevelStyles {
    "log-warning" = 3,
    "log-error" = 4,
    "log-critical" = 5,
    "log-information"=2,
    "log-debug" = 1,
    "log-trace" = 0
}

export enum LogdotStyles {
    "dot-warning" = 3,
    "dot-error" = 4,
    "dot-critical" = 5,
    "dot-information"=2,
    "dot-debug" = 1,
    "dot-trace" = 0
}
export enum timeUnit {
    minute = 'minute',
    day = 'day',
    hour = 'hour',
    month = 'month',
    year = 'year'
}

export enum Permissions {
    CanEditEstablishments = "canEditEstablishments",
    CanViewEstablishments = "canViewEstablishments",
    CanManageRoles = "canManageRoles",
    CanAssignRoles = "canAssignRoles",
    CanViewUsers = "canViewUsers",
    CanViewDashboard = "canViewDashboard",
    CanViewHealthboard = "canViewHealthboard"
}

export enum MisType {
    Unknown = 0,
    Sims = 1
}

export enum QueueStatus {
    NotExecuted = 1,
    Failed = 2,
    Completed = 3
}

export enum TaskType {
    ExtractMis = 1
}

export enum QueueStatusStyles {
    "queue-notexecuted" = 1,
    "queue-failed" = 2,
    "queue-completed" = 3
}

export enum MisServiceType{
    Task = 'Task',
    Extract = 'Extract',
    Transform = 'Transform'
}

export enum PauseSyncDuration{
    Hours4 = "4h",
    Hours12 = "12h",
    Hours24 = "24h",
    Week1 = "1w",
    Week2 = "2w",
    Month1 = "1m"
}

export enum SyncType{
    RunSync = "run",
    ForceRefresh = "refresh",
    PauseSync = "pause"
}

export enum FilterTableCallFrom{
    Healthboard = "1",
    ForceRefresh = "2",
    ExtractedData = "3",
    Establishment = "4"
}

export enum SimsStandardMappingTemplates
{
    None,
    Student,
    Staff,
    Applicants
}
export enum SalesForceCaseID
{
    CaseID = "CaseID",
    CaseIDNull = "CaseIDNull",
    CaseIDBlank = "CaseIDBlank",
    
}

export enum ExtractedPersonFieldTypes
{
    MisID,
    MisID2,
    Title,
    Surname,
    FirstName,
    LegalSurname,
    LegalFirstName,
    Year,
    RegClass,
    DateOfBirth,
    Gender,
    IsFsm,
    DateFsmExpiry,
    AdmissionNumber,
    StaffCode,
    PhotoID,
    PhotoImage,
    DietaryPreferences,
    DietaryNeeds,
    ParentalConsent,
    Upn
}

export enum ExtractStatus {
    None = 0,
    Active = 1,
    Inactive = 3
}

export enum ExtractStatusStyles {
    "None" = 0,
    "status-green" = 1,
    "status-amber" = 2,
    "status-red" = 3
}

export enum SortOrder {
    ASC = "ASC",
    DESC = "DESC",
    NONE = "NONE"
}

export enum ModalTypes {
    Add = 1,
    Edit = 2,
    Delete = 3
}

export enum EstablishmentDataType {
    RowXML = "raw-xml",
    RowData = "raw-data",
    Transform = "transform",
    TransformFinal = "transform-final"
}