import React, { useCallback, useEffect, useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  PaginationState
} from "@tanstack/react-table";
import { Stack, Table } from "react-bootstrap";
import { Pagination } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import Filter from "../filter";
import { useQuery } from "@tanstack/react-query";
import SearchBar from "../search";
import ForceRefreshFilter from "../filter/force-refresh-filter";
import { FilterTableCallFrom, SortOrder } from "../../app/enums";
import { useNavigate } from "react-router-dom";
import ExtractedDataFilter from "../filter/extracted-data-filter";
import EstablishmentListViewFilter from "../filter/establistment-listview-filter";

interface IPagedFilterTableProps {
  tableKey: string;
  columns: any;
  data: any;
  usePagination: boolean;
  useSearch: boolean;
  pageRecords: number;
  fnFetchData: Function;
  onRowSelect?: any;
  title?: any;
  fnRowClick?: Function;
  tableCallFrom?: FilterTableCallFrom;
  establishmentId?: any;
  useSorting?: boolean;
}

type ColumnSort = {
  id: string
  desc: boolean
}

type SortingState = ColumnSort[]

export default function PagedFilterDataTable(props: IPagedFilterTableProps) {
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState<string>();
  const [establishmentId, setEstablishmentId] = useState(null);
  const [includeChildren, setIncludeChildren] = useState(null);
  const [caseID, setCaseID] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [finishDate, setFinishDate] = useState(null);
  const [misType, setMisType] = useState(null);
  const [status, setStatus] = useState(null);
  const [extractStatus, setExtractStatus] = useState(0);
  const [year, setYear] = useState("");
  const [userType, setUserType] = useState(-1);
  const [clientId, setClientId] = useState(null);
  const [activeRowIndex, setActiveRowIndex] = useState<number>(-1);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState('');

  const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: props.pageRecords,
  });

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const fetchDataOptions = {
    startDate,
    finishDate,
    establishmentId,
    misType,
    status,
    caseID,
    year,
    userType,
    clientId,
    searchValue,
    extractStatus,
    includeChildren,
    sortBy,
    sortOrder,
    pageIndex,
    pageSize
  };

  const { isLoading, data } = useQuery([props.tableKey, fetchDataOptions], () =>
    props.fnFetchData(fetchDataOptions), {
    refetchOnWindowFocus: false
  }
  );

  const table = useReactTable({
    data: data ?? props.data,
    columns: props.columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    enableRowSelection: true,
    onPaginationChange: setPagination,
    state: {
      pagination
    },
    debugTable: true,
  });

  function callbackStartDate(startDate: any) {
    setStartDate(startDate);
  }
  function callbackFinishDate(finishDate: any) {
    setFinishDate(finishDate);
  }
  function callbackEstablishment(establishmentId: any, includeChildren: any) {
    setEstablishmentId(establishmentId);
    setIncludeChildren(includeChildren);
  }
  function callbackMisType(misTypeId: any) {
    setMisType(misTypeId);
  }
  function callbackStatus(statusId: any) {
    setStatus(statusId);
  }
  function callbackYear(year: any) {
    setYear(year);
  }
  function callbackUserType(userType: any) {
    setUserType(userType);
  }
  function callbackClientId(clientId: any) {
    setClientId(clientId);
  }

  function callbackCaseID(caseID: any) {
    setCaseID(caseID);
  }
  function callbackExtractStatus(statusId: any) {
    setExtractStatus(statusId);
  }
  const updateURL = useCallback(() => {
    const queryParams = new URLSearchParams();

    if (startDate) {
      queryParams.set('startDate', startDate);
    } else {
      queryParams.delete('startDate');
    }

    if (finishDate) {
      queryParams.set('finishDate', finishDate);
    } else {
      queryParams.delete('finishDate');
    }

    if (status) {
      queryParams.set('status', status);
    } else {
      queryParams.delete('status');
    }

    if (establishmentId) {
      queryParams.set('establishmentId', establishmentId);
    } else {
      queryParams.delete('establishmentId');
    }

    if (misType) {
      queryParams.set('mis', misType);
    } else {
      queryParams.delete('mis');
    }

    if (extractStatus) {
      queryParams.set('status', extractStatus.toString());
    } else {
      queryParams.delete('status');
    }

    if(caseID) {
      queryParams.set('caseID', caseID);
    } else {
      queryParams.delete('caseID');
    }

    navigate(`?${queryParams.toString()}`, { replace: true });
  }, [navigate, startDate, finishDate, misType, status, establishmentId, extractStatus, caseID]);

  const onHeaderClicked = (header: any) => {
    if (header.column.columnDef.enableSorting) {

      if (!(sorting.findIndex(obj => obj.id === header.column.id) > -1)) {
        if (sortOrder === SortOrder.ASC) {
          let sort = { id: header.column.id, desc: true } as ColumnSort;
          setSorting((prev) => [...prev, sort]);
          setSortOrder(SortOrder.DESC)
        } else if (sortOrder === SortOrder.DESC) {
          let sort = { id: header.column.id, desc: false } as ColumnSort;
          setSorting((prev) => [...prev, sort]);
          setSortOrder(SortOrder.ASC)
        } else {
          let sort = { id: header.column.id, desc: false } as ColumnSort;
          setSorting((prev) => [...prev, sort]);
          setSortOrder(SortOrder.ASC)
        }
      } else {
        let colums = sorting.find(col => col.id === header.column.id);
        if (colums?.desc === true) {
          setSorting((sortList) => sortList.filter((current) => current.id !== colums?.id));
          let sort = { id: header.column.id, desc: false } as ColumnSort;
          setSorting((prev) => [...prev, sort]);
          setSortOrder(SortOrder.ASC)
        } else if (colums?.desc === false) {
          setSorting((sortList) => sortList.filter((current) => current.id !== colums?.id));
          let sort = { id: header.column.id, desc: true } as ColumnSort;
          setSorting((prev) => [...prev, sort]);
          setSortOrder(SortOrder.DESC)
        }
      }
      setSortBy(header.column.id);
    }
  }

  const onRowClicked = (row: any) => {
    setActiveRowIndex(row.index);
    props.fnRowClick?.(row);
  }

  useEffect(() => {
    updateURL();
  }, [updateURL]);

  return (
    <React.Fragment>
      {
        props.tableCallFrom === FilterTableCallFrom.Healthboard &&
        <Stack direction="horizontal" gap={3} className="mb-3">
          <Filter
            callbackStartDate={callbackStartDate}
            callbackFinishDate={callbackFinishDate}
            callbackEstablishment={callbackEstablishment}
            callbackMisType={callbackMisType}
            callbackStatus={callbackStatus}
            callbackCaseID={callbackCaseID}
          />
        </Stack>
      }
      {
        (props.title
          || props.useSearch
          || props.tableCallFrom === FilterTableCallFrom.ForceRefresh
          || props.tableCallFrom === FilterTableCallFrom.ExtractedData) &&
        <div className={"w-100 pb-2 pt-2 d-flex justify-content-between " + (props.title ? "table-title border border-bottom-0" : "")}>
          <div>
          {props.tableCallFrom === FilterTableCallFrom.ExtractedData &&
            <ExtractedDataFilter establishmentId={props.establishmentId} callbackClientId={callbackClientId} />}
          </div>
          <div>{props.title}</div>
          {props.tableCallFrom === FilterTableCallFrom.Establishment && <EstablishmentListViewFilter callbackExtractStatus={callbackExtractStatus} />}
          <div className="d-flex align-items-center">
            {props.useSearch && <SearchBar placeholder="Search" onChange={(searchText: string) => setSearchValue(searchText)} />}
            {props.tableCallFrom === FilterTableCallFrom.ForceRefresh &&
              <ForceRefreshFilter establishmentId={props.establishmentId} callbackYear={callbackYear} callbackUserType={callbackUserType} />}
          </div>
        </div>
      }
      <Table responsive borderless>
        <thead className="sticky">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  style={{
                    width:
                      header.getSize()
                  }}
                >
                  {
                    props.useSorting ? <div style={{ width: header.getSize() ? header.getSize() + "%" : undefined, }}
                      onClick={() => props.useSorting ? onHeaderClicked(header) : () => false}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </div>

                      :
                      header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )

                  }
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {!isLoading && table.getRowModel().rows.map(row => (
            <tr key={row.id} className={activeRowIndex === row.index ? "selected" : ""} onClick={() => props.fnRowClick ? onRowClicked(row) : () => false}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}

          {isLoading && table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <td key={header.id}><Skeleton height={30} /></td>
              ))}
            </tr>
          ))}

          {table.getRowModel().rows.length === 0 && !isLoading && (
            <tr>
              <td colSpan={table.getHeaderGroups()[0].headers.length} className="text-center">
                No records found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {props.usePagination && !isLoading && (
        <Pagination className="float-end mt-2">
          <Pagination.Item
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Previous
          </Pagination.Item>
          <Pagination.Item
            onClick={() => table.nextPage()}
            disabled={table.getRowModel().rows.length < props.pageRecords}
          >
            Next
          </Pagination.Item>
        </Pagination>
      )}
    </React.Fragment>
  );
}
