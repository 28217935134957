import {GUID} from "../app/common/helper/Guid";
import BaseService from "./BaseService";

export default class UserService extends BaseService {
    controllerName = "user";

    async GetRoleByLoginId(loginId: GUID): Promise<any> {
        const resp = await this.Get(`${loginId}/roles`, {});
        return resp.data ? resp.data : resp;
    }

    async GetProfileByLoginId(loginId: GUID): Promise<any> {
        const resp = await this.Get(`${loginId}/profile`, {});
        return resp.data ? resp.data : resp;
    }

    async GetAll(
        page: number,
        pageSize: number,
        IncludeActive: boolean = true,
        IncludeInactive: boolean = true,
        IncludeRoles: boolean = false,
        Username: string = "",
        DisplayName: string = "",
        EmailAddress: string = ""
    ): Promise<any> {
        const resp = await this.Get("/", {
            IncludeActive: IncludeActive,
            IncludeInactive: IncludeInactive,
            IncludeRoles: IncludeRoles,
            page: page,
            pageSize: pageSize,
            Username: Username,
            DisplayName: DisplayName,
            EmailAddress: EmailAddress,
        });

        return resp ? resp.data : null;
    }
}
