import { useState } from "react";
import { faRefresh, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Dropdown, Stack } from "react-bootstrap";
import { PauseSyncDuration, Permissions, SyncType } from "../../../app/enums";
import { ServiceManager } from "../../../services/ServiceManager";
import { PERMISSION_REQUIRED, SOMETHING_WENT_WRONG } from "../../../app/constants";
import { HttpStatusCode } from "axios";
import { format } from "date-fns";
import CustomModal from "../../../components/modal";
import ForceRefresh from "./force-refresh";
import { usePermissionCheck } from "../../../app/common/helper/Permissions";
import MISTooltip from "../../../components/tooltip";

export default function SyncActions(props: any) {

    const [syncInProcess, setSyncInProcess] = useState(false)
    const [dateNextExecute, setDateNextExecute] = useState<Date | null>(null);
    const [showPauseSyncModal, setShowPauseSyncModal] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showForceRefreshModal, setShowForceRefreshModal] = useState(false);
    const [disableRunSync, setDisableRunSync] = useState(false);
    const canEditEstablishments = usePermissionCheck(Permissions.CanEditEstablishments);

    const handleClosePauseSyncModal = () => setShowPauseSyncModal(false);
    const handleOpenPauseSyncModal = () => setShowPauseSyncModal(true);
    const handleCloseConfirmationModal = () => setShowConfirmationModal(false);
    const handleCloseForceRefreshModal = () => setShowForceRefreshModal(false);

    async function onClickRunSync() {
        setSyncInProcess(true);
        await updateDateNextExecutes(SyncType.RunSync, new Date());
    }

    function onClickForceRefresh(){
        setShowForceRefreshModal(true);
    }

    async function onClickPauseSync() {
        setSyncInProcess(true);
        setShowConfirmationModal(false);
        setShowPauseSyncModal(false);
        await updateDateNextExecutes(SyncType.PauseSync, dateNextExecute);
    }

    async function updateDateNextExecutes(syncType: SyncType, nextDate: any) {
        if (nextDate) {
            const response = await ServiceManager.EstablishmentService.UpdateDateNextExecutes(props.establishmentId, nextDate.toISOString());
            if (response && response?.dateNextExecutes) {
                if (syncType === SyncType.RunSync) {
                    ServiceManager.ToastService.showSuccess("Run synced successfully");
                    setDisableRunSync(true);
                }
                else if (syncType === SyncType.PauseSync) {
                    ServiceManager.ToastService.showSuccess(`All syncs are currently paused for this establishment until 
                        ${format(new Date(response.dateNextExecutes), "dd/MM/yyyy hh:mm a")}. To begin syncing again, press the 'Run Sync' button.`);
                }
                setDateNextExecute(null);
            }
            else if (response && response?.response && response.response?.status === HttpStatusCode.NotFound) {
                ServiceManager.ToastService.showError(response.response?.data ?? "The establishment does not have active tasks");
            }
            else {
                ServiceManager.ToastService.showError(SOMETHING_WENT_WRONG);
            }
        }
        else {
            ServiceManager.ToastService.showError("The next execution date cannot be null");
        }
        setSyncInProcess(false);
    }

    function selectPauseSync(duration: any) {
        const currentDate = new Date();
        const pauseDate = new Date(currentDate);
        switch (duration) {
            case PauseSyncDuration.Hours4:
                pauseDate.setHours(currentDate.getHours() + 4);
                break;
            case PauseSyncDuration.Hours12:
                pauseDate.setHours(currentDate.getHours() + 12);
                break;
            case PauseSyncDuration.Hours24:
                pauseDate.setHours(currentDate.getHours() + 24);
                break;
            case PauseSyncDuration.Week1:
                pauseDate.setDate(currentDate.getDate() + 7);
                break;
            case PauseSyncDuration.Week2:
                pauseDate.setDate(currentDate.getDate() + 14);
                break;
            case PauseSyncDuration.Month1:
                pauseDate.setMonth(currentDate.getMonth() + 1);
                break;
            default:
                ServiceManager.ToastService.showError('Invalid pause duration');
                return;
        }
        setDateNextExecute(pauseDate);
        setShowConfirmationModal(true);
    }

    const PauseSyncDropDown = () => {
        return (
            <Stack>
                <Dropdown onSelect={selectPauseSync}>
                    <Dropdown.Toggle className="btn btn-secondary crbc-bg-color w-100 d-flex justify-content-between align-items-center">
                        Duration
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="timer-dropdown">
                        <Dropdown.Item eventKey={PauseSyncDuration.Hours4}>4 hours</Dropdown.Item>
                        <Dropdown.Item eventKey={PauseSyncDuration.Hours12}>12 hours</Dropdown.Item>
                        <Dropdown.Item eventKey={PauseSyncDuration.Hours24}>24 hours</Dropdown.Item>
                        <Dropdown.Item eventKey={PauseSyncDuration.Week1}>1 week</Dropdown.Item>
                        <Dropdown.Item eventKey={PauseSyncDuration.Week2}>2 weeks</Dropdown.Item>
                        <Dropdown.Item eventKey={PauseSyncDuration.Month1}>1 month</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Stack>
        );
    }

    const PauseSyncConfirmationModal = () => {
        return (
            <Stack>
                <h5 className="text-center">Pause Sync</h5>
                <p className="text-center">Are you sure you want to pause the syncs for this establishment?</p>
                <Stack direction="horizontal" className="mt-3">
                    <Button variant="popup-btn right-margin10 btn-outline-secondary w-50" onClick={handleCloseConfirmationModal}>
                        Cancel
                    </Button>
                    <Button variant="popup-btn right-margin10 btn-danger w-50" onClick={onClickPauseSync}>
                        PAUSE
                    </Button>
                </Stack>
            </Stack>
        )
    }

    return (
        <>
            {
                syncInProcess
                ?
                <Button className="btn btn-secondary grey sync-button">
                    <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                </Button>
                :
                <>
                    {
                        canEditEstablishments ?
                        <Dropdown>
                            <Dropdown.Toggle className="btn btn-secondary sync-button d-flex justify-content-between align-items-center">
                                <FontAwesomeIcon icon={faRefresh} />
                                <span>Sync Data</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="timer-dropdown" >
                                <div className={'border-bottom2 ' + (disableRunSync ? 'cursor-no-drop' : '')} title="Manually run a full sync to update changed data for this establishment. Can only be used once every hour">
                                    <Dropdown.Item disabled={disableRunSync} onClick={onClickRunSync} >Run Extract Sync</Dropdown.Item>
                                </div>
                                <div className='border-bottom2'><Dropdown.Item onClick={onClickForceRefresh}>Force Refresh</Dropdown.Item></div>
                                <Dropdown.Item onClick={handleOpenPauseSyncModal} title="Pause all extracts for this Establishment">Pause Extract Sync</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        :
                        <MISTooltip message={PERMISSION_REQUIRED} placement="bottom">
                            <div className="disabled">
                                <Dropdown>
                                    <Dropdown.Toggle className="btn btn-secondary sync-button d-flex justify-content-between align-items-center" disabled>
                                        <FontAwesomeIcon icon={faRefresh} />
                                        <span>Sync Data</span>
                                    </Dropdown.Toggle>
                                </Dropdown>
                            </div>
                        </MISTooltip>
                    }
                </>
            }

            <CustomModal isShow={showPauseSyncModal} handleClose={handleClosePauseSyncModal} 
                    header={<Stack><p className="heading">Pause Extract Sync</p><p className="text-muted">Select pause duration.</p></Stack>} size="sm">
                <PauseSyncDropDown />
            </CustomModal>
            <CustomModal isShow={showConfirmationModal} handleClose={handleCloseConfirmationModal} size="sm">
                <PauseSyncConfirmationModal />
            </CustomModal>
            <CustomModal isShow={showForceRefreshModal} handleClose={handleCloseForceRefreshModal} isShowHeader={false} size="xl">
                <ForceRefresh establishmentId={props.establishmentId} handleClose={handleCloseForceRefreshModal}/>
            </CustomModal>
        </>
    );
}