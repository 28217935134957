import { IClientConfiguration } from "../models/ExtractClient";
import ISimsUserType from "../models/SimsUserType";
import ISimsUserTypeFieldMappings from "../models/SimsUserTypeFieldMappings";
import BaseService from "./BaseService";

export default class ConfigurationService extends BaseService {
    controllerName = "establishment";

    async GetExtractClients(establishmentId: any): Promise<any> {
        const resp = await this.Get(`${establishmentId}/extract-clients`, {});
        return resp ? resp.data ?? [] : null;
    }

    async DeleteSimsConfigurations(establishmentId : any, simsConfigurationId: number): Promise<any> {
        const resp = await this.Delete(`/${establishmentId}/sims/${simsConfigurationId}`);
        return resp;
    }

    async CreateSimsConfigurations(establishmentId: any, model: IClientConfiguration): Promise<any> {
        const resp = await this.Post(`/${establishmentId}/sims`, {
            password: model.password,
            simsPath: model.simsPath,
            username: model.username
        });
        return resp;
    }

    async UpdateSimsConfigurations(establishmentId: any, simsConfigurationId: any, model: IClientConfiguration): Promise<any> {
        const resp = await this.Put(`/${establishmentId}/sims/${simsConfigurationId}`, {
            password: model.password,
            simsPath: model.simsPath,
            username: model.username
        });
        return resp;
    }

    async GetSimsConfigurationsById(establishmentId: any, simsConfigurationID: any): Promise<any> {
        const resp = await this.Get(`${establishmentId}/sims/${simsConfigurationID}`, {});
        return resp ? resp.data : null;
    }

    async ResetClientSecret(establishmentId: any, clientId: any){
        const resp = await this.Patch(`${establishmentId}/client/${clientId}/reset`, {});
        return resp;
    }

    async GetSimsUserTypes(establishmentId: any, simsConfigurationId: any): Promise<any> {
        const resp = await this.Get(`${establishmentId}/sims/${simsConfigurationId}/usertypes`, {});
        return resp ? resp.data ?? [] : null;
    }

    async DeleteSimsUserType(establishmentId: any, simsConfigurationId: any, userTypeId: any): Promise<any> {
        const resp = await this.Delete(`${establishmentId}/sims/${simsConfigurationId}/usertypes/${userTypeId}`);
        return resp.data ? resp.data : resp;
    }

    async CreateSimsUserType(establishmentId: any, simsConfigurationId: any, model: ISimsUserType): Promise<any> {
        const resp = await this.Post(`/${establishmentId}/sims/${simsConfigurationId}/usertypes`, {
            userTypeID: model.userTypeID,
            isApplicant: model.isApplicant,
            mappingTemplate: Number(model.mappingTemplate),
            photoReportName: model.photoReportName,
            reportName: model.reportName
        });
        return resp;
    }

    async GetSimsUserType(establishmentId: any, simsConfigurationId: any, userTypeId: any): Promise<any> {
        const resp = await this.Get(`${establishmentId}/sims/${simsConfigurationId}/usertypes/${userTypeId}`, {});
        return resp ? resp.data ?? [] : null;
    }

    async UpdateSimsUserType(establishmentId: any, simsConfigurationId: any, userTypeId: any, model: ISimsUserType): Promise<any> {
        const resp = await this.Put(`/${establishmentId}/sims/${simsConfigurationId}/usertypes/${userTypeId}`, {
            userTypeID: model.userTypeID,
            isApplicant: model.isApplicant,
            mappingTemplate: Number(model.mappingTemplate),
            photoReportName: model.photoReportName,
            reportName: model.reportName
        });
        return resp;
    }

    async GetSimsUserTypeFieldMappings(establishmentId: any, simsConfigurationId: any): Promise<any> {
        const resp = await this.Get(`${establishmentId}/sims/${simsConfigurationId}/fieldmappings`, {});
        return resp ? resp.data ?? [] : null;
    }

    async UpdateSimsUserTypeFieldMappings(establishmentId: any, simsConfigurationId: any, model: ISimsUserTypeFieldMappings): Promise<any> {
        const resp = await this.Post(`/${establishmentId}/sims/${simsConfigurationId}/fieldmappings`, model);
        return resp;
    }

}