import { Container, Row, Col, Navbar } from "react-bootstrap";
import Sidebar from "../components/sidebar";
import AuthenticatedSideBarItems from "../components/sidebar/authenticated-sidebar-Items";
import CRBCLogo from "../components/logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from '@fortawesome/free-solid-svg-icons';

interface ILayoutProps{
    title: string,
    subTitle?: string,
    button?: JSX.Element,
    children: any,
    backArrow?: JSX.Element,
    hideBorderBottom?: boolean
}

export default function Layout(props: ILayoutProps) {
    let isAuthenticated = true;
    return (
        <main>
            <Sidebar />
            <div className="contentPane container-bg">
                <div className="mobile-nav d-md-block d-sm-block d-xd-block d-lg-none d-xl-none">
                    <Navbar bg="dark" expand="lg" variant="dark">
                        <Navbar.Brand href="/" className="d-flex"> <CRBCLogo/> <span className="ms-3 logo-text">MIS Central</span></Navbar.Brand>
                        <Navbar.Toggle aria-controls="mobile-navbar-nav"><FontAwesomeIcon icon={faBars} className="crbc-color" /></Navbar.Toggle>
                        <Navbar.Collapse className="mt-2" id="mobile-navbar-nav">
                            <AuthenticatedSideBarItems
                                isAuthenticated={true}
                                logout={null}
                            />
                        </Navbar.Collapse>
                    </Navbar>
                </div>
                <Container fluid>
                    <Row className="mt-1 px-3">
                        <Col md={12} sm={12} xs={12}>
                            {isAuthenticated ? (
                                <Row>
                                    <Col md={12} sm={12} xs={12} className="pt-2">
                                        <h4 className='float-start mt-2'>
                                            {props.backArrow ? (props.backArrow) : null}
                                            {props.title}
                                        </h4>

                                        <div className='float-end mt-2'>
                                            {props.button}
                                        </div>

                                        {props.subTitle ? <h6 className="clear pt-3">{props.subTitle}</h6> : null}

                                        {(props.hideBorderBottom === false || props.hideBorderBottom === undefined)  ? <div className="clear border-bottom pt-2 mb-2"></div> : null}
                                    </Col>
                                    <Col md={12} sm={12} xs={12} className="mt-1">
                                        {props.children}
                                    </Col>
                                </Row>
                            ) : (
                                null
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        </main>
    );
}