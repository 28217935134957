
import { useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { NavLink } from "react-router-dom";
import { ServiceManager } from "../../services/ServiceManager";
import { format } from 'date-fns'
import { FilterTableCallFrom, MisServiceType, MisType, ModalTypes, Permissions, QueueStatus, QueueStatusStyles, SortOrder, TaskType } from "../../app/enums";
import { Button, Stack } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faPen, faSortAsc, faSortDesc, faTrashAlt, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import ITaskLog from "../../models/TaskLog";
import CustomModal from "../../components/modal";
import PagedFilterDataTable from "../../components/table/filter-table";
import { usePermissionCheck } from "../../app/common/helper/Permissions";
import SalesForceModal from "./modal/salesforce-modal";
import { useQueryClient } from "@tanstack/react-query";
import IconElement from "../../components/icon";

export default function ClientExtracts() {
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showSalesForceModal, setShowSalesForceModal] = useState(false);
  const [taskId, setTaskId] = useState(0);
  const [messageText, setmessageText] = useState("");
  const [dateStartOrder, setDateStartOrder] = useState(SortOrder.DESC);
  const [dateCompletedOrder, setDateCompletedOrder] = useState(SortOrder.NONE);
  const [modalType, setModalType] = useState(ModalTypes.Add);
  const [salesForceParams, setSalesForceParams] = useState({ salesforceCaseId: '', salesforceCaseUrl: '' });
  const queryClient = useQueryClient();

  const canViewEstablishments = usePermissionCheck(Permissions.CanViewEstablishments);

  const columnHelper = createColumnHelper<ITaskLog>();
  const columns = [
    columnHelper.accessor('establishmentName', {
      header: () => <span>Estab Name</span>,
      cell: ({ row }) => (
        <div className="establishment-text">
          {
            canViewEstablishments
              ?
              <NavLink to={`/establishment/${row.original.establishmentID}`} state={{ "establishmentName": row.original.establishmentName }}>
                {row.original.establishmentName}
              </NavLink>
              :
              <span>{row.original.establishmentName}</span>
          }
        </div>
      ),
    }),
    columnHelper.accessor('parentEstablishmentName', {
      header: () => <span>Parent</span>,
      cell: ({ row }) => (
        <div className="establishment-text">
          {
            canViewEstablishments
              ?
              <NavLink to={`/establishment/${row.original.parentEstablishmentID}`} state={{ "establishmentName": row.original.parentEstablishmentName }}>
                {row.original.parentEstablishmentName}
              </NavLink>
              :
              <span>{row.original.parentEstablishmentName}</span>
          }
        </div>
      ),
    }),
    columnHelper.accessor('salesforceCaseID', {
      header: () => <span>Case ID</span>,
      cell: ({ row }) => (
        <SalesForceCase data={row.original} />      
      ),
    }),
    columnHelper.accessor('taskID', {
      header: () => <span>Task ID</span>,
      cell: ({ row }) => (
        <>
          {
            canViewEstablishments
              ?
              <NavLink to={`/establishment/${row.original.establishmentID}/tasks/${row.original.taskID}`}>
                {row.original.taskID}
              </NavLink>
              :
              <span>{row.original.taskID}</span>
          }
        </>
      ),
    }),
    columnHelper.accessor('taskType', {
      header: () => <span>Task Name</span>,
      cell: ({ row }) => (
        <div className="short-text-columns">
          <span>{TaskType[row.original.taskType]?.toUpperCase()}</span>
        </div>
      ),
    }),
    columnHelper.accessor('misType', {
      header: () => <span>MIS</span>,
      cell: ({ row }) => (
        <div className="short-text-columns">
          <span>{MisType[row.original.misType]?.toUpperCase()}</span>
        </div>

      ),
    }),
    columnHelper.accessor('dateStart', {
      header: (header) => <div className="cursor-pointer" style={{ display: "table" }}  >Started <span id={header.column.id} onClick={() => onDateStartClick()} style={{ display: "table-cell", verticalAlign: "middle", paddingLeft: "5px" }} ><FontAwesomeIcon icon={dateStartOrder === SortOrder.DESC ? faSortDesc : faSortAsc} className={dateStartOrder === SortOrder.DESC ? "mb-1 cursor-pointer" : "mt-1 cursor-pointer"} /></span></div>,
      id: "dateStart",
      sortDescFirst: true,
      enableSorting: true,
      cell: ({ row }) => (
        <Stack>
          <span className="font-dark">{row.original.dateStart ? format(new Date(row.original.dateStart), "p") : ''}</span>
          <span>{row.original.dateStart ? format(new Date(row.original.dateStart), "dd-MM-yyyy") : ''}</span>
        </Stack>
      ),
    }),
    columnHelper.accessor('dateFinish', {
      header: (header) => <div className="cursor-pointer" style={{ display: "table" }}  >Completed <span id={header.column.id} onClick={() => onDateCompletedClick()} style={{ display: "table-cell", verticalAlign: "middle", paddingLeft: "5px" }} ><FontAwesomeIcon icon={dateCompletedOrder === SortOrder.DESC ? faSortDesc : faSortAsc} className={dateCompletedOrder === SortOrder.NONE ? "light-gray-color mt-1 cursor-pointer" : dateCompletedOrder === SortOrder.DESC ? "mb-1 cursor-pointer" : "mt-1 cursor-pointer"} /></span></div>,
      id: "dateCompleted",
      sortDescFirst: true,
      enableSorting: true,
      cell: ({ row }) => (
        <Stack>
          <span className="font-dark">{row.original.dateFinish ? format(new Date(row.original.dateFinish), "p") : ''}</span>
          <span>{row.original.dateFinish ? format(new Date(row.original.dateFinish), "dd-MM-yyyy") : ''}</span>
        </Stack>
      ),
    }),
    columnHelper.accessor('status', {
      header: () => <span>Status</span>,
      cell: ({ row }) => (
        <div className={`p-1 ps-2 pe-2 ${QueueStatusStyles[row.original.status]}`}>
          <FontAwesomeIcon icon={faCircle} size="2xs" className="icon" />
          <span className="ms-2">{row.original.status === QueueStatus.NotExecuted ? "Not Executed " : QueueStatus[row.original.status]}</span>
        </div>
      ),
    }),
    columnHelper.accessor('message', {
      header: () => <span>Message</span>,
      size: 12,
      cell: ({ row }) => (
        <div className="message-text">
          <div >{row?.original?.message?.substring(0, 38)}
            {row?.original?.message?.length as number > 40 ?
              <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => {
                onRowClick(row);
                setShowMessageModal(true);
              }} >... See More</span>
              : null}</div>

        </div>
      ),
    })
  ];

  function onRowClick(row: any) {
    setmessageText(row.original.message);
  }

  function toggleSortOrder(order: any, setOrder: any) {
    setOrder(order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC);
  }

  function onDateStartClick() {
    toggleSortOrder(dateStartOrder, setDateStartOrder);
  }

  function onDateCompletedClick() {
    toggleSortOrder(dateCompletedOrder, setDateCompletedOrder);
  }

  async function fetchData(options: any) {
    if (options.startDate === '' || options.finishDate === '') {
      return null;
    }
    const extractResponse = await ServiceManager.TaskService.GetLogs(options.startDate, options.finishDate, options.establishmentId,
      options.misType, options.status, options.caseID, options.includeChildren, options.sortBy, options.sortOrder, options.pageIndex + 1, options.pageSize);
      if (options.sortBy === "dateStart") {
        setDateStartOrder(options.sortOrder);
      } else if (options.sortBy === "dateCompleted") {
        setDateCompletedOrder(options.sortOrder);
      }
      return extractResponse;
  }

  const getClickableLink = (link : string) => {
    return link.startsWith("http://") || link.startsWith("https://") ?
      link
      : `//${link}`;
  }

  const MessageListHeader = () => {
    return (
      <Stack>
        <p className="heading">Message</p>
      </Stack>
    )
  }
  const Message = () => {
    return (
      <Stack>
        <div className="messagebox-border p-2 mw-100 messagebox-height">
          <pre className="text-wrap-next-line">
            {messageText}
          </pre>
        </div>
        <Button variant="popup-btn btn btn-primary crbc-bg-color w-100 mt-3" type="submit" onClick={() => {
          setShowMessageModal(false);
        }}>
          Close
        </Button>
      </Stack>
    )
  }  

  const SalesForceCase = (row: any) => {
    return (
      <>
        {row.data.salesforceCaseID &&
          <>
            <div className="btn icon-btn" onClick={() => {
              setShowSalesForceModal(true); setTaskId(row.data.taskID); setModalType(ModalTypes.Edit);
              setSalesForceParams({ salesforceCaseId: row.data.salesforceCaseID, salesforceCaseUrl: row.data.salesforceCaseUrl })
            }}>
              <FontAwesomeIcon icon={faPen} size="sm" className="icon" />
            </div>
            <div className="btn icon-btn" onClick={() => {
              setShowSalesForceModal(true); setTaskId(row.data.taskID); setModalType(ModalTypes.Delete);
              setSalesForceParams({ salesforceCaseId: row.data.salesforceCaseID, salesforceCaseUrl: row.data.salesforceCaseUrl })
            }}>
              <FontAwesomeIcon icon={faTrashCan} size="sm" className="icon" />
            </div>
          </>
        }
        {row.data.salesforceCaseID && row.data.salesforceCaseUrl
          ? <a style={{ textDecoration: "underline" }} href={getClickableLink(row.data.salesforceCaseUrl)} target="_blank" rel="noopener noreferrer" >{row.data.salesforceCaseID}</a>
          : row.data.salesforceCaseID
            ? <span>{row.data.salesforceCaseID}</span>
            : <div className="btn icon-btn" onClick={() => { setShowSalesForceModal(true); setTaskId(row.data.taskID); setModalType(ModalTypes.Add) }}> <span className="font-14">+ Add</span> </div>
        }
      </>
    );
  }

  const callBackSalesForceModal = (isRefresh: any) => {
    if (isRefresh) {
      queryClient.invalidateQueries({ queryKey: ["client-extracts"] });
    }
    setShowSalesForceModal(false);
    setSalesForceParams({ salesforceCaseId: '', salesforceCaseUrl: '' });
  }

  const SalesForceModalHeader = ({ modalType }: { modalType: ModalTypes }) => {
    if (modalType === ModalTypes.Delete) {
      return (<IconElement iconType={faTrashAlt} headerClass="error-icon ms-6" color="#D92D20" />);
    }
    else {
      return (<p className="heading">{modalType === ModalTypes.Add ? "Add Case ID" : "Edit Case ID"}</p>);
    }
  }

  return (
    <>
      <div id="healthboardTable-transform">
        <PagedFilterDataTable
          tableKey={"client-extracts"}
          columns={columns}
          data={[]}
          usePagination={true}
          pageRecords={10}
          fnFetchData={fetchData}
          useSearch={false}
          tableCallFrom={FilterTableCallFrom.Healthboard}
          useSorting={true}
        />
      </div>
      <CustomModal customClass={'text-overflow-control'} isShow={showMessageModal} handleClose={() => { setShowMessageModal(false) }} header={<MessageListHeader />} size="lg">
        <Message />
      </CustomModal>
      <CustomModal isShow={showSalesForceModal} handleClose={() => { callBackSalesForceModal(false) }} header={<SalesForceModalHeader modalType={modalType} />} size="md">
        <SalesForceModal healthboardType={MisServiceType.Task} healthboardId={taskId} modalType={modalType} salesForceParams={salesForceParams} callBack={callBackSalesForceModal} />
      </CustomModal>
    </>
  );
};