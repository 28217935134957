import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Layout from "../../../layout";
import { faAdd, faArrowLeft, faTrashAlt, faEye } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import PagedFilterTable from "../../../../components/table";
import { createColumnHelper } from "@tanstack/react-table";
import { useState } from "react";
import { ServiceManager } from "../../../../services/ServiceManager";
import ITask from "../../../../models/Tasks";
import MISButton from "../../../../components/button";
import { Spinner, Stack } from "react-bootstrap";
import { format } from "date-fns";
import CustomModal from "../../../../components/modal";
import IconElement from "../../../../components/icon";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { APIStatusCode, MisType, Permissions, TaskType } from "../../../../app/enums";
import { usePermissionCheck } from "../../../../app/common/helper/Permissions";
import MISTooltip from "../../../../components/tooltip";
import { PERMISSION_REQUIRED } from "../../../../app/constants";

export default function Tasks(props: any) {

    const [tableJobs, setTableJobs] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [taskId, setTaskId] = useState();    
    const canEditEstablishments = usePermissionCheck(Permissions.CanEditEstablishments);

    const handleCloseDeleteModal = () => setShowDeleteModal(false);

    let navigate = useNavigate();
    const { establishmentId } = useParams();

    const columnHelper = createColumnHelper<ITask>();
    const columns = [
        columnHelper.accessor('taskID', {
            header: () => <span>Action</span>,
            size: 10,
            cell: ({ row }) => (

                <div className="d-flex flex-row">
                    <FontAwesomeIcon
                        className="cursor-pointer me-3"
                        icon={faEye}
                        size="lg"
                        onClick={() => {
                            routeChange(`/establishment/${establishmentId}/tasks/${row.original.taskID}`);
                        }}
                    />

                    {
                        canEditEstablishments ?
                            <FontAwesomeIcon
                                className="cursor-pointer"
                                icon={faTrashAlt}
                                size="lg"
                                onClick={() => {
                                    processDelete(row.original.taskID);
                                }}
                            />
                            :
                            <MISTooltip message={PERMISSION_REQUIRED} placement="top">
                                <FontAwesomeIcon
                                    icon={faTrashAlt}
                                    size="lg"
                                    className="disabled"
                                />
                            </MISTooltip>
                    }
                </div>
            ),
        }),
        columnHelper.accessor('schedule', {
            header: () => <span>Schedule</span>,
            size: 10,
            cell: ({ row }) => (
                <div className="schedule-text">
                    <div >{row?.original?.schedule?.substring(0, 48)}
                        {row?.original?.schedule?.length as number > 50 ?
                            <span onClick={() => {
                            }} >...</span>
                            : null}</div>
                </div>
            ),
        }),
        columnHelper.accessor('misType', {
            header: () => <span>MIS</span>,
            cell: ({ row }) => (
                <div className="short-text-columns">
                    <span>{MisType[row.original.misType]?.toUpperCase()}</span>
                </div>
            ),
            size: 10
        }),
        columnHelper.accessor('priority', {
            header: () => <span>Priority</span>,
            size: 10,
            cell: ({ row }) => (
                <div className="short-header">
                    <span>{row.original.priority}</span>
                </div>
            ),
        }),
        columnHelper.accessor('taskType', {
            header: () => <span>Task Type</span>,
            size: 10,
            cell: ({ row }) => (
                <div className="short-text-columns">
                    <span>{TaskType[Number(row.original.taskType)]?.toUpperCase()}</span>
                </div>
            ),
        }),
        columnHelper.accessor('enabled', {
            header: () => <span>Enabled</span>,
            size: 10,
            cell: ({ row }) => (
                <div title={row.original.enabled.toString()}>
                    <span>{row.original.enabled ? 'Yes' : 'No'}</span>
                </div>
            ),
        }),
        columnHelper.accessor('dateNextExecutes', {
            header: () => <span>Next Executes</span>,
            size: 10,
            cell: ({ row }) => (
                <Stack className="date-column">
                    <span className="font-dark">{row.original.dateNextExecutes ? format(new Date(row.original.dateNextExecutes), "p") : ''}</span>
                    <span>{row.original.dateNextExecutes ? format(new Date(row.original.dateNextExecutes), "dd-MM-yyyy") : ''}</span>
                </Stack>
            ),
        }),
        columnHelper.accessor('dateLastExecuted', {
            header: () => <span>Last Executed</span>,
            size: 10,
            cell: ({ row }) => (
                <Stack className="date-column" >
                    <span className="font-dark">{row.original.dateLastExecuted ? format(new Date(row.original.dateLastExecuted), "p") : ''}</span>
                    <span>{row.original.dateLastExecuted ? format(new Date(row.original.dateLastExecuted), "dd-MM-yyyy") : ''}</span>
                </Stack>
            ),
        }),
        columnHelper.accessor('dateStarted', {
            header: () => <span>Started</span>,
            size: 10,
            cell: ({ row }) => (
                <Stack className="date-column" >
                    <span className="font-dark">{row.original.dateStarted ? format(new Date(row.original.dateStarted), "p") : ''}</span>
                    <span>{row.original.dateStarted ? format(new Date(row.original.dateStarted), "dd-MM-yyyy") : ''}</span>
                </Stack>
            ),
        }),
        columnHelper.accessor('dateCreated', {
            header: () => <span>Created</span>,
            size: 10,
            cell: ({ row }) => (
                <Stack className="date-column">
                    <span className="font-dark">{row.original.dateCreated ? format(new Date(row.original.dateCreated), "p") : ''}</span>
                    <span>{row.original.dateCreated ? format(new Date(row.original.dateCreated), "dd-MM-yyyy") : ''}</span>
                </Stack>
            ),
        }),
        columnHelper.accessor('dateModified', {
            header: () => <span>Modified</span>,
            size: 10,
            cell: ({ row }) => (
                <Stack className="date-column">
                    <span className="font-dark">{row.original.dateModified ? format(new Date(row.original.dateModified), "p") : ''}</span>
                    <span>{row.original.dateModified ? format(new Date(row.original.dateModified), "dd-MM-yyyy") : ''}</span>
                </Stack>
            ),
        })

    ];
    const routeChange = (path: any) => {
        navigate(path);
    }

    async function fetchData(options: {
        pageIndex: number;
        pageSize: number;
        searchValue: string
    }) {
        const establishmentResponse = await ServiceManager.EstablishmentService.GetAllTasks(establishmentId);
        setTableJobs(establishmentResponse);
        return establishmentResponse;
    }

    const processDelete = (taskId: any) => {
        setTaskId(taskId);
        setShowDeleteModal(true);
    }

    const DeleteTaskModal = () => {
        const queryClient = useQueryClient();
        const mutation = useMutation({
            mutationFn: () => {
                return ServiceManager.EstablishmentService.deleteTask(establishmentId, taskId);
            },
            onError: (error, variables, context) => {

            },
            onSuccess: (data, variables, context) => {
                if (data === null) {
                    ServiceManager.ToastService.showError("Unable to delete task.");
                }
                else {

                    if (data.status === APIStatusCode.NoContent) {
                        ServiceManager.ToastService.showSuccess("Task deleted successfully.");
                        queryClient.invalidateQueries({ queryKey: ["user-types"] });
                        handleCloseDeleteModal();
                    }
                    else {
                        let errorMessage = (data.response && data.response.data && data.response.data.errors.errors) ? data.response.data.errors.errors : data.message;
                        ServiceManager.ToastService.showError(errorMessage.toString());
                    }
                }
            }
        });
        return (
            <Stack>
                <p className="heading text-center mt-2">Delete Task</p>
                <p className="text-center">Are you sure? This means that Extract Schedules and Subtask information within this Task will be removed. This action cannot be undone.</p>
                {
                    !mutation.isLoading
                        ? (<Stack direction="horizontal" className="mt-3">
                            <MISButton text="Cancel" className="popup-btn right-margin10 btn btn-outline-secondary w-50" FnOnClick={handleCloseDeleteModal} />
                            <MISButton text="Delete" className="popup-btn right-margin10 btn btn-danger w-50" FnOnClick={() => { mutation.mutate(); }} />
                        </Stack>
                        ) : (
                            <div className="d-flex justify-content-center mt-3">
                                <Spinner
                                    animation="border"
                                    variant="info"
                                    role="status"
                                >
                                </Spinner>
                            </div>
                        )
                }
            </Stack>
        )
    }

    return (
        <Layout title="Tasks"
            backArrow={
                <FontAwesomeIcon icon={faArrowLeft} onClick={() => navigate(-1)} className="me-4 fs-5 cursor-pointer" />
            }
            button={
                canEditEstablishments ?
                    <MISButton text="Add Task" className="btn btn-primary crbc-bg-color" FnOnClick={() => navigate('new')} icon={<FontAwesomeIcon icon={faAdd} />}></MISButton>
                    :
                    <MISTooltip message={PERMISSION_REQUIRED} placement="bottom">
                        <div className="disabled">
                            <MISButton text="Add Task" className="btn btn-secondary" FnOnClick={() => null} disabled icon={<FontAwesomeIcon icon={faAdd} />}></MISButton>
                        </div>
                    </MISTooltip>
            }
        >
            <div id="userTypesTable">
                <PagedFilterTable
                    tableKey={"user-types"}
                    columns={columns}
                    data={tableJobs}
                    usePagination={false}
                    useSearch={false}
                    pageRecords={10}
                    fnFetchData={fetchData}
                />
            </div>
            
            <CustomModal isShow={showDeleteModal} handleClose={handleCloseDeleteModal} header={<IconElement iconType={faTrashAlt} headerClass="error-icon ms-6" color="#D92D20" />} size="md">
                <DeleteTaskModal />
            </CustomModal>
        </Layout>
    );
}