import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams } from "react-router-dom";
import { faArrowLeft, faPlus, faQuestionCircle, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Layout from "../../../layout";
import { Col, Container, Dropdown, Row, Spinner, Stack } from "react-bootstrap";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ServiceManager } from "../../../../services/ServiceManager";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { APIStatusCode, MisType } from "../../../../app/enums";
import MISButton from "../../../../components/button";
import Skeleton from "react-loading-skeleton";
import ITask, { ITaskRequest } from "../../../../models/Tasks";
import { ErrorMessage } from "@hookform/error-message";
import { useEffect, useState } from "react";
import * as cronstrue from 'cronstrue';

export default function CreateUpdateTask() {

    const [showNeedHelp, setShowNeedHelp] = useState(false);
    let navigate = useNavigate();
    const { establishmentId, taskId } = useParams();

    const firstCol = 2, secondCol = 4;
    const defaultCron = "30 6,9 * * MON-FRI";

    const { isLoading: isTasksLoading, data: taskData } = useQuery([`tasks-${taskId ?? 0}`], () => {
        if (taskId) {
            return ServiceManager.EstablishmentService.GetTaskById(establishmentId, taskId);
        }
        const defaultTask: ITask = {
            enabled: false,
            misType: 1,
            priority: 0,
            taskType: '1',
            schedule: '',
            dateCreated: null,
            dateLastExecuted: null,
            dateModified: null,
            dateNextExecutes: null,
            dateStarted: null,
            taskID: null,
            taskSettings: ''
        };
        //by default set MIS Type to SIMS
        handleSelect(defaultTask.misType);
        return Promise.resolve(defaultTask);
    },
        { refetchOnWindowFocus: false, cacheTime: 0 }
    );

    const handleSelect = (eventKey: any) => {
        setValue('misType', eventKey);
        clearErrors('misType');
    }

    const { register, handleSubmit, clearErrors, setValue, formState: { errors }, control } = useForm<ITaskRequest>({
        defaultValues: {
            schedule: [{ cronExpression: defaultCron }]
        },
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'schedule',
    });

    const handleRemove = (index: any) => {
        remove(index);
        if (fields.length === 1) {
            append({ cronExpression: defaultCron })
        }
    };

    const onSubmit: SubmitHandler<ITaskRequest> = (data) => {
        data.taskType = 1;
        data.schedule = data?.schedule.map((item) => item.cronExpression);
        data.misType = Number(data.misType);
        data.priority = Number(data.priority);
        if (taskId) {
            updatedMutation.mutate(data);
        }
        else {
            createMutation.mutate(data);
        }
    };

    const createMutation = useMutation({
        mutationFn: (model: ITaskRequest) => {
            return ServiceManager.EstablishmentService.createTask(establishmentId, model);
        },
        onError: (error, variables, context) => {

        },
        onSuccess: (data, variables, context) => {
            if (data === null) {
                ServiceManager.ToastService.showError("Unable to add task.");
            }
            else {
                if (data.status === APIStatusCode.Created) {
                    ServiceManager.ToastService.showSuccess("Task created successfully.");
                    navigate(-1);
                }
                else {
                    const errors = data?.response?.data?.errors;
                    if (errors) {
                        Object.keys(errors).forEach(field => {
                            ServiceManager.ToastService.showError(`${field}: ${errors[field][0]}`);
                        });
                    }
                    else {
                        ServiceManager.ToastService.showError(data?.message);
                    }
                }
            }
        }
    });

    const updatedMutation = useMutation({
        mutationFn: (model: ITaskRequest) => {
            return ServiceManager.EstablishmentService.updateTask(establishmentId, taskId, model);
        },
        onError: (error, variables, context) => {

        },
        onSuccess: (data, variables, context) => {
            if (data === null) {
                ServiceManager.ToastService.showError("Unable to update task.");
            }
            else {
                if (data.status === APIStatusCode.Ok) {
                    ServiceManager.ToastService.showSuccess("Task updated successfully.");
                    navigate(-1);
                }
                else {
                    let errorMessage = (data.response && data.response.data) ? data.response.data : data.message;
                    ServiceManager.ToastService.showError(errorMessage.toString());
                }
            }
        }
    });

    const validateDuplicateCron = (value: any, allValues: any) => {
        const scriptTextValues = allValues?.schedule.map((item: any) => item.cronExpression);
        const isDuplicate = scriptTextValues.filter((text: any) => text === value).length > 1;
        if (isDuplicate) {
            return 'Duplicate Extract Schedule is not allowed';
        }
        return undefined;
    };

    const validateCronExpression = (value: any) => {
        try {
            const isValid = cronstrue.toString(value);
            if (!isValid) {
                return 'Invalid Cron expression'
            }
        } catch (error: any) {
            return 'Invalid Cron expression'
        }
    };

    const openCloseSideBar = (isOpen: boolean) => {
        setShowNeedHelp(isOpen);
    };

    const NeedHelpSideBar = () => {
        return (
            <div className={`offcanvas offcanvas-end w-40 ${showNeedHelp ? 'show' : ''}`}>
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title">Need Help</h5>
                    <button type="button" className="btn-close" onClick={() => openCloseSideBar(false)}></button>
                </div>
                <div className="offcanvas-body font-14" style={{textAlign: "justify"}}>
                    <p>Tasks must be scheduled using Cron expressions. It may help to use online generators to translate schedules in to Cron expressions.</p>
                    <p className="mb-2"><b>Cron Expression Format</b></p>
                    <p>&lt;minute&gt; &lt;hour&gt; &lt;day-of-month&gt; &lt;month&gt; &lt;day-of-week&gt; &lt;command&gt;</p>
                    <p className="mb-2"><b>Special Characters</b></p>
                    <ul>
                        <li> <p className="mb-2"><b>* (all)</b> specifies that event should happen for every time unit. For example, “*” in the &lt;minute&gt; field means “for every minute.”</p> </li>
                        <li> <p className="mb-2"><b>? (any)</b> is utilized in the &lt;day-of-month&gt; and &lt;day-of-week&gt; fields to denote the arbitrary value and thus neglect the field value. For example, if we want to fire a script at “5th of every month” irrespective of what day of the week falls on that date, we specify a “?” in the &lt;day-of-week&gt; field.</p> </li>
                        <li> <p className="mb-2"><b>- (range)</b> determines the value range. For example, “10-11” in the &lt;hour&gt; field means “10th and 11th hours.”</p> </li>
                        <li> <p className="mb-2"><b>, (values)</b> specifies multiple values. For example, “MON, WED, FRI“ in &lt;day-of-week&gt; field means on the days “Monday, Wednesday and Friday.”</p> </li>
                        <li> <p className="mb-2"><b>/ (increments)</b> specifies the incremental values. For example, a “5/15” in the &lt;minute&gt; field means at “5, 20, 35 and 50 minutes of an hour.”</p> </li>
                        <li> <p className="mb-2"><b>L (last)</b> has different meanings when used in various fields. For example, if it's applied in the &lt;day-of-month&gt; field, it means last day of the month, i.e. “31st of January” and so on as per the calendar month. It can be used with an offset value, like “L-3”, which denotes the “third to last day of the calendar month.” In &lt;day-of-week&gt;, it specifies the “last day of a week.” It can also be used with another value in &lt;day-of-week&gt;, like “6L”, which denotes the “last Friday.”</p> </li>
                        <li> <p className="mb-2"><b>W (weekday)</b> determines the weekday (Monday to Friday) nearest to a given day of the month. For example, if we specify “10W” in the &lt;day-of-month&gt; field, it means the “weekday near to 10th of that month.” So if “10th” is a Saturday, the job will be triggered on “9th,” and if “10th” is a Sunday, it will trigger on “11th.” If we specify “1W” in &lt;day-of-month&gt; and if “1st” is Saturday, the job will be triggered on “3rd,” which is Monday, and it will not jump back to the previous month.</p> </li>
                        <li> <p className="mb-2"><b>#</b> specifies the “N-th” occurrence of a weekday of the month, for example, “third Friday of the month” can be indicated as “6#3”.</p> </li>
                    </ul>
                    <p className="mb-2"><b>Example Cron Expressions</b></p>
                    <p>At 12:00 p.m. (noon) every day: <b>0 12 * * ?</b></p>
                    <p>At 9:30 a.m. every Monday, Tuesday, Wednesday, Thursday and Friday: <b>30 9 ? * MON-FRI</b></p>
                </div>
            </div>
        );
    }

    useEffect(() => {
        if (!isTasksLoading && taskData?.taskID) {
            handleSelect(taskData?.misType)
            const data = taskData?.schedule.split("|").map((cronExpression: any) => ({ cronExpression: cronExpression.trim(), }));
            if(data.length > 0){
                remove(0);
                append(data)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskData]);

    return (
        <Layout title={taskId ? "Edit Task" : "Add New Task"}
            backArrow={<FontAwesomeIcon icon={faArrowLeft} onClick={() => navigate(-1)} className="me-4 fs-5 cursor-pointer" />}
            button={<button className="btn btn-primary crbc-bg-color d-flex align-items-center" onClick={() => openCloseSideBar(true)}> <span className="me-2" style={{fontSize: 15}}>Need Help</span> <FontAwesomeIcon icon={faQuestionCircle} /> </button>}>
            <Stack className="pt-3 overflow-auto" style={{ height: '80vh' }}>
                <form onSubmit={handleSubmit(onSubmit)} className="form">
                    {!isTasksLoading &&
                        <Container className="mt-3">
                            <Row className="pb-3">
                                <Col md={firstCol}>MIS Type</Col>
                                <Col md={secondCol}>
                                    <Dropdown className="m-auto" onSelect={handleSelect as any}>
                                        <Dropdown.Toggle disabled={taskId !== undefined && taskId !== ""} className="btn btn-secondary crbc-bg-color w-100 d-flex justify-content-between align-items-center"
                                            {...register('misType', { required: 'MIS type is required' })}>
                                            {taskData?.misType ? MisType[taskData?.misType]?.toUpperCase() : 'SIMS'}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="timer-dropdown w-100">
                                            <Dropdown.Item eventKey="1">SIMS</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    {errors.misType && <div className="invalid-feedback" style={{ display: 'block', color: '#DC3245' }}>MIS Type is required</div>}
                                </Col>
                            </Row>
                            <Row className="pb-3">
                                <Col md={firstCol}>Priority Number</Col>
                                <Col md={secondCol}>
                                    <input type="number" min="0" max="1000" defaultValue={taskData?.priority} 
                                        className={`form-control mt-1 ms-0 w-100 ${errors.priority ? 'is-invalid' : ''}`} 
                                        {...register('priority', {
                                            validate: value => {
                                                if (value < 0 || value > 1000) {
                                                  return 'Priority must be between 0 and 1000';
                                                }
                                                return true;
                                              },
                                        })}/>                                        
                                    {errors.priority && <div className="invalid-feedback" style={{ display: 'block', color: '#DC3245' }}>{errors.priority.message}</div>}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={firstCol}>Extract Schedule(s)</Col>
                                <Col md={secondCol}>
                                    {fields.map((schedule, index) => (
                                        <div key={index} className="mb-2">
                                            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                                <input placeholder="* * * * *" defaultValue={schedule.cronExpression} style={{ marginRight: '10px' }}
                                                    {...register(`schedule.${index}.cronExpression`, {
                                                        required: 'Schedule is required',
                                                        validate: { duplicate: validateDuplicateCron, cron: validateCronExpression }
                                                    })}
                                                    className={`form-control mt-1 ms-0 ${(errors.schedule && errors.schedule[index]) ? 'is-invalid' : ''}`}
                                                />
                                                <button type="button" className="btn btn-light red-color" onClick={() => handleRemove(index)}>
                                                    <FontAwesomeIcon icon={faTrashCan} />
                                                </button>
                                            </div>
                                            <ErrorMessage
                                                errors={errors}
                                                name={`schedule.${index}.cronExpression`}
                                                render={({ message }: any) => (
                                                    <div style={{ color: '#DC3245', marginTop: '5px' }}>
                                                        {message}
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    ))}
                                </Col>
                            </Row>
                            <Row className="pb-3">
                                <Col md={firstCol}></Col>
                                <Col md={secondCol}>
                                    {fields.length < 5 && (
                                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                        <a tabIndex={0} className="mt-2 cursor-pointer" style={{ textDecoration: 'none' }} onClick={() => append({ cronExpression: defaultCron })}>
                                            <FontAwesomeIcon icon={faPlus} className="me-2" /> Add Schedule
                                        </a>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={firstCol}>Enabled</Col>
                                <Col md={secondCol} className="d-flex">
                                    <input type="checkbox" {...register("enabled")} defaultChecked={taskData?.enabled} className={`ms-0 ${(errors.enabled) ? 'is-invalid' : ''}`} /><span className="ms-2">Yes</span>
                                </Col>
                            </Row>
                            {
                                !(createMutation.isLoading || updatedMutation.isLoading)
                                    ? (
                                        <Row className="mt-3">
                                            <Col md={firstCol}></Col>
                                            <Col md={secondCol}>
                                                <MISButton text="Cancel" className="btn popup-btn right-margin10 btn-outline-secondary" type="button" FnOnClick={() => navigate(-1)} />
                                                <MISButton text={taskId ? "Update" : "Create"} disabled={isTasksLoading ? true : false}
                                                    className="popup-btn btn btn-primary crbc-bg-color" type="submit" FnOnClick={handleSubmit(onSubmit)} />
                                            </Col>
                                        </Row>
                                    )
                                    : (
                                        <Row>
                                            <Col md={firstCol}></Col>
                                            <Col md={secondCol}>
                                                <div className="d-flex justify-content-center mt-3">
                                                    <Spinner animation="border" variant="info" role="status" > </Spinner>
                                                </div>
                                            </Col>
                                        </Row>
                                    )
                            }
                        </Container>
                    }

                    {isTasksLoading &&
                        <>
                            <div className="w-25 mis-loader">
                                <Skeleton height={20} count={5} />
                            </div>
                        </>
                    }
                </form>
            </Stack>

            <NeedHelpSideBar />

        </Layout>
    );
}