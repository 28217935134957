import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from "react-oidc-context";
import { usePermissionCheck } from '../../app/common/helper/Permissions';

const ProtectedRoute = ({ children, permission }: any) => {
  const auth = useAuth();
  var hasPermission = usePermissionCheck(permission);
  const location = useLocation();

  if (!auth.isAuthenticated && !auth.isLoading) {
    sessionStorage.setItem('redirectPath', location.pathname);
    sessionStorage.setItem('queryParams', location.search);
    return <Navigate to="/login" replace />;
  }
  else if (auth.isAuthenticated) {
    if (permission && !hasPermission) {
      return <Navigate to="/error/unauthorised" replace />;
    }
    return children;
  }
};

export default ProtectedRoute;