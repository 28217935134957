import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { Alert, Col, Container, Dropdown, Row, Spinner, Stack } from "react-bootstrap";
import ISimsUserType from "../../../../../models/SimsUserType";
import { ServiceManager } from "../../../../../services/ServiceManager";
import { APIStatusCode, SimsStandardMappingTemplates } from "../../../../../app/enums";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import MISButton from "../../../../../components/button";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Layout from "../../../../layout";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Skeleton from "react-loading-skeleton";
import CreatableSelect from 'react-select/creatable';

export default function CreateUserTypeForm(props: any) {
    const { establishmentId, configurationId, userTypeId } = useParams();
    const queryClient = useQueryClient();
    const [selectedMappingTemplate, setSelectedMappingTemplate] = useState(SimsStandardMappingTemplates.None);
    const [selectedUserType, setSelectedUserType] = useState(null);
    let navigate = useNavigate();

    const { isLoading : isUserTypesLoading, data : userTypes } = useQuery([`estbalishment-user-types-${establishmentId}`], () =>
     ServiceManager.UserTypeService.GetByEstablishmentId(establishmentId), { refetchOnWindowFocus: false }
    );

    const { isLoading : isSimsUserTypeLoading, data : simsUserType } = useQuery([`sims-user-types-${userTypeId}`], () =>
     ServiceManager.ConfigurationService.GetSimsUserType(establishmentId, configurationId, userTypeId), { refetchOnWindowFocus: false, cacheTime: 0 }
    );

    useEffect(() => {
        if (simsUserType) {
            handleUserTypeSelect(simsUserType.userTypeID);
            handleMappingTemplateSelect(simsUserType.mappingTemplate);
        }
        // eslint-disable-next-line
    }, [simsUserType]);

    const mutation = useMutation({
        mutationFn: (model: ISimsUserType) => {
            return ServiceManager.ConfigurationService.CreateSimsUserType(establishmentId, configurationId, model);
        },
        onError: (error, variables, context) => {

        },
        onSuccess: (data, variables, context) => {
            if (data === null) {
                ServiceManager.ToastService.showError("Unable to add user type.");
            }
            else {
                if (data.status === APIStatusCode.Created) {
                    ServiceManager.ToastService.showSuccess("User Type created successfully.");
                    queryClient.invalidateQueries({ queryKey: [`extract-client-user-types-${configurationId}`] });
                    navigate(-1);
                }
                else {
                    let errorMessage = (data.response && data.response.data) ? data.response.data : data.message;
                    ServiceManager.ToastService.showError(errorMessage.toString());
                }
            }
        }
    });

    const dataReportOptions = [
        {
            value: 'Student Extract',
            label: 'Student Extract'
        },
        {
            value: 'Applicant Extract',
            label: 'Applicant Extract'
        },
        {
            value: 'Staff Extract',
            label: 'Staff Extract'
        }
    ];

    const photoReportOptions = [
        {
            value: 'Student Photos Extract',
            label: 'Student Photos Extract'
        },
        {
            value: 'Applicant Photos Extract',
            label: 'Applicant Photos Extract'
        },
        {
            value: 'Staff Photos Extract',
            label: 'Staff Photos Extract'
        }
    ]

    const updatedMutation = useMutation({
        mutationFn: (model: ISimsUserType) => {
            return ServiceManager.ConfigurationService.UpdateSimsUserType(establishmentId, configurationId, userTypeId, model);
        },
        onError: (error, variables, context) => {

        },
        onSuccess: (data, variables, context) => {
            if (data === null) {
                ServiceManager.ToastService.showError("Unable to update user type.");
            }
            else {
                if (data.status === APIStatusCode.Ok) {
                    ServiceManager.ToastService.showSuccess("User Type updated successfully.");
                    queryClient.invalidateQueries({ queryKey: [`extract-client-user-types-${configurationId}`, `sims-user-types-${userTypeId}`] });
                    navigate(-1);
                }
                else {
                    let errorMessage = (data.response && data.response.data) ? data.response.data : data.message;
                    ServiceManager.ToastService.showError(errorMessage.toString());
                }
            }
        }
    });

    const { register, handleSubmit, clearErrors, control, setValue, formState: { errors } } = useForm<ISimsUserType>();
    const onSubmit: SubmitHandler<ISimsUserType> = (data) => {
        if(userTypeId) {
            updatedMutation.mutate(data);
        }
        else {
            mutation.mutate(data);
        }
    };
    const firstCol = 2, secondCol = 4;
    
    const handleMappingTemplateSelect = (eventKey: number) => {
        setValue('mappingTemplate', eventKey);
        setSelectedMappingTemplate(eventKey);
        clearErrors('mappingTemplate');
    };

    const handleUserTypeSelect = (eventKey: any) => {
        setValue('userTypeID', eventKey);
        setSelectedUserType(eventKey);
        clearErrors('userTypeID');
    }

    return (
        <Layout title={userTypeId ? "Edit User Type For Extract " : "New User Type For Extract"}
            backArrow={<FontAwesomeIcon icon={faArrowLeft} onClick={() => navigate(-1)} className="me-4 fs-5 cursor-pointer" />}
        >
        <Stack>
            {!isUserTypesLoading && userTypes.length === 0 &&
                <Alert key={'danger'} variant={'danger'}>
                    No User Types set up for establishment
                </Alert>
            }
            <form onSubmit={handleSubmit(onSubmit)} className="form">
                    {!isSimsUserTypeLoading &&
                        <Container className="mt-3">
                            <Row className="pb-3">
                                <Col md={firstCol}>User Type Name</Col>
                                <Col md={secondCol}>
                                    <Dropdown className="m-auto" onSelect={handleUserTypeSelect as any}>
                                        <Dropdown.Toggle className="btn btn-secondary crbc-bg-color w-100 d-flex justify-content-between align-items-center"
                                            {...register('userTypeID', { required: 'User type is required' })}
                                            disabled={userTypeId ? true : false}>
                                            {selectedUserType && userTypes ? userTypes.find((x: any) => x.userTypeID === parseInt(selectedUserType)).userTypeName : 'Select'}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="timer-dropdown w-100">
                                            {!isUserTypesLoading && userTypes &&
                                                userTypes.map((userType: any) => (
                                                    <Dropdown.Item key={userType.userTypeID} eventKey={userType.userTypeID}>
                                                        {userType.userTypeName}
                                                    </Dropdown.Item>
                                                ))
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    {errors.userTypeID && <div className="invalid-feedback" style={{ display: 'block', color: '#DC3245' }}>User Type is required</div>}
                                </Col>
                            </Row>
                            <Row className="pb-3">
                                <Col md={firstCol}>Data Report Name</Col>
                                <Col md={secondCol}>
                                    <Controller
                                        control={control}
                                        name="reportName"
                                        defaultValue={simsUserType.reportName}
                                        rules={{
                                            required: true,
                                        }}
                                        render={({
                                            field: { onChange, onBlur, value, name, ref },
                                        }) => (
                                            <CreatableSelect
                                                isClearable
                                                placeholder={"Type or Select"}
                                                className={`${(errors.reportName) ? 'is-invalid' : ''}`}
                                                defaultInputValue={simsUserType.reportName}
                                                options={dataReportOptions}
                                                onChange={val => onChange(val?.value)}
                                            />
                                        )}
                                    />
                                    {errors.reportName && <div className="invalid-feedback">Data Report Name is required</div>}
                                </Col>
                            </Row>
                            <Row className="pb-3">
                                <Col md={firstCol}>Photos Report Name</Col>
                                <Col md={secondCol}>
                                    <Controller
                                        control={control}
                                        name="photoReportName"
                                        defaultValue={simsUserType.photoReportName}
                                        rules={{
                                            required: true,
                                        }}
                                        render={({
                                            field: { onChange, onBlur, value, name, ref },
                                        }) => (
                                            <CreatableSelect
                                                isClearable
                                                placeholder={"Type or Select"}
                                                className={`${(errors.photoReportName) ? 'is-invalid' : ''}`}
                                                defaultInputValue={simsUserType.photoReportName}
                                                options={photoReportOptions}
                                                onChange={val => onChange(val?.value)}
                                            />
                                        )}
                                    />
                                    {errors.photoReportName && <div className="invalid-feedback">Photos Report Name is required</div>}
                                </Col>
                            </Row>
                            <Row className="pb-3">
                                <Col md={firstCol}>Mapping Template</Col>
                                <Col md={secondCol}>
                                    <Dropdown className="m-auto" onSelect={handleMappingTemplateSelect as any}>
                                        <Dropdown.Toggle className="btn btn-secondary crbc-bg-color w-100 d-flex justify-content-between align-items-center"
                                            {...register('mappingTemplate', { required: 'Mapping template is required' })}>
                                            {selectedMappingTemplate ? SimsStandardMappingTemplates[selectedMappingTemplate] : 'Select'}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="timer-dropdown w-100">
                                            <Dropdown.Item eventKey={SimsStandardMappingTemplates.None}>None</Dropdown.Item>
                                            <Dropdown.Item eventKey={SimsStandardMappingTemplates.Student}>Student</Dropdown.Item>
                                            <Dropdown.Item eventKey={SimsStandardMappingTemplates.Staff}>Staff</Dropdown.Item>
                                            <Dropdown.Item eventKey={SimsStandardMappingTemplates.Applicants}>Applicants</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    {errors.mappingTemplate && <div className="invalid-feedback" style={{ display: 'block', color: '#DC3245' }}>Mapping Template is required</div>}
                                </Col>
                            </Row>
                            <Row className="pb-3">
                                <Col md={firstCol}>Is Applicant</Col>
                                <Col md={secondCol}>
                                    <input type="checkbox" {...register("isApplicant")} defaultChecked={simsUserType.isApplicant} className={`mt-1 ms-0 ${(errors.photoReportName) ? 'is-invalid' : ''}`} />
                                </Col>
                            </Row>
                            {
                                !(mutation.isLoading || updatedMutation.isLoading)
                                    ? (
                                        <Row className="mt-5">
                                            <Col md={firstCol}></Col>
                                            <Col md={secondCol}>
                                                <MISButton text="Cancel" className="btn popup-btn right-margin10 btn-outline-secondary" type="button" FnOnClick={() => navigate(-1)} />
                                                <MISButton text={userTypeId ? "Update" : "Create"} disabled={(!isUserTypesLoading && userTypes.length === 0) ? true : false} className="popup-btn btn btn-primary crbc-bg-color" type="submit" FnOnClick={handleSubmit(onSubmit)} />
                                            </Col>
                                        </Row>
                                    )
                                    : (
                                        <Row>
                                            <Col md={firstCol}></Col>
                                            <Col md={secondCol}>
                                                <div className="d-flex justify-content-center mt-3">
                                                    <Spinner animation="border" variant="info" role="status" > </Spinner>
                                                </div>
                                            </Col>
                                        </Row>
                                    )
                            }
                        </Container>
                    }

                    {isSimsUserTypeLoading &&
                        <React.Fragment>
                            <div className="w-25 mis-loader">
                                <Skeleton height={20} count={5} />
                            </div>
                        </React.Fragment>
                    }     
            </form>
        </Stack>
        </Layout>
    );
}