import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { ITransformScript } from "../../../../models/TransformScript";
import { ServiceManager } from "../../../../services/ServiceManager";
import Layout from "../../../layout";
import MISButton from "../../../../components/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faArrowLeft, faGripVertical, faSave, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { Col, Container, Row, Stack } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { useForm, useFieldArray } from "react-hook-form";
import { useEffect, useRef } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { APIStatusCode, Permissions } from "../../../../app/enums";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { usePermissionCheck } from "../../../../app/common/helper/Permissions";
import MISTooltip from "../../../../components/tooltip";
import { PERMISSION_REQUIRED, UNSAVED_CHANGE_MESSAGE } from "../../../../app/constants";

export default function TransformScript() {
    let navigate = useNavigate();
    const queryClient = useQueryClient();
    const { establishmentId, clientId } = useParams();
    const refSubmitButtom = useRef<HTMLButtonElement>(null);
    const canEditEstablishments = usePermissionCheck(Permissions.CanEditEstablishments);

    const { isLoading, data: transformData } = useQuery<ITransformScript[]>(["transform-script-" + clientId], () =>
        ServiceManager.EstablishmentService.GetTransformScripts(establishmentId, clientId), { refetchOnWindowFocus: false }
    );

    const mutation = useMutation({
        mutationFn: (model: ITransformScript) => {
            return ServiceManager.EstablishmentService.UpdateTransformScripts(establishmentId, clientId, model);
        },
        onError: (error, variables, context) => {

        },
        onSuccess: (data, variables, context) => {
            if (data === null) {
                ServiceManager.ToastService.showError("Unable to save transforms.");
            }
            else {
                if (data.status === APIStatusCode.Ok) {
                    ServiceManager.ToastService.showSuccess("Transform Scripts saved successfully.");
                    queryClient.invalidateQueries({ queryKey: [`transform-script-${clientId}`] });
                }
                else {
                    let errorMessage = (data.response && data.response.data) ? data.response.data : data.message;
                    ServiceManager.ToastService.showError(errorMessage.toString());
                }
            }
        }
    });

    const onSubmit = (data: any) => {
        const updatedTransformScript = data.data.map((fieldMapping: ITransformScript, index: number) => (
            {
                ...fieldMapping,
                priority: index + 1,
            }
        ));
        mutation.mutate(updatedTransformScript);
    };

    const { register, handleSubmit, reset, getValues, control, formState: { errors } } = useForm();
    const { fields, append, remove, move } = useFieldArray({
        control,
        name: 'data',
    });

    const handleRemove = (index: any) => {
        remove(index);
        reset({ data: getValues().data });
    };

    const validateDuplicateScriptText = (value: any, allValues: any) => {
        const scriptTextValues = allValues.data.map((item: any) => item.scriptText);
        const isDuplicate = scriptTextValues.filter((text: any) => text === value).length > 1;
        if (isDuplicate) {
            return 'Duplicate Script is not allowed';
        }
        return undefined;
    };

    const handleUnsavedChanges = (event: any) => {
        event.preventDefault();
    };

    const isUnsavedChanges = (transformData: any) => {
        const prevFormData = getValues().data;

        const filteredFormData = prevFormData.filter((obj: any) => {
            const scriptTextValue = obj.scriptText;
            return scriptTextValue !== '' && scriptTextValue !== undefined;
        });

        if ((filteredFormData && filteredFormData.length === 0) && (transformData && transformData.length === 0)) {
            return false;
        }

        const sortedFormData = JSON.stringify(filteredFormData.sort());
        const sortedTransformData = JSON.stringify(transformData.sort());

        return sortedFormData !== sortedTransformData;
    }

    const handleUnsavedChangesClick = () => {
        if (isUnsavedChanges(transformData)) {
            const shouldLeave = window.confirm(UNSAVED_CHANGE_MESSAGE);
            if (shouldLeave) {
                navigate(-1);
            }
        }
        else {
            navigate(-1);
        }
    }

    const handleBeforeUnload = (event: any) => {
        if (isUnsavedChanges(transformData)) {
            const message = UNSAVED_CHANGE_MESSAGE;
            event.returnValue = message;
            return message;
        }
    };

    const handleDragEnd = (result: any) => {
        if (!result.destination || !canEditEstablishments) {
            return; // Dropped outside the list
        }

        const startIndex = result.source.index;
        let endIndex = result.destination.index;
        if (endIndex >= fields.length) {
            endIndex = fields.length - 1;
        }

        move(startIndex, endIndex);
    };

    useEffect(() => {
        if (!isLoading && transformData) {
            window.addEventListener('beforeunload', handleBeforeUnload);
            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transformData]);

    useEffect(() => {
        if (!isLoading && transformData) {
            reset({ data: [] });

            if (transformData.length > 0) {

                transformData.forEach((item: any, index: any) => {
                    append(item);
                });
            }
            else {
                append({});
            }
        }
    }, [isLoading, transformData, append, reset]);

    return (
        <Layout title="Transform"
            button={
                canEditEstablishments ?
                    <MISButton text="Save" className="btn btn-primary crbc-bg-color me-2" type="submit" form="hook-form" disabled={mutation.isLoading}
                        FnOnClick={() => refSubmitButtom?.current?.click()} icon={<FontAwesomeIcon icon={faSave} />}></MISButton>
                    :
                    <MISTooltip message={PERMISSION_REQUIRED} placement="bottom">
                        <div className="disabled">
                            <MISButton text="Save" className="btn btn-secondary me-2" FnOnClick={() => null} disabled icon={<FontAwesomeIcon icon={faSave} />}></MISButton>
                        </div>
                    </MISTooltip>
            }
            backArrow={<FontAwesomeIcon icon={faArrowLeft} onClick={handleUnsavedChangesClick} className="me-4 fs-5 cursor-pointer" />}
        >
            {isLoading ?
                (
                    <div className="mis-loader">
                        <Row className="pb-3">
                            <Col md={3}><Skeleton height={20} /></Col>
                            <Col md={6}><Skeleton height={20} /></Col>
                            <Col md={3}><Skeleton height={20} /></Col>
                        </Row>
                        <Row className="pb-3">
                            <Col md={3}><Skeleton height={20} /></Col>
                            <Col md={6}><Skeleton height={20} /></Col>
                            <Col md={3}><Skeleton height={20} /></Col>
                        </Row>
                        <Row className="pb-3">
                            <Col md={3}><Skeleton height={20} /></Col>
                            <Col md={6}><Skeleton height={20} /></Col>
                            <Col md={3}><Skeleton height={20} /></Col>
                        </Row>
                    </div>
                )
                :
                (
                    <>
                        <Stack direction="horizontal">
                            {
                                canEditEstablishments ?
                                    <MISButton text="New" className="btn btn-secondary btn-small me-2" FnOnClick={() => append({ scriptText: '' })} 
                                        icon={<FontAwesomeIcon icon={faAdd} />} disabled={fields.length >= 15}></MISButton>
                                    :
                                    <MISTooltip message={PERMISSION_REQUIRED} placement="bottom">
                                        <div className="disabled">
                                            <MISButton text="New" className="btn btn-secondary btn-small me-2" FnOnClick={() => null} disabled 
                                                icon={<FontAwesomeIcon icon={faAdd} />}></MISButton>
                                        </div>
                                    </MISTooltip>
                            }
                        </Stack>

                        <Stack className="pt-3 overflow-auto" style={{ height: '80vh' }}>
                            <form onSubmit={handleSubmit(onSubmit)} className="form">
                                <Container className="mt-3">
                                    <DragDropContext onDragEnd={handleDragEnd}>
                                        {fields.map((field: any, index) => (
                                            <Droppable droppableId={field.id} direction="vertical" isDropDisabled={!canEditEstablishments}>
                                                {(provided) => (
                                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                                        <Draggable key={field.id} draggableId={field.id} index={index}>
                                                            {(provided) => (
                                                                <div key={field.id} ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}>
                                                                    <Row className="transform-field-row mb-5">
                                                                        <Col md={1} className="me-2" style={{ flex: '0' }}>
                                                                            <span className="transform-number-circle">{index + 1}</span>
                                                                        </Col>
                                                                        <Col md={1} style={{ flex: '0' }}>
                                                                            <FontAwesomeIcon icon={faGripVertical} className="gray-color me-3" />
                                                                        </Col>
                                                                        <Col md={7} className="transform-field-text p-0 me-3 position-relative">
                                                                            <label className="transform-field-name">Script</label>
                                                                            <input {...register(`data[${index}].scriptText`, { required: "Transform Script is required", validate: { duplicate: validateDuplicateScriptText } })}
                                                                                defaultValue={field.scriptText || ''} className={`form-control mt-1 ms-0 w-100 ${(errors.scriptText) ? 'is-invalid' : ''}`}
                                                                                onChange={handleUnsavedChanges} disabled={!canEditEstablishments}/>
                                                                            <ErrorMessage
                                                                                errors={errors}
                                                                                name={`data[${index}].scriptText`}
                                                                                render={({ message }: any) => <div className="invalid-feedback position-absolute" style={{ display: 'block', color: '#DC3245' }}>{message}</div>}
                                                                            />
                                                                        </Col>
                                                                        <Col md={3}>
                                                                            {
                                                                                canEditEstablishments ?
                                                                                    <button className="btn btn-light red-color" onClick={() => handleRemove(index)}>
                                                                                        <FontAwesomeIcon icon={faTrashCan} />
                                                                                    </button>
                                                                                    :
                                                                                    <MISTooltip message={PERMISSION_REQUIRED} placement="bottom">
                                                                                        <div className="disabled" style={{width: '15%'}}>
                                                                                            <button className="btn btn-light red-color" onClick={() => null} disabled>
                                                                                                <FontAwesomeIcon icon={faTrashCan} />
                                                                                            </button>
                                                                                        </div>
                                                                                    </MISTooltip>
                                                                            }
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        ))}
                                    </DragDropContext>
                                </Container>
                                <button hidden={true} ref={refSubmitButtom} type={"submit"} />
                            </form>
                        </Stack>
                    </>
                )
            }
        </Layout>

    )
}