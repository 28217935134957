import React, { useState } from "react";
import {
    flexRender,
    getCoreRowModel,
    useReactTable,
    PaginationState
} from "@tanstack/react-table";
import { useQuery } from "@tanstack/react-query";
import { Stack, Table } from "react-bootstrap";
import { Pagination } from "react-bootstrap";
import SearchBar from "../search";
import Skeleton from "react-loading-skeleton";

interface IPagedFilterTableProps {
    tableKey: string;
    columns: any;
    data: any;
    usePagination: boolean;
    useSearch: boolean;
    pageRecords: number;
    fnFetchData: Function;
    onRowSelect?: any;
    title?: any;
    fnRowClick?: Function;
}

export default function PagedFilterTable(props: IPagedFilterTableProps) {

    const [searchValue, setSearchValue] = useState<string>('');
    const [activeRowIndex, setActiveRowIndex] = useState<number>(-1);

    const [{ pageIndex, pageSize }, setPagination] =
        React.useState<PaginationState>({
            pageIndex: 0,
            pageSize: props.pageRecords,
        });

    const fetchDataOptions = {
        pageIndex,
        pageSize,
        searchValue : searchValue,
    };

    const { isLoading, data } = useQuery([props.tableKey, fetchDataOptions], () =>
            props.fnFetchData(fetchDataOptions), {
            refetchOnWindowFocus: false
        }
    );

    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize]
    );

    const onRowClicked = (row : any) => {
        setActiveRowIndex(row.index);
        props.fnRowClick?.(row);
    }

    const table = useReactTable({
        data: data ?? props.data,
        columns: props.columns,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
        enableRowSelection: true,
        onPaginationChange: setPagination,
        state: {
            pagination
        },
        debugTable: true,
    });
    return (
        <React.Fragment>           
            {
                props.title ?
                    <Stack className="border border-bottom-0 w-100 table-title" direction="horizontal">
                        {props.title}
                        {props.useSearch && <SearchBar placeholder="Search" className="ms-auto" onChange={(searchText: string) => setSearchValue(searchText)} />}
                    </Stack> :
                    props.useSearch && <SearchBar placeholder="Search" className="mb-3" onChange={(searchText: string) => setSearchValue(searchText)} />
            }
            <Table responsive borderless>
                <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                            <th
                                key={header.id}
                                style={{
                                    width:
                                        header.getSize() !== 150
                                            ? header.getSize() + "%"
                                            : header.getSize(),
                                }}
                            >
                                {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                    )}
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody>
                {!isLoading && table.getRowModel().rows.map(row => (
                    <tr key={row.id} className={activeRowIndex === row.index ? "selected" : ""} onClick={() => props.fnRowClick ? onRowClicked(row) : () => false }>
                        {row.getVisibleCells().map(cell => (
                            <td key={cell.id}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                        ))}
                    </tr>
                ))}

                {isLoading && table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => (
                            <td key={header.id}><Skeleton height={30} /></td>
                        ))}
                    </tr>
                ))}

                {table.getRowModel().rows.length === 0 && !isLoading && (
                    <tr>
                        <td colSpan={table.getHeaderGroups()[0].headers.length} className="text-center">
                            No records found
                        </td>
                    </tr>
                )}
                </tbody>
            </Table>
            {props.usePagination && !isLoading && (
                <Pagination className="float-end mt-2">
                    <Pagination.Item
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                    >
                        Previous
                    </Pagination.Item>
                    <Pagination.Item
                        onClick={() => table.nextPage()}
                        disabled={table.getRowModel().rows.length < props.pageRecords}
                    >
                        Next
                    </Pagination.Item>
                </Pagination>
            )}
        </React.Fragment>
    );
}
