import BaseService from "./BaseService";

export default class UserTypeService extends BaseService {

    controllerName = "establishment";

    async GetByEstablishmentId(establishmentId: any): Promise<any> {
        const resp = await this.Get(`${establishmentId}/usertypes/all`, {});
        return resp ? resp.data : null;
    }

    async DeleteUserType(establishmentId : any, userTypeId: number): Promise<any> {
        const resp = await this.Delete(`/${establishmentId}/usertypes/${userTypeId}`);
        return resp;
    }

    async Update(establishmentId: any, userTypeId: number, userTypeName: string): Promise<any> {
        const resp = await this.Put(`/${establishmentId}/usertypes/${userTypeId}`, { 
            userTypeName : userTypeName 
        });
        return resp;
    }

    async Create(establishmentId: any, userTypeName: string): Promise<any> {
        const resp = await this.Post(`/${establishmentId}/usertypes`, {
            userTypeName: userTypeName,
        });
        return resp;
    }

}