import { Stack } from "react-bootstrap";
import { ServiceManager } from "../../services/ServiceManager";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { useQuery } from "@tanstack/react-query";
import { MisServiceType, Permissions, QueueStatus } from "../../app/enums";
import Skeleton from "react-loading-skeleton";
import IDashboardStatistics from "../../models/DashboardStatsModel";
import React from "react";
import { Link } from "react-router-dom";
import { usePermissionCheck } from "../../app/common/helper/Permissions";
import MISTooltip from "../tooltip";
import { PERMISSION_REQUIRED } from "../../app/constants";

export interface IMisStatsProps {
    headingText: string;
    selectedTimer: number; // currently in hours
    startDate: Date;
    finishDate: Date;
    serviceName:string;
}
export default function MisStats(props: IMisStatsProps) {
  let canViewHealthboard: boolean = usePermissionCheck(Permissions.CanViewHealthboard);

  const { isLoading, data } = useQuery([props.selectedTimer + props.startDate.toTimeString() + props.serviceName], () => {
    if (props.serviceName === MisServiceType.Task) {
      return ServiceManager.TaskService.GetTaskStats(props.startDate.toISOString(), props.finishDate.toISOString())
    }
    else if (props.serviceName === MisServiceType.Extract) {
      return ServiceManager.ExtractService.GetExtractStats(props.startDate.toISOString(), props.finishDate.toISOString())
    } 
    else if (props.serviceName === MisServiceType.Transform) {
      return ServiceManager.TransformService.GetExtractStats(props.startDate.toISOString(), props.finishDate.toISOString())
    }
  },
    {
      refetchOnWindowFocus: false
    }
  );

  return (
    <React.Fragment>
      <div className="stats-widget-box">
        <Stack direction="horizontal">
          <div className="stats-widget-box-header">
            {props.headingText}
          </div>
          {
            canViewHealthboard ?
              <Link className="stats-widget-box-header-button" to={`/healthboard?type=${props.serviceName}&startDate=${props.startDate.toISOString()}&finishDate=${props.finishDate.toISOString()}`}>
                View
              </Link> :
              <MISTooltip message={PERMISSION_REQUIRED} placement="bottom">
                  <Link className="stats-widget-box-header-button-disabled" style={{ cursor: "pointer" }} to={""}>
                    View
                  </Link>
              </MISTooltip>
          }
        </Stack>
        <div className="outer-container d-flex justify-content-center align-items-center">
          <div className="stats-log-count-container">
            <div className="stats-log-count-upper">
              <FontAwesomeIcon icon={faCircle} size="2xs" color="#D92D20" />
              <p className="stats-log-count-header">
                Failed
              </p>
            </div>
            <p className="stats-log-count-text">{!isLoading && data !== null ? data?.find((x: IDashboardStatistics) => x.queueStatusName === QueueStatus[QueueStatus.Failed])?.count ?? 0 : <Skeleton height={20} count={1} />}</p>
          </div>
          <div className="stats-log-count-container">
            <div className="stats-log-count-upper">
              <FontAwesomeIcon icon={faCircle} size="2xs" color="#FFB900" />
              <p className="stats-log-count-header">Not Executed</p>
            </div>
            <p className="stats-log-count-text">
              {!isLoading && data !== null ? data?.find((x: IDashboardStatistics) => x.queueStatusName === QueueStatus[QueueStatus.NotExecuted])?.count ?? 0 : <Skeleton height={20} count={1} />}
            </p>
          </div>
          <div className="stats-log-count-container">
            <div className="stats-log-count-upper">
              <FontAwesomeIcon icon={faCircle} size="2xs" color="#12B76A" />
              <p className="stats-log-count-header">Completed</p>
            </div>
            <p className="stats-log-count-text">
              {!isLoading && data !== null ? data?.find((x: IDashboardStatistics) => x.queueStatusName === QueueStatus[QueueStatus.Completed])?.count ?? 0 : <Skeleton height={20} count={1} />}</p>
          </div>
        </div>
      </div>
    </React.Fragment >
  );
}