import BaseService from "./BaseService";

export default class ProcessService extends BaseService {
    controllerName = "transform";

    async GetStats(dateStart : string, dateFinish : string): Promise<any> {
      const resp = await this.Get(`statistics`, { 
        dateStart : dateStart,
        dateFinish : dateFinish,
      });
      return resp.data ? resp.data : resp;
    }

}