import ISubTask from "../models/SubTask";
import { ITransformScript } from "../models/TransformScript";
import BaseService from "./BaseService";

export default class EstablishmentService extends BaseService {
  controllerName = "establishment";

  async GetAllWithChildern(
    includeDisabled: boolean,
    page: number,
    pageSize: number,
    search: string = '',
    extractStatus:number = 0
  ): Promise<any> {
    const resp = await this.Get("/all", {
      includeDisabled: includeDisabled,
      page: page,
      pageSize: pageSize,
      search: search,
      extractStatus:extractStatus,
    });

    return resp ? resp.data : null;
  }
  async GetAll(
    includeDisabled: boolean,
    page: number,
    pageSize: number,
    search: string = ''
  ): Promise<any> {
    const resp = await this.Get("", {
      includeDisabled: includeDisabled,
      page: page,
      pageSize: pageSize,
      search: search
    });

    return resp ? resp.data : null;
  }

  async GetById(establishmentId: any, includeChildren: boolean): Promise<any> {
    const resp = await this.Get(`${establishmentId}`, {
      includeChildren: includeChildren,
    });

    return resp ? resp.data : null;
  }

  async GetByAccountId(accountId : any, includeChildren: boolean): Promise<any> {
    const resp = await this.Get(`account/${accountId}`, {
      includeChildren: includeChildren
    });

    return resp.data ? resp.data : resp;
  }

  async Synchronise(accountId : any) : Promise<any> {
    const resp = await this.Patch(`account/${accountId}`, {});
    return resp;
  }

  async GetExtractPeopleData(establishmentID: string, search: string, clientId: string, page: number, pageSize: number): Promise<any> {
    let params;
    if (clientId) {
      params = { search: search, clientId: clientId, page: page, pageSize: pageSize };
    }
    else {
      params = { search: search, page: page, pageSize: pageSize };
    }
    const resp = await this.Get(`${establishmentID}/extract/people`, params);

    return resp ? resp.data : null;
  }

  async GetTransformPeopleData(establishmentID: string, page: number, pageSize: number, search: string = '', year: string = '', userTypeId: number = -1): Promise<any> {
    let data = {
      search: search,
      year: year,
      userTypeId: userTypeId,
      page: page,
      pageSize: pageSize
    };
    let filteredData = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value !== '' && value !== -1 && value !== null)
    );
    const resp = await this.Get(`${establishmentID}/transform/people`, filteredData);

    return resp ? resp.data : null;
  }

  async GetFinalPeopleData(
    establishmentID: string,
    search: string,
    page: number,
    pageSize: number,
  ): Promise<any> {
    const resp = await this.Get(`${establishmentID}/final/people`, {
      search: search,
      page: page,
      pageSize: pageSize
    });

    return resp ? resp.data : null;
  }
  async UpdateDateNextExecute(
    establishmentID: any,
    dateNextExecutes:any,
  ): Promise<any> {
    const resp = await this.Patch(`${establishmentID}/tasks/next-executes`, {
      dateNextExecutes:dateNextExecutes
    });

    return resp ? resp.data : null;
  }

  async UpdateDateNextExecutes(establishmentID: any, dateNextExecutes: string) {
    const params: any = {
      DateNextExecutes: dateNextExecutes
    }

    const resp = await this.Patch(`${establishmentID}/tasks/next-executes`, params);

    return resp.data ? resp.data : resp;
  }

  async UpdatePeopleForceRefresh(establishmentID: any, crbcIDs: string[]) {
    const params: any = {
      CrbcIDs: crbcIDs
    }

    const resp = await this.Patch(`${establishmentID}/people/force-refresh`, params);

    return resp.data ? resp.data : resp;
  }

  async GetAllUserTypes(establishmentID: any){    
    const resp = await this.Get(`${establishmentID}/usertypes/all`, {});
    return resp.data ? resp.data : null;
  }

  async GetTransformScripts(establishmentId: any, clientId: any) : Promise<any> {
    const resp = await this.Get(`${establishmentId}/client/${clientId}/transform-scripts`, {});
    return resp.data ? resp.data : null;
  }

  async UpdateTransformScripts(establishmentId: any, clientId: any, model: ITransformScript) : Promise<any> {
    const resp = await this.Post(`${establishmentId}/client/${clientId}/transform-scripts`, model);
    return resp;
  }

  async GetAllTasks(establishmentID: any){    
    const resp = await this.Get(`${establishmentID}/tasks`, {
      includeDisabled : true
    });
    return resp.data ? resp.data : null;
  }

  async GetTaskById(establishmentID: any, taskID: any){    
    const resp = await this.Get(`${establishmentID}/tasks/${taskID}`, {});
    return resp.data ? resp.data : null;
  }

  async UpdateSubTasks(establishmentId: any, taskId: any, model: ISubTask) : Promise<any> {
    const resp = await this.Patch(`${establishmentId}/tasks/${taskId}/subtasks`, model);
    return resp;
  }

  async createTask(establishmentID: any, model: any) : Promise<any> {
    const resp = await this.Post(`${establishmentID}/tasks/`, model);
    return resp;
  }

  async updateTask(establishmentID: any, taskID: any, model: any) : Promise<any>  {
    const resp = await this.Put(`${establishmentID}/tasks/${taskID}`, model);
    return resp;
  }

  async deleteTask(establishmentID: any, taskID: any) : Promise<any>  {
    const resp = await this.Delete(`${establishmentID}/tasks/${taskID}`);
    return resp;
  }

  async GetConsumerClients(establishmentID: any){    
    const resp = await this.Get(`${establishmentID}/consumer-clients`, {});
    return resp.data ? resp.data : null;
  }

  async GetFinalAdditionalProperties(establishmentID: any){    
    const resp = await this.Get(`${establishmentID}/final/properties`, {
      includeDisabled : true
    });
    return resp.data ? resp.data : null;
  }

  async GetCustomFields(establishmentID: any, clientId: any) : Promise<any> {
    const resp = await this.Get(`${establishmentID}/client/${clientId}/custom-fields`, {});
    return resp;
  }

  async UpdateCustomFields(establishmentID: any, clientId: any, model: any) : Promise<any> {
    const resp = await this.Post(`${establishmentID}/client/${clientId}/custom-fields`, model);
    return resp;
  }

  async CreateClient(establishmentID: any, clientTypeID: any) : Promise<any> {
    const resp = await this.Post(`${establishmentID}/clientTypes/${clientTypeID}/clients`, {});
    return resp;
  }

  async DeleteClient(establishmentID: any, clientTypeID: any, clientID: any) : Promise<any> {
    const resp = await this.Delete(`${establishmentID}/clientTypes/${clientTypeID}/clients/${clientID}`);
    return resp;
  }
  
  async GetExtractFiles(establishmentID: string, page: number, pageSize: number) : Promise<any> {
    const resp = await this.Get(`${establishmentID}/extract/files`, { page: page, pageSize: pageSize });
    return resp ? resp.data : null;
  }

  async GetExtractFile(establishmentID: any, fileId: number) : Promise<any> {
    const resp = await this.Get(`${establishmentID}/extract/files/${fileId}`, { });
    return resp ? resp.data : null;
  }

  async GetTransformProperties(establishmentID: any, crbcId: any) : Promise<any> {
    const resp = await this.Get(`${establishmentID}/transform/people/${crbcId}/properties`, { });
    return resp ? resp.data : null;
  }

  async GetFinalProperties(establishmentID: any, crbcId: any) : Promise<any> {
    const resp = await this.Get(`${establishmentID}/final/people/${crbcId}/properties`, { });
    return resp ? resp.data : null;
  }
}