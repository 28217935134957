import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MISButton from "../../../components/button";
import Layout from "../../layout";
import { faAdd, faArrowLeft, faMagicWandSparkles, faPen, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import PagedFilterTable from "../../../components/table";
import IUserType from "../../../models/UserType";
import { createColumnHelper } from "@tanstack/react-table";
import { useState } from "react";
import { ServiceManager } from "../../../services/ServiceManager";
import CustomModal from "../../../components/modal";
import IconElement from "../../../components/icon";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Spinner, Stack } from "react-bootstrap";
import { APIStatusCode, Permissions } from "../../../app/enums";
import { useForm } from "react-hook-form";
import { PERMISSION_REQUIRED, SOMETHING_WENT_WRONG } from "../../../app/constants";
import { usePermissionCheck } from "../../../app/common/helper/Permissions";
import MISTooltip from "../../../components/tooltip";

export default function UserTypes(props: any) {
    const [selectedUserType, setSelectedUserType] = useState<IUserType>();
    const [tableJobs, setTableJobs] = useState([]);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [userTypeToDelete, setUserTypeToDelete] = useState(0);
    const canEditEstablishments = usePermissionCheck(Permissions.CanEditEstablishments);

    const handleCloseCreateModal = () => setShowCreateModal(false);
    const handleCloseDeleteModal = () => setShowDeleteModal(false);
    const handleCloseEditModal = () => setShowEditModal(false);

    let navigate = useNavigate();
    const { establishmentId } = useParams();

    const columnHelper = createColumnHelper<IUserType>();
    const columns = [
        columnHelper.accessor('userTypeID', {
            header: () => <span>User Type ID</span>,
            size: 30
        }),
        columnHelper.accessor('userTypeName', {
            header: () => <span>User Type Name</span>,
            size: 60
        }),
        columnHelper.accessor('actions', {
            header: () => <span>Actions</span>,
            size: 5,
            cell: ({ row }) => (
                <div>
                    {
                        canEditEstablishments ?
                        <FontAwesomeIcon
                            className="cursor-pointer me-5"
                            icon={faTrashAlt}
                            size="lg"
                            onClick={() => {
                                setShowDeleteModal(true); setUserTypeToDelete(row.original.userTypeID);
                            }}
                        />
                        :
                        <MISTooltip message={PERMISSION_REQUIRED} placement="top">
                            <FontAwesomeIcon
                                icon={faTrashAlt}
                                size="lg"
                                className="disabled me-5"
                            />
                        </MISTooltip>
                    }

                    {
                        canEditEstablishments ?
                            <FontAwesomeIcon
                                className="cursor-pointer"
                                icon={faPen}
                                size="lg"
                                onClick={() => {
                                    setSelectedUserType(row.original);
                                    setShowEditModal(true);
                                }}
                            />
                            :
                            <MISTooltip message={PERMISSION_REQUIRED} placement="top">
                                <FontAwesomeIcon
                                    icon={faPen}
                                    size="lg"
                                    className="disabled"
                                />
                            </MISTooltip>
                    }
                </div>
            )
        })
    ];

    async function fetchData(options: {
        pageIndex: number;
        pageSize: number;
        searchValue: string
    }) {
        const establishmentResponse = await ServiceManager.UserTypeService.GetByEstablishmentId(establishmentId);
        setTableJobs(establishmentResponse);
        return establishmentResponse;
    }

    const UserTypeCreateForm = () => {
        const queryClient = useQueryClient();

        const mutation = useMutation({
            mutationFn: (userTypeName: string) => {
                return ServiceManager.UserTypeService.Create(establishmentId, userTypeName);
            },
            onError: (error, variables, context) => {

            },
            onSuccess: (data, variables, context) => {
                if (data === null) {
                    ServiceManager.ToastService.showError("Unable to create user type.");
                }
                else {
                    if (data.status === APIStatusCode.Created) {
                        ServiceManager.ToastService.showSuccess("User Type created successfully.");
                        queryClient.invalidateQueries({ queryKey: ["user-types"] });
                        queryClient.invalidateQueries({ queryKey: [`estbalishment-user-types-${establishmentId}`] });
                        handleCloseCreateModal();
                    }
                    else if (data.response.status === APIStatusCode.Conflict) {
                        ServiceManager.ToastService.showError(data.response.data + ".");
                    }
                    else {
                        let errorMessage = (data.response && data.response.data && data.response.data.errors.errors) ? data.response.data.errors.errors : data.message;
                        ServiceManager.ToastService.showError(errorMessage.toString());
                    }
                }
            }
        });
        const { register, handleSubmit, formState: { errors }, setError } = useForm();
        const onSubmit = (data: any) => {
            mutation.mutate(data.userTypeName);
        };

        const handleEnterPress = (event: any) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                if (!event.target.value) {
                    setError('userTypeName', {
                        message: 'User type name is required',
                    });
                    return;
                }
                mutation.mutate(event.target.value);
            }
        };

        return (
            <Stack>
                <form onSubmit={handleSubmit(onSubmit)} className="form">
                    <p className="heading">Add New User Type</p>
                    <p className="text-muted">Please enter details below</p>
                    <label className="mt-1">User Type Name</label>
                    <input {...register("userTypeName", { required: true })}
                        onKeyDown={handleEnterPress} maxLength={20}
                        className={`form-control mt-1 ms-0 w-100 ${(errors.userTypeName) ? 'is-invalid' : ''}`} />
                    {errors.userTypeName && <div className="invalid-feedback">User Type Name is required</div>}

                    {
                        !mutation.isLoading
                            ? (<Stack direction="horizontal" className="mt-3">
                                <MISButton text="Cancel" className="btn popup-btn right-margin10 btn-outline-secondary w-100" FnOnClick={handleCloseCreateModal} />
                                <MISButton text="Create" className="popup-btn btn btn-primary crbc-bg-color w-100" type="submit" FnOnClick={handleSubmit(onSubmit)} />
                            </Stack>
                            ) : (
                                <div className="d-flex justify-content-center mt-3">
                                    <Spinner
                                        animation="border"
                                        variant="info"
                                        role="status"
                                    >
                                    </Spinner>
                                </div>
                            )
                    }
                </form>
            </Stack>
        )
    }

    const UserTypeEditForm = () => {
        const queryClient = useQueryClient();

        const mutation = useMutation({
            mutationFn: () => {
                return ServiceManager.UserTypeService.Update(establishmentId, selectedUserType?.userTypeID || 0, editUserTypeName);
            },
            onError: (error, variables, context) => {
                ServiceManager.ToastService.showError(SOMETHING_WENT_WRONG);
            },
            onSuccess: (data, variables, context) => {
                if (data === null) {
                    ServiceManager.ToastService.showError("Unable to update user type.");
                }
                else {
                    if (data.status === APIStatusCode.Ok) {
                        ServiceManager.ToastService.showSuccess("User Type updated successfully.");
                        queryClient.invalidateQueries({ queryKey: ["user-types"] });
                        queryClient.invalidateQueries({ queryKey: [`estbalishment-user-types-${establishmentId}`] });
                        handleCloseEditModal();
                    }
                    else if (data.response.status === APIStatusCode.Conflict) {
                        ServiceManager.ToastService.showError(data.response.data + ".");
                    }
                    else {
                        let errorMessage = (data.response && data.response.data && data.response.data.errors.errors) ? data.response.data.errors.errors : data.message;
                        ServiceManager.ToastService.showError(errorMessage.toString());
                    }
                }
            }
        });

        const [editUserTypeName, setEditUserTypeName] = useState(selectedUserType?.userTypeName || "");
        const onSubmit = () => {
            if (editUserTypeName) {
                mutation.mutate();
            }
        };

        const handleEnterPress = (event: any) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                if (event.target.value) {
                    mutation.mutate();
                }
            }
        };

        return (
            <Stack>
                <form className="form">
                    <p className="heading">Edit User Type</p>
                    <p className="text-muted">Please enter details below</p>
                    <label className="mt-1">User Type Name</label>
                    <input key="name-input" onChange={x => setEditUserTypeName(x.target.value)}
                        onKeyDown={handleEnterPress} maxLength={20} value={editUserTypeName || ""}
                        className={`form-control mt-1 ms-0 w-100 ${(editUserTypeName === '' || editUserTypeName === undefined) ? 'is-invalid' : ''}`} />
                    {!editUserTypeName ? <div className="invalid-feedback">User Type Name is required</div> : ""}

                    {
                        !mutation.isLoading
                            ? (<Stack direction="horizontal" className="mt-3">
                                <MISButton text="Cancel" className="btn popup-btn right-margin10 btn-outline-secondary w-100" FnOnClick={handleCloseEditModal} />
                                <MISButton text="Save" className="popup-btn btn btn-primary crbc-bg-color w-100" type="button" FnOnClick={onSubmit} />
                            </Stack>
                            ) : (
                                <div className="d-flex justify-content-center mt-3">
                                    <Spinner
                                        animation="border"
                                        variant="info"
                                        role="status"
                                    >
                                    </Spinner>
                                </div>
                            )
                    }
                </form>
            </Stack>
        )
    }

    const DeleteUserTypeConfirmationForm = () => {
        const queryClient = useQueryClient();
        const mutation = useMutation({
            mutationFn: () => {
                return ServiceManager.UserTypeService.DeleteUserType(establishmentId, userTypeToDelete);
            },
            onError: (error, variables, context) => {

            },
            onSuccess: (data, variables, context) => {
                if (data === null) {
                    ServiceManager.ToastService.showError("Unable to delete user type.");
                }
                else {
                    if (data.status === APIStatusCode.NoContent) {
                        ServiceManager.ToastService.showSuccess("User Type deleted successfully.");
                        queryClient.invalidateQueries({ queryKey: ["user-types"] });
                        queryClient.invalidateQueries({ queryKey: [`estbalishment-user-types-${establishmentId}`] });
                        handleCloseDeleteModal();
                    } else if (data.response.status === APIStatusCode.Conflict) {
                        ServiceManager.ToastService.showError("Unable to delete User Type as it is configured to an extract.");
                    }
                    else {
                        let errorMessage = (data.response && data.response.data && data.response.data.errors.errors) ? data.response.data.errors.errors : data.message;
                        ServiceManager.ToastService.showError(errorMessage.toString());
                    }
                }
            }
        });

        return (
            <Stack>
                <p className="heading text-center">Delete User Type</p>
                <p className="text-center">User Types are essential for configuring how staff/student MIS data is used by consumers. Are you sure you want to delete a User Type?</p>
                {
                    !mutation.isLoading
                        ? (<Stack direction="horizontal" className="mt-3">
                            <MISButton text="Cancel" className="popup-btn right-margin10 btn btn-outline-secondary w-50" FnOnClick={handleCloseDeleteModal} />
                            <MISButton text="Delete" className="popup-btn right-margin10 btn btn-danger w-50" FnOnClick={() => { mutation.mutate(); }} />
                        </Stack>
                        ) : (
                            <div className="d-flex justify-content-center mt-3">
                                <Spinner
                                    animation="border"
                                    variant="info"
                                    role="status"
                                >
                                </Spinner>
                            </div>
                        )
                }
            </Stack>
        )
    }

    return (
        <Layout title="User Types"
            backArrow={
                <FontAwesomeIcon icon={faArrowLeft} onClick={() => navigate(-1)} className="me-4 fs-5 cursor-pointer" />
            }
            button={
                canEditEstablishments ?
                    <MISButton text="Add User Type" className="btn btn-primary crbc-bg-color" FnOnClick={() => { setShowCreateModal(true); }} icon={<FontAwesomeIcon icon={faAdd} />}></MISButton>
                    :
                    <MISTooltip message={PERMISSION_REQUIRED} placement="bottom">
                        <div className="disabled">
                            <MISButton text="Add User Type" className="btn btn-secondary" FnOnClick={() => null} disabled icon={<FontAwesomeIcon icon={faAdd} />}></MISButton>
                        </div>
                    </MISTooltip>
                }
                    
                    
        >
            <div id="userTypeTable">
                <PagedFilterTable
                    tableKey={"user-types"}
                    columns={columns}
                    data={tableJobs}
                    usePagination={false}
                    useSearch={false}
                    pageRecords={10}
                    fnFetchData={fetchData}
                />
            </div>

            <CustomModal isShow={showCreateModal} handleClose={handleCloseCreateModal} header={<IconElement iconType={faMagicWandSparkles} headerClass="light-green-icon" color="#039855" />} size="md">
                <UserTypeCreateForm />
            </CustomModal>

            <CustomModal isShow={showDeleteModal} handleClose={handleCloseDeleteModal} header={<IconElement iconType={faTrashAlt} headerClass="error-icon ms-6" color="#D92D20" />} size="md">
                <DeleteUserTypeConfirmationForm />
            </CustomModal>

            <CustomModal isShow={showEditModal} handleClose={handleCloseEditModal} header={<IconElement iconType={faMagicWandSparkles} headerClass="light-green-icon" color="#039855" />} size="md">
                <UserTypeEditForm />
            </CustomModal>

        </Layout>
    );
}