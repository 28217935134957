import { createColumnHelper } from "@tanstack/react-table";
import { Stack } from "react-bootstrap";
import format from "date-fns/format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { ServiceManager } from "../../../services/ServiceManager";
import PagedFilterTable from "../../../components/table";
import ITransformData from "../../../models/TransformData";
import { useParams } from "react-router-dom";
import { toGuid } from "../../../app/common/helper/Guid";
import CustomModal from "../../../components/modal";
import { useState } from "react";
import CustomFieldsModal from "./modal/custom-fields-modal";
import { EstablishmentDataType } from "../../../app/enums";

export default function TransformData() {

    const { establishmentId } = useParams();
    const [showParentalConsentModal, setShowParentalConsentModal] = useState(false);
    const [parentalConsentModalValue, setParentalConsentModalValue] = useState("");
    const [showCustomFieldModal, setShowCustomFieldModal] = useState(false);
    const [crbcId, setCrbcId] = useState<any>();
    const [studentName, setStudentName] = useState<string>();

    const columnHelper = createColumnHelper<ITransformData>();
    const columns = [
        columnHelper.accessor('crbcID', {
            header: () => <span>Crbc ID</span>
        }),
        columnHelper.accessor('establishmentID2', {
            header: () => <span>Establishment ID</span>
        }),
        columnHelper.accessor('misID', {
            header: () => <span>Mis ID</span>
        }),
        columnHelper.accessor('misID2', {
            header: () => <span>Mis ID 2</span>
        }),
        columnHelper.accessor('userTypeID', {
            header: () => <span>User Type ID</span>
        }),
        columnHelper.accessor('title', {
            header: () => <span>Title</span>
        }),
        columnHelper.accessor('surname', {
            header: () => <span>Surname</span>
        }),
        columnHelper.accessor('firstName', {
            header: () => <span>First Name</span>
        }),
        columnHelper.accessor('legalSurname', {
            header: () => <span>Legal Surname</span>
        }),
        columnHelper.accessor('legalFirstName', {
            header: () => <span>Legal First Name</span>
        }),
        columnHelper.accessor('year', {
            header: () => <span>Year</span>
        }),
        columnHelper.accessor('regClass', {
            header: () => <span>Reg Class</span>
        }),
        columnHelper.accessor('dateOfBirth', {
            header: () => <span>Date of Birth</span>,
            cell: ({ row }) => (
                <Stack>
                    <span>{row.original.dateOfBirth ? format(new Date(row.original.dateOfBirth), "dd/MM/yyyy") : ''}</span>
                </Stack>
            )
        }),
        columnHelper.accessor('gender', {
            header: () => <span>Gender</span>,
            cell: ({ row }) => (
                <Stack>
                    <span>{row.original.gender === 'M' ? 'Male' : row.original.gender === 'F' ? 'Female' : 'Not Specified'}</span>
                </Stack>
            )
        }),
        columnHelper.accessor('isFsm', {
            header: () => <span>Fsm</span>,
            cell: ({ row }) => (
                <Stack>
                    <span>{row.original.isFsm ? 'Yes' : 'No'}</span>
                </Stack>
            )
        }),
        columnHelper.accessor('dateFsmExpiry', {
            header: () => <span>Fsm Expiry</span>,
            cell: ({ row }) => (
                <Stack>
                    <span className="font-dark">{row.original.dateFsmExpiry ? format(new Date(row.original.dateFsmExpiry), "p") : ''}</span>
                    <span>{row.original.dateFsmExpiry ? format(new Date(row.original.dateFsmExpiry), "dd-MM-yyyy") : ''}</span>
                </Stack>
            )
        }),
        columnHelper.accessor('admissionNumber', {
            header: () => <span>Admission Number</span>
        }),
        columnHelper.accessor('staffCode', {
            header: () => <span>Staff Code</span>
        }),
        columnHelper.accessor('dietaryPreferences', {
            header: () => <span>Dietary Preferences</span>
        }),
        columnHelper.accessor('dietaryNeeds', {
            header: () => <span>Dietary Needs</span>
        }),
        columnHelper.accessor('parentalConsent', {
            header: () => <span>Parental Consent</span>,
            cell: ({ row }) => (
                <span style={{ width: "400px", padding: "0" }} onClick={() => setParentalConsentModalData(row.original.parentalConsent)} className={row.original.parentalConsent ? 'parent-consent-td' : ''}>
                    {row.original.parentalConsent ?? ''}
                </span>
            )
        }),
        columnHelper.accessor('photoHash', {
            header: () => <span>Photo Hash</span>
        }),
        columnHelper.accessor('hasPhoto', {
            header: () => <span>Has Photo</span>,
            cell: ({ row }) => (
                <Stack>
                    <span>{row.original.hasPhoto ? 'Yes' : 'No'}</span>
                </Stack>
            )
        }),
        columnHelper.accessor('isActive', {
            header: () => <span>Status</span>,
            cell: ({ row }) => (
                <div className={`p-1 ps-2 pe-2 ${row.original.isActive ? 'queue-completed' : 'queue-notexecuted'}`}>
                    <FontAwesomeIcon icon={faCircle} size="2xs" className="icon" />
                    <span className="ms-2">{row.original.isActive ? 'Active' : 'Disabled'}</span>
                </div>
            )
        }),
        columnHelper.accessor('forceRefresh', {
            header: () => <span>Force Refresh</span>,
            cell: ({ row }) => (
                <Stack>
                    <span>{row.original.forceRefresh ? 'Yes' : 'No'}</span>
                </Stack>
            )
        }),
        columnHelper.accessor('dateLastActive', {
            header: () => <span>Date Last Active</span>,
            cell: ({ row }) => (
                <Stack>
                    <span>{row.original.dateLastActive ? format(new Date(row.original.dateLastActive), "dd/MM/yyyy") : ''}</span>
                </Stack>
            )
        }),
        columnHelper.accessor('dateCreated', {
            header: () => <span>Date Created</span>,
            cell: ({ row }) => (
                <Stack>
                    <span>{row.original.dateCreated ? format(new Date(row.original.dateCreated), "dd/MM/yyyy") : ''}</span>
                </Stack>
            )
        }),
        columnHelper.accessor('dateModified', {
            header: () => <span>Date Modified</span>,
            cell: ({ row }) => (
                <Stack>
                    <span>{row.original.dateModified ? format(new Date(row.original.dateModified), "dd/MM/yyyy") : ''}</span>
                </Stack>
            )
        }),
        columnHelper.accessor('dateLeaving', {
            header: () => <span>Date Leaving</span>,
            cell: ({ row }) => (
                <Stack>
                    <span>{row.original.dateLeaving ? format(new Date(row.original.dateLeaving), "dd/MM/yyyy") : ''}</span>
                </Stack>
            )
        }),
        columnHelper.accessor('upn', {
            header: () => <span>UPN</span>
        }),
        columnHelper.accessor('crbcID', {
            id: "customFields",
            header: () => <span>Custom Fields</span>,
            cell: ({ row }) => (
                <div className="text-center">
                    <button className="btn btn-outline-secondary" onClick={() => viewCustomFieldsModal(row.original.crbcID, row.original.firstName ?? "", row.original.surname ?? "")}>View</button>
                </div>
            ),
            size: 10,
            
        })
    ];

    function setParentalConsentModalData(modalVal: any) {
        setParentalConsentModalValue(modalVal);
        setShowParentalConsentModal(true);
    }

    async function fetchData(options: {
        pageIndex: number;
        pageSize: number;
        searchValue: string
    }) {
        const transformData = await ServiceManager.EstablishmentService.GetTransformPeopleData(toGuid(establishmentId ?? ""), options.pageIndex + 1,
            options.pageSize, options.searchValue);
        return transformData;
    }

    const viewCustomFieldsModal = (crbcId: any, fName: string, sName: string) => {
        setCrbcId(crbcId);
        setStudentName(`${fName} ${sName}`);
        setShowCustomFieldModal(true);
    }

    return (
        <>
            <div id="listView-data">
                <PagedFilterTable
                    tableKey={`transform-data-${establishmentId}`}
                    columns={columns}
                    data={[]}
                    usePagination={true}
                    pageRecords={25}
                    fnFetchData={fetchData}
                    useSearch={true}
                />
            </div>
            <CustomModal isShow={showParentalConsentModal} handleClose={() => setShowParentalConsentModal(false)} header={<p className="heading">Parental Consent</p>} size="md">
                {parentalConsentModalValue}
            </CustomModal>
            <CustomModal isShow={showCustomFieldModal} handleClose={() => setShowCustomFieldModal(false)} header={<span><p className="heading mb-0">Custom Fields</p><p className="font-14 mb-0">{studentName} - {crbcId}</p></span>} size="lg">
                <CustomFieldsModal callFrom={EstablishmentDataType.Transform} crbcId={crbcId} establishmentId={establishmentId} />
            </CustomModal>
        </>
    )
}