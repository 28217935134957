import { useState } from "react";
import { Nav } from "react-bootstrap";
import MISTooltip from "../../../components/tooltip";

interface INavTabProps {
    callbackActiveTab: any;
}

export default function NavTabs(props: INavTabProps) {

    const [activeKey, setActiveKey] = useState('raw-xml')
    const handleTabSelect = (selectedKey: any) => {
        setActiveKey(selectedKey)
        props.callbackActiveTab(selectedKey);
    };

    const XML_TEXT = "Source data received from MIS.";
    const EXTRACTED_TEXT = "People records extracted from raw data.";
    const PROCESSED_TEXT = "Extracted records have been processed/merged with estab records, updating leavers, applying pre-transform scripts.";
    const TRANSFORMED_TEXT = "Post-transform scripts have been executed, people records now in final state for client applications.";


    return (
        <Nav variant="pills" className="justify-content-center" activeKey={activeKey} onSelect={handleTabSelect}>
            <MISTooltip message={XML_TEXT} placement="top">
                <Nav.Item className="mt-1 mb-3 m-5">
                    <Nav.Link eventKey="raw-xml">
                        <svg width="40" height="40" className="tab-circle-border">
                            <circle cx="20" cy="20" r="20" className="tab-circle" />
                            <circle cx="20" cy="20" r="7" fill="white" />
                        </svg>
                        <svg width="130" height="40" className="tab-separator">
                            <path d="M0,20 L64,20 L128,20" stroke="#CEDDF3" strokeWidth="2" markerMid="url(#arrow)" />
                            <defs>
                                <marker id="arrow" markerWidth="10" markerHeight="10" refX="2" refY="3" orient="auto" markerUnits="strokeWidth">
                                    <path d="M0,0 L6,3 L0,6" stroke="#CEDDF3" fill="none" />
                                </marker>
                            </defs>
                        </svg>
                    </Nav.Link>
                    <span className="ms-3 d-block tab-text-ml3 tab-text position-absolute">Raw</span>
                </Nav.Item>
            </MISTooltip>
            <MISTooltip message={EXTRACTED_TEXT} placement="top">
                <Nav.Item className="mt-1 mb-3 m-5">
                    <Nav.Link eventKey="raw-data">
                        <svg width="40" height="40" className="tab-circle-border">
                            <circle cx="20" cy="20" r="20" className="tab-circle" />
                            <circle cx="20" cy="20" r="7" fill="white" />
                        </svg>
                        <svg width="128" height="40" className="tab-separator">
                            <path d="M0,20 L64,20 L133,20" stroke="#CEDDF3" strokeWidth="2" markerMid="url(#arrow)" />
                        </svg>
                    </Nav.Link>
                    <span className="ms-1 d-block tab-text-ml1 tab-text position-absolute">Extracted</span>
                </Nav.Item>
            </MISTooltip>
            <MISTooltip message={PROCESSED_TEXT} placement="top">
                <Nav.Item className="mt-1 mb-3 m-5">
                    <Nav.Link eventKey="transform">
                        <svg width="40" height="40" className="tab-circle-border">
                            <circle cx="20" cy="20" r="20" className="tab-circle" />
                            <circle cx="20" cy="20" r="7" fill="white" />
                        </svg>
                        <svg width="128" height="40" className="tab-separator">
                            <path d="M0,20 L64,20 L140,20" stroke="#CEDDF3" strokeWidth="2" markerMid="url(#arrow)" />
                        </svg>
                    </Nav.Link>
                    <span className="ms-1 d-block tab-text-ml1 tab-text position-absolute">Processed</span>
                </Nav.Item>
            </MISTooltip>
            <MISTooltip message={TRANSFORMED_TEXT} placement="top">
                <Nav.Item className="mt-1 mb-3 m-5">
                    <Nav.Link eventKey="transform-final">
                        <svg width="40" height="40" className="tab-circle-border">
                            <circle cx="20" cy="20" r="20" className="tab-circle" />
                            <circle cx="20" cy="20" r="7" fill="white" />
                        </svg>
                    </Nav.Link>
                    <span className="d-block tab-text-ml1 tab-text position-absolute">Transformed</span>
                </Nav.Item>
            </MISTooltip>
        </Nav>
    );

}