import { faWrench } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function ConfigActions(props: any) {
    return (
        <Dropdown align={'end'}>
            <Dropdown.Toggle className="btn btn-secondary config-button d-flex justify-content-between align-items-center">
                <FontAwesomeIcon icon={faWrench} />
                <span>Config</span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="timer-dropdown">
                <div className='border-bottom2'><Dropdown.Item as={Link} to={`extract-clients`}>Extract Client Setup</Dropdown.Item></div>
                <div className='border-bottom2'><Dropdown.Item as={Link} to={`user-types`}>User Types</Dropdown.Item></div>
                <div className='border-bottom2'><Dropdown.Item as={Link} to={`tasks`}>Tasks</Dropdown.Item></div>
                <div><Dropdown.Item as={Link} to={`consumer-clients`}>Connected Apps</Dropdown.Item></div>
            </Dropdown.Menu>
        </Dropdown>
    );
}