import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { CloseButton, Dropdown, Stack, Button } from "react-bootstrap";
import CustomModal from "../modal";
import EstablishmentList from "./establishment-filter-List";
import TimeFilter from "./time-filter";
import { QueueStatus, SalesForceCaseID, timeUnit } from "../../app/enums";
import { useForm } from "react-hook-form";

interface IFilterProps {
    callbackStartDate: any;
    callbackFinishDate: any;
    callbackEstablishment: any;
    callbackMisType: any;
    callbackStatus: any;
    callbackCaseID: any;
}

export default function Filter(props: IFilterProps) {
    const { register, reset, handleSubmit, formState: { errors } } = useForm();
    const [FilterList, setFilterList] = useState<string[]>([]);
    const [showEstablishmentModal, setShowEstablishmentModal] = useState(false);
    const [showMisModal, setShowMisModal] = useState(false);
    const [showStatusModal, setShowStatusModal] = useState(false);
    const [showCaseIDModal, setShowCaseIDModal] = useState(false);

    //time filter
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const uriStartDate = params.get('startDate');
    const uriFinishDate = params.get('finishDate');
    const isTimeFilterInUrl = uriStartDate != null && uriFinishDate != null;
    const [selectedTimer, setSelectedTimer] = useState(isTimeFilterInUrl ? 0 : 12);
    const [selectedTimeUnit, setselectedTimeUnit] = useState(timeUnit.hour);
    const [finishDate, setFinishDate] = useState(uriFinishDate ? new Date(uriFinishDate!) : new Date());
    var defaultStartDate = new Date();
    defaultStartDate.setHours(defaultStartDate.getHours() - selectedTimer);
    const [startDate, setStartDate] = useState(uriStartDate ? new Date(uriStartDate!) : defaultStartDate);
    const [queueStatus, setQueueStatus] = useState('');

    const handleCloseEstablishmentModal = () => setShowEstablishmentModal(false);
    const handleCloseMisModal = () => setShowMisModal(false);
    const handleCloseStatusModal = () => setShowStatusModal(false);
    const handleCloseCaseIDModal = () => setShowCaseIDModal(false);

    var counter = 0;
    useEffect(() => {
        //counter to call setTimes function only once
        if (counter === 0) {
            updateFiltersFromUrl();
            counter++;
        }
        // eslint-disable-next-line
    }, []);

    const updateFiltersFromUrl = () => {
        const params = new URLSearchParams(window.location.search);

        if (params.get('startDate') && params.get('finishDate')) {
            selectDateFilter(params.get('startDate'), params.get('finishDate'));
        }
        else {
            setTimes(selectedTimer, selectedTimeUnit, startDate, finishDate, false);
        }

        if (params.get('status')) {
            selectStatusFilter(params.get('status'));
        }
        else {
            selectDefaultStatus();
        }

        if (params.get('establishmentId')) {
            selectEstablishmentFilter(params.get('establishmentId'), null);
        }
        if (params.get('salesforceId')) {
            selectEstablishmentFilter(params.get('salesforceId'), null);
        }

        if (params.get('mis')) {
            selectMisFilter(params.get('mis'));
        }

        if (params.get('caseID')) {
            const caseId = params.get('caseID');
            if (caseId === SalesForceCaseID.CaseIDNull) {
                handleCaseIDNull();
            }
            else if (caseId === SalesForceCaseID.CaseID){
                handleCaseID();
            }
            else {
                onSubmitCaseID(caseId);
            }
        }
    }

    //Show Modals Functions
    const handleShowEstablishmentModel = () => {
        setShowEstablishmentModal(true);
    };
    const handleShowMisModal = () => {
        setShowMisModal(true);
    };
    const handleShowStatusModal = () => {
        setShowStatusModal(true);
    };
    const handleShowCaseIDModal = () => {
        setShowCaseIDModal(true);
    };

    //End

    //Filter Selection Functions
    function selectEstablishmentFilter(establishmentId: any, includeChildren: any) {
        if (FilterList.indexOf("Establishment") < 0) {
            setFilterList((prev) => [...prev, "Establishment"]);
        }
        props.callbackEstablishment(establishmentId, includeChildren);
    }
    function selectMisFilter(misType: any) {
        if (FilterList.indexOf("MIS") < 0) {
            setFilterList((prev) => [...prev, "MIS"]);
        }
        props.callbackMisType(misType);
        setShowMisModal(false);
    }
    function selectDefaultStatus() {
        if (FilterList.indexOf("Status") < 0) {
            setFilterList((prev) => [...prev, "Status"]);
        }
        props.callbackStatus("1,2");
        setShowStatusModal(false);
    }
    function selectStatusFilter(statusId: any) {
        if (FilterList.indexOf("Status") < 0) {
            setFilterList((prev) => [...prev, "Status"]);
        }
        setQueueStatus(statusId);
        props.callbackStatus(statusId);
        setShowStatusModal(false);
    }
    function selectDateFilter(startDate: any, finishDate: any) {
        if (FilterList.indexOf("Date/Time") < 0) {
            setFilterList((prev) => [...prev, "Date/Time"]);
        }
        props.callbackStartDate(startDate);
        props.callbackFinishDate(finishDate);
    }
    function onSubmitCaseID(data: any) {
        if (FilterList.indexOf(SalesForceCaseID.CaseID) < 0) {
            setFilterList((prev) => [...prev, SalesForceCaseID.CaseID]);
        }
        deleteCaseIDFilter(SalesForceCaseID.CaseIDBlank);
        props.callbackCaseID(data.callbackCaseID);
        reset();
        setShowCaseIDModal(false);
    };
    const handleCaseIDNull = () => {
        if (FilterList.indexOf(SalesForceCaseID.CaseIDBlank) < 0) {
            setFilterList((prev) => [...prev, SalesForceCaseID.CaseIDBlank]);
        }
        deleteCaseIDFilter(SalesForceCaseID.CaseID);
        props.callbackCaseID(SalesForceCaseID.CaseIDNull);
    };
    const handleCaseID = () => {
        if (FilterList.indexOf(SalesForceCaseID.CaseID) < 0) {
            setFilterList((prev) => [...prev, SalesForceCaseID.CaseID]);
        }
        deleteCaseIDFilter(SalesForceCaseID.CaseIDBlank);
        props.callbackCaseID(SalesForceCaseID.CaseID);
    };
    //End

    function setTimes(time: any, timeUnit: timeUnit, callBackStartDate: Date, callBackfinishDate: Date, isRefresh?: boolean) {
        if (time === '') {
            return;
        }

        setSelectedTimer(time);
        setselectedTimeUnit(timeUnit);
        setFinishDate(callBackfinishDate);
        setStartDate(callBackStartDate);

        selectDateFilter(callBackStartDate.toISOString(), callBackfinishDate.toISOString());
    }
    function deleteCaseIDFilter(caseID: string) {
        setFilterList((filterList) => filterList.filter((current) => current !== caseID));
        const index = FilterList.findIndex(obj => obj === caseID);
        if (index !== -1) {
            FilterList.splice(index, 1);
        }
    }

    const handleRemoveFilter = (event: any) => {
        event.preventDefault();
        const data = event.target.id ? event.target.id : "";
        let newFilterList = [...FilterList];
        let index = newFilterList.indexOf(data);
        if (index > -1) {
            newFilterList.splice(index, 1);
            setFilterList(newFilterList);
            if (data === "Establishment") {
                props.callbackEstablishment(null, null);
            }
            else if (data === "MIS") {
                props.callbackMisType(null);
            }
            else if (data === "Status") {
                props.callbackStatus(null);
                setQueueStatus('');
            }
            else if (data === "CaseID") {
                props.callbackCaseID(null);
            }
            else if (data === "CaseIDBlank") {
                props.callbackCaseID(null);
            }
        }
    };

    const ModalHeader = ({ heading, title }: any) => {
        return (
            <Stack>
                <p className="heading">{heading}</p>
                <p className="text-muted">{title}.</p>
            </Stack>
        )
    }

    const MisModal = () => {
        return (
            <Stack>
                <Dropdown onSelect={selectMisFilter}>
                    <Dropdown.Toggle className="btn btn-secondary crbc-bg-color w-100 d-flex justify-content-between align-items-center">
                        MIS
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="timer-dropdown">
                        <Dropdown.Item eventKey="1">SIMS</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Stack>
        )
    }

    const StatusModal = () => {
        return (
            <Stack>
                <Dropdown onSelect={selectStatusFilter}>
                    <Dropdown.Toggle className="btn btn-secondary crbc-bg-color w-100 d-flex justify-content-between align-items-center">
                        Status
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="timer-dropdown">
                        <Dropdown.Item eventKey="1">Not Executed</Dropdown.Item>
                        <Dropdown.Item eventKey="2">Failed</Dropdown.Item>
                        <Dropdown.Item eventKey="3">Completed</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Stack>
        )
    }
    const CaseIDModal = () => {
        return (
            <Stack className="text-filter-top">
                <form onSubmit={handleSubmit(onSubmitCaseID)} className="form mt-0">
                    <label >{props.callbackCaseID}</label>
                    <input {...register("callbackCaseID", { required: true })} maxLength={20} defaultValue={props.callbackCaseID} className={`form-control ms-0 w-100 ${(errors.callbackCaseID) ? 'is-invalid' : ''}`} />
                    {errors.callbackCaseID && <div className="invalid-feedback">
                        The {props.callbackCaseID} name field value is invalid, it cannot be empty or contain whitespaces
                    </div>}
                    <div className=" d-flex mt-3">
                        <Button variant="popup-btn right-margin10 btn-outline-secondary w-100" onClick={handleCloseCaseIDModal}>
                            Cancel
                        </Button>
                        <Button variant="popup-btn btn btn-primary crbc-bg-color w-100" type="submit">
                            Apply
                        </Button>
                    </div>
                </form>
            </Stack>
        )
    }

    return (
        <React.Fragment>
            <Stack direction="horizontal" gap={3} className="w-100">
                <Dropdown>
                    <Dropdown.Toggle variant="primary" className="dropdown-filter crbc-bg-color" style={{ fontSize: 14 }}>
                        <FontAwesomeIcon icon={faFilter} /> Filter
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="timer-dropdown">
                        <Dropdown.Item onClick={handleCaseID} className="mb-1 font-14">Case ID Added</Dropdown.Item>
                        <Dropdown.Item onClick={handleCaseIDNull} className="mb-1 font-14">Case ID Blank</Dropdown.Item>
                        <Dropdown.Item onClick={handleShowCaseIDModal} className="mb-1 font-14">Case ID Search</Dropdown.Item>
                        <Dropdown.Item onClick={handleShowEstablishmentModel} className="mb-1 font-14">Establishment</Dropdown.Item>
                        <Dropdown.Item onClick={handleShowMisModal} className="mb-1 font-14">MIS</Dropdown.Item>
                        <Dropdown.Item onClick={handleShowStatusModal} className="mb-1 font-14">Status</Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown>
                {FilterList.map((element, index) => {
                    return (
                        <div key={index} id={element}>
                            <div className="btn selected-filter-color" id={element}>
                                {
                                    element !== "Date/Time"
                                        ? <div className="filter-wrap d-flex align-items-center font-14">{element} <CloseButton id={element} className="ms-2 p-0" onClick={handleRemoveFilter} /></div>
                                        : <div className="font-14">{element}</div>
                                }
                            </div>
                        </div>
                    );
                })}
                <div className="ms-auto">
                    <TimeFilter selectedTimer={selectedTimer} selectedTimeUnit={selectedTimeUnit} tooltipText={queueStatus.includes(QueueStatus.NotExecuted.toString()) ? "When filtering by Not Executed tasks, time filters include results greater than the chosen time filter" : ""} setTimes={setTimes} isRefresh={false} />
                </div>
            </Stack>
            <CustomModal isShow={showEstablishmentModal} handleClose={handleCloseEstablishmentModal} header={<ModalHeader heading="Establishments" title="Please select an establishment" />} size="lg">
                <EstablishmentList handleClose={handleCloseEstablishmentModal} handleEstablishmentFilter={selectEstablishmentFilter} />
            </CustomModal>
            <CustomModal isShow={showMisModal} handleClose={handleCloseMisModal} header={<ModalHeader heading="MIS Type" title="Please select a MIS Type" />} size="sm">
                <MisModal />
            </CustomModal>
            <CustomModal isShow={showStatusModal} handleClose={handleCloseStatusModal} header={<ModalHeader heading="Status" title="Please select a Status" />} size="sm">
                <StatusModal />
            </CustomModal>
            <CustomModal isShow={showCaseIDModal} handleClose={handleCloseCaseIDModal} header={<ModalHeader heading="Case ID" title="Please enter a Case ID" />} size="sm">
                <CaseIDModal />
            </CustomModal>
        </React.Fragment>
    );
}