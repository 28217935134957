import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MISButton from "../../../components/button";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { PERMISSION_REQUIRED, SYNC_SALESFORCE_BUTTON_TITLE } from "../../../app/constants";
import MISTooltip from "../../../components/tooltip";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ServiceManager } from "../../../services/ServiceManager";
import { APIStatusCode, Permissions } from "../../../app/enums";
import { useNavigate } from "react-router-dom";
import { usePermissionCheck } from "../../../app/common/helper/Permissions";

export default function SyncSalesforce(props: any) {

    const canEditEstablishments = usePermissionCheck(Permissions.CanEditEstablishments);
    const queryClient = useQueryClient();
    let navigate = useNavigate();

    const mutation = useMutation({
        mutationFn: () => {
            return ServiceManager.EstablishmentService.Synchronise(props.salesforceExternalId);
        },
        onError: (error, variables, context) => {

        },
        onSuccess: (data, variables, context) => {
            if (data === null) {
                ServiceManager.ToastService.showError("Unable to Synchronise Establishment with Salesforce.");
            }
            else {
                if (data.status === APIStatusCode.Ok) {
                    queryClient.invalidateQueries();
                    ServiceManager.ToastService.showSuccess("Establishment successfully synced with Salesforce.");
                }
                else {
                    if (data.response.status === APIStatusCode.NotFound) {
                        queryClient.invalidateQueries();
                        navigate(-1);
                        ServiceManager.ToastService.showError("Establishment not found in Salesforce, it is now disabled.");
                    }
                    else {
                        const errors = data?.response?.data?.errors;
                        if (errors) {
                            Object.keys(errors).forEach(field => {
                                ServiceManager.ToastService.showError(`${field}: ${errors[field][0]}`);
                            });
                        }
                        else {
                            const errorMessage = data?.response?.data ?? (data?.response?.message ?? "Error in Sync Salesforce.");
                            ServiceManager.ToastService.showError(errorMessage);
                        }
                    }
                }
            }
        }
    });

    const synchronise = () => {
        mutation.mutate();
    }

    return (
        <>{
            canEditEstablishments
            ?
            <div>
                <MISButton text="Sync Salesforce"
                    className="btn btn-secondary grey sync-button pt-1"
                    style={{height: "34px"}}
                    title={SYNC_SALESFORCE_BUTTON_TITLE}
                    FnOnClick={synchronise}
                    disabled={!props.salesforceExternalId}
                    textClass="ms-0 text-center"
                    icon={<FontAwesomeIcon icon={faRefresh} className={`${mutation.isLoading ? "spin" : ""}`} />}></MISButton>
            </div>

            :
            <MISTooltip message={PERMISSION_REQUIRED} placement="bottom">
                <div>
                    <MISButton text="Sync Salesforce"
                        className="btn btn-secondary grey sync-button pt-1"
                        style={{height: "34px"}}
                        FnOnClick={() => null}
                        textClass="ms-0 text-center"
                        disabled
                        icon={<FontAwesomeIcon icon={faRefresh} />}></MISButton>
                </div>
            </MISTooltip>
        }</>
    );
}